import React from "react"
import {inject} from "mobx-react"
import {
    Button,
    message
} from 'antd'

import {LeaseStepsContainer} from ".."
import {Checkbox} from "../../../components"
import * as Icons from "../../../components/icons"
import {GeneralAgreementStepContent} from './components/GeneralAgreementStepContent/GeneralAgreementStepContent'
import {QLDGeneralAgreementStepContent} from './components/GeneralAgreementStepContent/QLDGeneralAgreementStepContent'
import {SignatureModal} from "../components/SignatureModal/SignatureModal"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {saveTenantInitial} from "../../../networking/lease"

import {
    Props,
    InjectedProps,
    State
} from "./types"

import './styles.scss'
import {SIGNATURE_STEP} from "../../../routing/paths";

@inject('Core', 'Lease')
export class GeneralAgreementStep extends ComponentWithInjectedProps<Props, State, InjectedProps>{

    state = {
        isConfirm: false,
        loading: false,
        isVisibleSignatureModal: false
    }

    private changeConfirm = (): void => {
        this.setState({isConfirm: !this.state.isConfirm})
    }

    private saveTenantInitial = (signature: any): any => {
        const initial = signature.toDataURL()
        const {nextPath, history} = this.props
        if(signature.isEmpty()){
            return message.warning({
                content: 'Set your initial please'
            })
        }
        this.setState({loading: true}, () => {
            saveTenantInitial(initial, 'agreements')
                    .then(res => {
                        if(res.status === 200) {
                            const missedSignature = localStorage.getItem('signature');
                            if(missedSignature != "" && missedSignature != null){
                                localStorage.setItem('signature', "");
                                history.push(SIGNATURE_STEP);
                            } else {
                                history.push(nextPath)
                            }
                        } else {
                            this.setState({loading: false})
                            message.error('Internal error occur, please try again')
                        }
                    })
                    .catch(err => this.setState({loading: false}))
        })
    }

    componentDidMount(): void {
        const missedSignature = localStorage.getItem('signature');
        if(missedSignature != "" && missedSignature != null){
            message.warn('Something wrong with saving your signature on this step. Please sign again.')
        }
    }

    render(): React.ReactElement{
        const {isConfirm, loading, isVisibleSignatureModal} = this.state
        const {Core, Lease: {getCurrentUser, lease:{property:{state}}}, Lease} = this.injectedProps
        const user = getCurrentUser()
        return (
                <LeaseStepsContainer title={'General Agreement'}>
                    {{
                        content: (
                                <div className="general-agreement-step__container">
                                    <Icons.WebChecklist/>
                                    {state == "NSW" && <GeneralAgreementStepContent {...{Lease}}/>}
                                    {state == "QLD" && <QLDGeneralAgreementStepContent {...{Lease}}/>}
                                    <div className='witness-step__confirmation-container w-100'>
                                        <div>
                                            <Checkbox checked={isConfirm} onChange={this.changeConfirm}>
                                                <p className='small-paragraph mt-0'>
                                                    I {user.first_name} {user.last_name} agree to the general terms of
                                                    the agreement.
                                                </p>
                                            </Checkbox>
                                        </div>
                                    </div>
                                    <SignatureModal
                                            title={'Initial'}
                                            isVisible={isVisibleSignatureModal}
                                            onCancel={() => this.setState({isVisibleSignatureModal: false})}
                                            onSave={this.saveTenantInitial}
                                            loading={loading}
                                    />
                                </div>
                        ),
                        footer: (
                                <div className='footer__buttons-container_two'>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="default"
                                                loading={false}
                                                onClick={() => Core.toggleCallDialog()}
                                        >
                                            Disagree
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                                disabled={!isConfirm}
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="danger"
                                                loading={false}
                                                onClick={() => this.setState({isVisibleSignatureModal: true})}
                                        >
                                            Agree
                                        </Button>
                                    </div>
                                </div>
                        )
                    }}
                </LeaseStepsContainer>
        )
    }
}
