export const VERIFICATION_TENANT = '/'
export const VERIFICATION_SMS_CODE = '/verification-code'
export const START_PAGE = '/lease/start'
export const WELCOME_PAGE = '/lease/welcome'
export const CURRENT_LOCATION_PAGE = '/lease/location'
export const INFORMATION_PAGE = '/lease/information'
export const LEASE_STEPS = '/lease'
export const TENANT_DETAILS_LEASE_STEP = '/lease/tenant-details'
export const RENTAL_DETAILS_LEASE_STEP = '/lease/rental-details'
export const PROPERTY_DETAILS_LEASE_STEP = '/lease/property'
export const RENT_BOND_DETAILS_LEASE_STEP = '/lease/rent-bond'
export const OCCUPANCY_DETAILS_LEASE_STEP = '/lease/occupancy'
export const INCLUSION_DETAILS_LEASE_STEP = '/lease/inclusion'
export const EMERGENCY_CONTACTS_LEASE_STEP = '/lease/emergency-contacts'
export const SMOKE_ALARMS_STEP = '/lease/smoke-alarms'
export const WATER_USAGES_STEP = '/lease/water-usages'
export const NOTES_STEP = '/lease/notes'
export const IMPORTANT_INFORMATION_STEP = '/lease/important-information'
export const ELECTRONIC_SERVICES = '/lease/electronic-services'
export const INFORMATION_STATEMENT = '/lease/information-statement'
export const ADDITIONAL_TERMS_STEP = '/lease/additional-terms'
export const CONDITION_REPORT_TENANCY_LAWS = '/lease/condition-report'
export const SPECIAL_CONDITION_LEASE_STEP = '/lease/special-condition'
export const SIGNATURE_STEP = '/lease/signature'
export const GENERAL_AGREEMENT_LEASE_STEP = '/lease/general-agreement'
export const C_PART = '/lease/part-c-safety-related-activities'
export const D_PART = '/lease/part-d-rights-and-obligations'
export const E_PART = '/lease/part-e-additional-terms'
export const WITNESS_LEASE_STEP = '/lease/witness'
export const CONGRATULATION_STEP = '/lease/congratulation'
export const CONNECTION_CALLBACK_STEP = '/lease/connection'
export const INVALID_PAGE = '/error'
export const ALREADY_SIGNED = '/lease/already-signed'
export const TEST_CODE = '/test-code'
export const EXPIRED_ERROR = '/link-expired'
