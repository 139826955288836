import React from "react"
import {
    inject,
    observer
} from "mobx-react"
import {Button} from 'antd'

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {
    Props,
    InjectedProps
} from "./types"

import './styles.scss'

@inject('Core', 'Lease')
@observer
export class InclusionDetailsStep extends ComponentWithInjectedProps<Props, any, InjectedProps>{

    constructor(props: Props){
        super(props)
        const {Lease: {lease: {inclusion}}} = this.injectedProps
        if(!inclusion){
            const {history, nextPath} = this.props
            history.replace(nextPath)
        }
    }

    render(): React.ReactElement | null{
        const {history, nextPath} = this.props
        const {Core, Lease: {lease: {inclusion, property: {state}}}} = this.injectedProps
        if(!inclusion){
            return null
        }
        return (
                <LeaseStepsContainer title={'Inclusion'}>
                    {{
                        content: (
                                <div className='inclusion-step__container'>
                                    <Icons.Inclusion/>
                                    <h4 className='heading-blue'>These things are included in the lease</h4>
                                    <p className='heading-secondary'>{inclusion.message}</p>
                                    {state != "QLD" &&
                                    <>
                                    <h4 className='heading-blue'>Furniture</h4>
                                    <p className='heading-secondary'>
                                        This property is {inclusion.property_furnished ? 'furnished' : 'unfurnished'}
                                    </p>
                                    </>
                                    }
                                </div>
                        ),
                        footer: (
                                <div className='footer__buttons-container_two'>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="default"
                                                loading={false}
                                                onClick={() => Core.toggleCallDialog()}
                                        >
                                            Disagree
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="danger"
                                                loading={false}
                                                onClick={() => history.push(nextPath)}
                                        >
                                            Agree
                                        </Button>
                                    </div>
                                </div>
                        )
                    }}
                </LeaseStepsContainer>
        )
    }
}
