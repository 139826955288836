import React from "react"
import {withRouter} from 'react-router'

import {routes} from "../../../../routing/routes"

import {Props} from "./types"

import './styles.scss'

function LeaseSteppers(props: Props) {
    const {location} = props
    const leaseRoutes = routes.filter(route => route.isLeaseSteps)
    const indexActiveStep = leaseRoutes.findIndex(step => location.pathname === step.path)
    return (
        <div className='lease-stepper__container'>
            {leaseRoutes.map((step, index) => {
                return index <= indexActiveStep ? <div className='lease-stepper__circle active' key={index}/> :
                    <div className='lease-stepper__circle' key={index}/>
            })}
            <div className="lease-stepper__line"/>
        </div>
    )
}

export default withRouter(LeaseSteppers)
