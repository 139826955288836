import React from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import {ThemeProvider} from "@material-ui/styles"
import {Provider} from "mobx-react"

import {themeOverrides} from "./themeOverrides"
import {AxiosMiddleware} from "./networking/middleware"
import {mockInit} from "./networking/mock"
import {generateCode} from "./networking/verification"

import {MainLayout} from "./components"
import {RoutesWrapper} from "./components"

import Store from "./store"
import {InvalidPage, VerificationSmsPage, VerificationTenantPage, LeaseAlreadySigned, ExpiredPage} from "./pages"
import {INVALID_PAGE, VERIFICATION_SMS_CODE, ALREADY_SIGNED, TEST_CODE, EXPIRED_ERROR} from "./routing/paths"

import {getItem} from "./helpers/storage"

declare const ENV: string

const store = {
    Store,
    Lease: Store.Lease,
    Core: Store.Core,
    StaticPage: Store.StaticPage
}

AxiosMiddleware.boot()
if (ENV === 'mock') {
    mockInit()
}

const App: React.FC = () => {
    return (
        <div className="App">
            <ThemeProvider theme={themeOverrides}>
                <Provider {...store}>
                    <Router>
                        <Switch>
                            <Route exact path={INVALID_PAGE} component={InvalidPage}/>
                            <Route exact path={EXPIRED_ERROR} component={ExpiredPage}/>
                            <Route exact path={ALREADY_SIGNED} component={LeaseAlreadySigned}/>
                            <Route exact path={VERIFICATION_SMS_CODE} render={(props) => {
                                return getItem('link_code') ?
                                    <MainLayout loadLease={false}>
                                        <VerificationSmsPage {...props}/>
                                    </MainLayout>
                                    :
                                    <Redirect to={{pathname: INVALID_PAGE}}/>

                            }}/>
                            <Route exact path={TEST_CODE} render={(props) => {
                                generateCode()
                                    .then(res => {
                                        const code = res.data.code
                                        props.history.replace('/' + code)
                                    })
                                    .catch(err => console.log(err))
                                return <div></div>
                            }}/>
                            <Route exact path={'/:code'} component={VerificationTenantPage}/>
                            <MainLayout loadLease={true}>
                                <RoutesWrapper/>
                            </MainLayout>
                            <Route component={InvalidPage}/>
                        </Switch>
                    </Router>
                </Provider>
            </ThemeProvider>
        </div>
    );
}

export default App;
