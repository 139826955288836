import React, {Fragment} from "react"
import {inject, observer} from "mobx-react"
import {Button} from 'antd'

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons"

import {formatDate, ordinal} from "../../../helpers/date"

import {PAYMENT_METHODS_ALIAS} from "../../../types/PaymentMethod"
import {PROVIDED_TO} from "../../../types/Rent"

import {Props, InjectedProps} from "./types"
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import './styles.scss'
import {toJS} from "mobx";

@inject('Core', 'Lease')
@observer
export class RentBondStep extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    checkDecimalNumbers(number: any): any {


        number = String(number);

        console.log(number)
        console.log(typeof number)
        let value: any = Number(number);
        let res = number.split(".");
        if(res.length === 1 || res[1].length < 3) {
            value = value.toFixed(2);
        }
        return value;
    }

    render(): React.ReactElement {
        const {history, nextPath} = this.props;
        const {Core, Lease: {lease: {payment_methods, rent, rent_increase, property}} } = this.injectedProps;
        const state = property.state;
        // @ts-ignore
        const rentIncrease:[] = toJS(rent_increase);
        const periods: any = {monthly: 'Month', fortnightly: 'Fortnight', weekly: 'Week'};
        const partsOfStartDate = rent.start_on.split("-");
        const startOnDay = partsOfStartDate[partsOfStartDate.length - 1].replace(/^0+/, '');

        return (
            <LeaseStepsContainer title='Rent and Bond'>
                {{
                    content: (
                        <div className="rent-bond-step__container">
                            <Icons.Calendar/>
                            <h4 className='heading-blue'>Payment method</h4>
                            {payment_methods.map(method => {
                                return (
                                    <Fragment key={method.id}>
                                        <p className='heading-secondary'>{method.method.toUpperCase()}</p>
                                        {Object.keys(method.context).map(key => {
                                            if(method.context[key]) {
                                                return <p key={key}
                                                          className='heading-secondary'>{PAYMENT_METHODS_ALIAS[key] + ': ' + method.context[key]}</p>
                                            }
                                            })}
                                    </Fragment>
                                )
                            })}

                            {state == "NSW" && <>
                                <h4 className='heading-blue'>Bond</h4>
                                <p className='heading-secondary'>${this.checkDecimalNumbers(rent.bond)} {rent.held?'held':null}</p>
                                <h4 className='heading-blue'>Tenant provided bond amount to</h4>
                                <p className='heading-secondary text-center'>{PROVIDED_TO[rent.provided_to]}</p>
                            </>}

                            {state == "VIC" &&
                            <div className="bond-wrapper__vic">
                                <div>
                                    <h4 className='heading-blue'>Bond</h4>
                                    <p className='heading-secondary'>${this.checkDecimalNumbers(rent.bond)} {rent.held?'held':null}</p>
                                </div>
                                <div>
                                    <h4 className='heading-blue'>Bond payment due</h4>
                                    <p className='heading-secondary'>{formatDate(rent.bond_payment_due)}</p>
                                </div>
                            </div>
                            }

                            {state == "QLD" && <>
                                <h4 className='heading-blue'>Bond</h4>
                                <p className='heading-secondary'>${this.checkDecimalNumbers(rent.bond)} {rent.held?'held':null}</p>
                            </>}

                            <div className='rent-bond-step__rent-info'>
                                <div>
                                    <h4 className='heading-blue'>Rent</h4>
                                    <span className='heading-primary'>${this.checkDecimalNumbers(rent.rate)}
                                        {' '}<span className='heading-secondary'>{rent.frequency}</span>
                                    </span>
                                </div>
                                <div>
                                    <h4 className='heading-blue'>Payable From</h4>
                                    <span className='heading-primary'>{formatDate(rent.start_on)}</span>
                                </div>
                                {rentIncrease&&
                                // @ts-ignore
                                rentIncrease.map((action: any, index: number) => {
                                    return(
                                      <React.Fragment key={index}>
                                          <div>
                                              <h4 className='heading-blue'>Rent Increase</h4>
                                              <span className='heading-primary'>${this.checkDecimalNumbers(action.rate)} <span
                                                className='heading-secondary'>{action.frequency}</span></span>
                                          </div>
                                          <div>
                                              <h4 className='heading-blue'>From</h4>
                                              <span
                                                className='heading-primary'>{formatDate(action.start_on)}</span>
                                          </div>
                                      </React.Fragment>
                                    )
                                })
                                }
                            </div>

                            {state == "QLD" &&
                            <div style={{textAlign:'center'}}>
                                <h4 className='heading-blue'>Rent must be paid on the</h4>
                                    {rent.frequency == "monthly" &&
                                    <p className='heading-secondary'>
                                        {ordinal(Number.parseInt(startOnDay))} of each {periods[rent.frequency]}
                                    </p>
                                    }
                                    {(rent.frequency == "fortnightly" || rent.frequency == "weekly") &&
                                    <p className='heading-secondary'>
                                        {rent.day} of each {periods[rent.frequency]}
                                    </p>
                                    }

                                {rent.place_of_rental && <>
                                <h4 className='heading-blue'>Place of rent payment</h4>
                                <p className='heading-secondary'>
                                    {rent.place_of_rental}
                                </p>
                                </>}
                            </div>
                            }
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_two'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
