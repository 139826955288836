import React from "react"
import {Button, message} from 'antd'
import TextField from "@material-ui/core/TextField"
import {inject, observer} from "mobx-react"

import {MainLayoutFooter, MainLayoutContentContainer, BaseModal} from "../../components"
import {ComponentWithInjectedProps} from "../../services/ComponentWithInjectedProps"

import * as Icons from "../../components/icons"

import {Props, State, InjectedProps} from './types'

import {getCurrentLocation} from "../../networking/lease"

import './styles.scss'
import {User} from "../../types/User";

@inject('Lease')
@observer
export class GrabLocationPage extends ComponentWithInjectedProps<Props, State, InjectedProps> {

    state = {
        current_address: '',
        loading: false,
        isVisibleModal: false,
        manualLocation: '',
        suburb: '',
        postcode: ''
    }

    private getGeolocation() {
        const {Lease: {attachCurrentLocation}} = this.injectedProps
        navigator.geolocation.getCurrentPosition((position) => {
            const {coords: {latitude, longitude}} = position
            this.setState({loading: true}, () => {
                getCurrentLocation({lng: longitude, lat: latitude})
                    .then(res => {
                        attachCurrentLocation(res.data)
                    })
                    .catch((err) => {
                        if (err.response.status === 422) {
                            this.showModalErrorGrabLocation()
                        }
                    })
                    .finally(() => this.setState({loading: false}))
            })
        }, (err) => this.showModalErrorGrabLocation())
    }

    private showModalErrorGrabLocation = (): void => {
        message.warning({
            content: "We can't grab your location, enter your location manually",
            duration: 5
        })
        this.setState({isVisibleModal: true})
    }

    private agreeButtonManualLocation = (): void => {
        const {postcode, suburb} = this.state
        const {Lease: {setCurrentLocation}} = this.injectedProps
        this.setState({loading: true}, () => {
            setCurrentLocation({city: suburb, post_code: postcode})
                .then(res => this.setState({isVisibleModal: false}))
                .catch(err => console.log(err))
                .finally(() => this.setState({loading: false}))
        })
    }

    private getCurrentTenant = (): User => {
        const {Lease: {lease: {users}}} = this.injectedProps
        return users.reduce((prev, user) => {
            if (user.me) {
                prev = user
            }
            return prev
        })
    }

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        const currentTenant = this.getCurrentTenant()
        const {loading, isVisibleModal, suburb, postcode} = this.state
        return (
            <>
                <MainLayoutContentContainer>
                    <div className='grab-location__continer'>
                        <Icons.LogoWithText/>
                        <Icons.Location/>
                        <p className='grab-location__info-text'>We need to confirm you current location. This will
                            only take a minute.
                        </p>
                        <p className='grab-location__address'>
                            {currentTenant.current_location.meta_data ? currentTenant.current_location.meta_data.city + ' ' + currentTenant.current_location.meta_data.post_code : 'Your current location'}
                        </p>
                        <div style={{width: 100}}>
                            <Button
                                className='fullwidth t-uppercase app-basic-button_large'
                                disabled={!currentTenant.current_location.meta_data}
                                shape="round"
                                type="danger"
                                loading={false}
                                onClick={() => history.push(nextPath)}
                            >
                                Agree
                            </Button>
                        </div>
                    </div>
                    <BaseModal visible={isVisibleModal}>
                        <p className='heading-secondary text-center'>Enter your current location</p>
                        <div className='grab-location__fields-container'>
                            <div className='col-md-6'>
                                <TextField
                                    label="Suburb"
                                    fullWidth
                                    margin="normal"
                                    value={suburb}
                                    onChange={(e) => this.setState({suburb: e.target.value.trimStart()})}
                                />
                            </div>
                            <div className='col-md-6'>
                                <TextField
                                    label="Postcode"
                                    fullWidth
                                    margin="normal"
                                    value={postcode}
                                    onChange={(e) => this.setState({postcode: e.target.value.trimStart()})}
                                />
                            </div>
                        </div>

                        <div style={{width: 100, margin: 'auto'}}>
                            <Button
                                className='fullwidth t-uppercase app-basic-button_large'
                                disabled={!suburb || !postcode}
                                shape="round"
                                type="danger"
                                loading={false}
                                onClick={this.agreeButtonManualLocation}
                            >
                                Agree
                            </Button>
                        </div>
                    </BaseModal>
                </MainLayoutContentContainer>
                <MainLayoutFooter>
                    <div className='grab-location__buttons-container'>
                        <div>
                            <Button
                                className='fullwidth t-uppercase app-basic-button_large'
                                shape="round"
                                type="default"
                                loading={false}
                                onClick={() => this.setState({isVisibleModal: true})}
                            >
                                Enter my location
                            </Button>
                        </div>
                        <div>
                            <Button
                                className='fullwidth t-uppercase app-basic-button_large'
                                shape="round"
                                type="danger"
                                loading={loading}
                                onClick={() => this.getGeolocation()}
                            >
                                Grab my location
                            </Button>
                        </div>
                    </div>
                </MainLayoutFooter>
            </>
        )
    }
}
