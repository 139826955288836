import React, {Component} from "react"
import {Button} from "antd"

import {LeaseStepsContainer} from ".."
import {ImportantInformationStepContent} from "./components/ImportantInformationStepContent/ImportantInformationStepContent"

import * as Icons from "../../../components/icons"

import {Props} from "./types"

export class ImportantInformationStep extends Component<Props, any>{

    render(): React.ReactElement{
        const {history, nextPath} = this.props
        return (
                <LeaseStepsContainer title={'Important Information'}>
                    {{
                        content: (
                                <div className="additional-terms__container">
                                    <Icons.WebChecklist/>
                                    <h4 className='heading-blue'>
                                        Please read this before completing the residential tenancy agreement (the
                                        Agreement).
                                    </h4>
                                    <ImportantInformationStepContent/>
                                </div>
                        ),
                        footer: (
                                <div className='footer__buttons-container_two'>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="danger"
                                                loading={false}
                                                onClick={() => history.push(nextPath)}
                                        >
                                            Ok
                                        </Button>
                                    </div>
                                </div>
                        )
                    }}
                </LeaseStepsContainer>
        )
    }
}
