import axios, {AxiosPromise} from 'axios'
import {File} from "../types/File"
import {Witness} from "../types/Witness"
import {Lease} from "../types/Lease"

export function getLease(): AxiosPromise<Lease> {
  return axios.get(`/lease`)
}

export function getCurrentLocation({lng, lat}: {lng: number, lat: number}): AxiosPromise<any> {
  return axios.post('/location', {lng, lat})
}

export function saveManualLocation({city, post_code}: {city: string, post_code: string}): AxiosPromise {
  return axios.post('/location/enter', {city, post_code})
}

export function saveWitnessSignature({signature, first_name, last_name}: {signature: string, first_name: string, last_name: string}): AxiosPromise<Witness> {
  return axios.post('/witness', {signature, first_name, last_name})
}

export function saveTenantSignature(signature: string): AxiosPromise<File> {
  return axios.put('/lease', {signature})
}

export function saveTenantInitial(initials: string, section: "agreements" | "notes"): AxiosPromise {
  return axios.post('/lease/initials', {section, initials})
}

export function saveTenantInitialForAdditionalTerms(initials: string, additional_term_id: number): AxiosPromise {
  return axios.post('/lease/initials/additional-terms', {initials, additional_term_id})
}

export function saveInformationStatementSignature(tenant_statement_signature: string): AxiosPromise {
  return axios.post('/lease/tenant-statements', {tenant_statement_signature})
}

export function proceedConnections(type: string, lease_id: string, code: string) {
  return axios.post('/connections', {type, lease_id, code})
}

export function getTenantInformationPdf():AxiosPromise{
  return axios.get('/induction-tenant-statement')
}

export function checkSignatures(): AxiosPromise<File> {
  return axios.get('/lease/verify-signatures')
}
