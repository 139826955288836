import React from "react"
import {Modal} from 'antd'

import {Props} from "./types"

import './styles.scss'

export function BaseModal(props: Props): React.ReactElement {
    const {children, visible, closable= false, onCancel=() => null, style={}} = props
    return (
        <Modal
            onCancel={onCancel}
            footer={null}
            visible={visible}
            closable={closable}
            style={style}
        >
            {children}
        </Modal>
    )
}
