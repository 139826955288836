import React from "react"
import {Button, message} from 'antd'
import {inject} from 'mobx-react'

import {LeaseStepsContainer} from ".."
import {SignatureModal} from "../components/SignatureModal/SignatureModal"
import * as Icons from "../../../components/icons"

import {Props, State, InjectedProps} from "./types"
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {saveTenantSignature, checkSignatures} from "../../../networking/lease"
import {NOTES_STEP, GENERAL_AGREEMENT_LEASE_STEP, INFORMATION_STATEMENT} from "../../../routing/paths";

import './styles.scss'
import {BaseModal} from "../../../components";

@inject('Lease')
export class SignatureStep extends ComponentWithInjectedProps<Props, State, InjectedProps> {

    state = {
        isVisibleSignatureModal: false,
        isVisibleInfoModal: false,
        loading: false
    }

    private saveSignature = (signature: any) => {
        const signatureImg = signature.toDataURL()
        if(signature.isEmpty()){
            return message.warning({
                content:'Sign please'
            })
        }
        this.setState({loading: true}, () => {
            saveTenantSignature(signatureImg)
                .then(res => {
                    if(res.status === 200) {
                        const {Lease} = this.injectedProps
                        Lease.setTenantSignature(res.data)
                        this.setState({loading: false}, () => {
                            this.props.history.push(this.props.nextPath)
                        })
                    } else {
                        this.setState({loading: false})
                        message.error('Internal error occur, please try again')
                    }
                })
                .catch(err => this.setState({loading: false}))
        })
    }

    private checkSignatures = () => {
        const {Lease: {lease: {property: {state}}}} = this.injectedProps;

        checkSignatures()
        .then(res => {
            if(res.status === 200) {
                //@ts-ignore
                if(res.data.signature == "") {
                    localStorage.setItem('signature', "");
                    this.setState({isVisibleSignatureModal: true})
                } else {
                    //@ts-ignore
                    localStorage.setItem('signature', res.data.signature);
                    console.log(localStorage);
                    //@ts-ignore
                    switch (res.data.signature) {
                        case "note_initials_id": // NSW, VIC, QLD
                            if (state == "NSW") {
                                this.props.history.push(NOTES_STEP)
                            }
                            if (state == "VIC" || state == "QLD") {
                                this.props.history.push(INFORMATION_STATEMENT)
                            }
                            break;
                        case "agreement_initials_id": //NSW, QLD
                            if (state == "NSW" || state == "VIC") {
                                this.props.history.push(GENERAL_AGREEMENT_LEASE_STEP)
                            }
                            break;
                        case "tenant_statement_signature_id": //only NSW
                            this.props.history.push(INFORMATION_STATEMENT)
                            break;
                    }
                }
            } else {
                this.setState({loading: false})
                message.error('Internal error occur, please try again')
            }
        })
        .catch(err => this.setState({loading: false}))
    }

    render(): React.ReactElement {
        const {isVisibleSignatureModal, loading, isVisibleInfoModal} = this.state
        const {Lease: {lease: {property: {state}}}} = this.injectedProps
        return (
            <LeaseStepsContainer title={'Signature'}>
                {{
                    content: (
                        <div className="special-condition-witness-step" style={{marginTop:25}}>
                            <Icons.UserCard/>
                            <p className='heading-red__bold-secondary'>
                                You're now ready to sign your Tenancy Agreement
                            </p>

                            {state == "NSW" && <>
                            <p className='heading-secondary text-center'>
                                As the tenant you enter into this agreement and agree to all it's terms
                            </p>
                            <p className='heading-blue text-center'>
                                Note. Section 9 of the Electronic Transactions Act 2000
                                allows for agreements to be signed electronically in NSW if
                                the parties consent. If an electronic signature is used then it
                                must comply with Division 2 of Part 2 of the Electronic Transaction Act 2000
                            </p>
                            </>}

                            {state == "VIC" && <>
                            <p className='heading-secondary text-center'>
                                This agreement is made under the <b>Residential Tenancies Act 1997.</b>
                            </p>
                            <p className='heading-secondary text-center'>
                                Before signing you must read <b>Part D – Rights and obligations</b> in this form.
                            </p>
                            </>}

                            {state == "QLD" && <>
                                <p className='heading-secondary text-center'>
                                    This agreement is made under the <b>Residential Tenancies and Rooming Accomodation
                                    Act 2008.</b>
                                </p>
                            </>}

                            <SignatureModal
                                title={'Sign'}
                                loading={loading}
                                isVisible={isVisibleSignatureModal}
                                onCancel={() => this.setState({isVisibleSignatureModal: false})}
                                onSave={this.saveSignature}
                            />
                            <BaseModal visible={isVisibleInfoModal}>
                                <p className='heading-red__bold-secondary'>IMPORTANT</p>
                                <p className='heading-secondary text-center'>You can't continue without witness</p>
                                <div className='witness-step__modal__button-container'>
                                    <Button
                                        className=' t-uppercase app-basic-button_large'
                                        shape="round"
                                        type="danger"
                                        loading={false}
                                        onClick={() => this.setState({isVisibleInfoModal: false})}
                                    >
                                        Ok
                                    </Button>
                                </div>
                            </BaseModal>
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_one'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    onClick={() => this.checkSignatures()}
                                >
                                    Sign Now
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
