import React from "react"
//@ts-ignore
import Div100vh from 'react-div-100vh'

import {MainLayoutContentContainer, MainLayoutFooter} from "../../components"

import './styles.scss'

import logo from '../../resources/images/svg/logo-with-text.svg'
import {Oops} from "../../components/icons";

export function ExpiredPage() {
    return (
            <>
                <Div100vh style={{
                    minHeight: '100rvh',
                    backgroundColor: '#fff',
                    display: 'flex',
                    maxWidth: 991,
                    margin: '0 auto'
                }}>
                    <MainLayoutContentContainer>
                        <div className="error-page"><img src={logo} alt="logo" className='logo' />
                            <div className="center-box">
                                <h1><Oops/></h1>
                                <h2>This link has expired</h2>
                                <h3>Please contact your property manager</h3>
                            </div>
                        </div>
                    </MainLayoutContentContainer>
                    <MainLayoutFooter>

                    </MainLayoutFooter>
                </Div100vh>
            </>
    )
}
