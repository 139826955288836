import React from "react"
import {inject, observer} from "mobx-react"
import {Button} from 'antd'

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons";

import {Props, InjectedProps} from "./types"

import './styles.scss'

@inject('Lease', 'Core')
@observer
export class OccupancyDetailsStep extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        const {Lease: {lease: {occupant}}, Core} = this.injectedProps
        return (
            <LeaseStepsContainer title={'Occupancy'}>
                {{
                    content: (
                        <div className="occupancy-step__container">
                            <Icons.Occupancy/>
                            <p className='heading-blue'>Maximum number of occupants</p>
                            <span className='heading-secondary'>{occupant.count}</span>
                            {/*{users.map(user => {*/}
                            {/*    return (*/}
                            {/*        <div key={user.id} className="occupancy-step__tenants-name">*/}
                            {/*            <span className='heading-secondary'>{user.first_name} {user.last_name}</span>*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*})}*/}
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_two'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
