import React from "react"
import {inject} from "mobx-react"
import {Button} from 'antd'

import {LeaseStepsContainer} from ".."
// import {Checkbox} from "../../../components"
import {AdditionalTermsStepContent} from "./components/AdditionalTermsStepContent/AdditionalTermsStepContent"
import * as Icons from "../../../components/icons"
// import {SignatureModal} from "../components/SignatureModal/SignatureModal"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {Props, InjectedProps, State} from "./types"
import {AdditionalTerms, ADDITIONAL_TERM_ALIAS} from "../../../types/AdditionalTerms"

// import {saveTenantInitialForAdditionalTerms} from "../../../networking/lease"

import './styles.scss'

@inject('Core', 'Lease')
export class AdditionalTermsStep extends ComponentWithInjectedProps<Props, State, InjectedProps> {

    state = {
        isConfirm: false,
        isVisibleSignatureModal: false,
        loading: false,
        term_id: 0,
        term_alias: '',
        signed_terms: {
            [ADDITIONAL_TERM_ALIAS.pets]: false,
            [ADDITIONAL_TERM_ALIAS.defence_clause]: false,
            [ADDITIONAL_TERM_ALIAS.original_condition_report]: false,
        }
    }

    // private changeConfirm = (): void => {
    //     this.setState({isConfirm: !this.state.isConfirm})
    // }

    componentDidMount(): void {
        window.scrollTo(0, 0)
        const {Lease: {lease: {additional_terms}}} = this.injectedProps
        const signed_terms = additional_terms.reduce((prev, term) => {
            if (term.user_terms && term.user_terms.signature) {
                prev[term.alias] = true
            }
            return prev
        }, this.state.signed_terms)
        this.setState({signed_terms})
    }

    // private saveTenantInitial = (signature: any): any => {
    //     const initial = signature.toDataURL()
    //     if (signature.isEmpty()) {
    //         return message.warning({
    //             content: 'Set your initial please'
    //         })
    //     }
    //     this.setState({loading: true}, () => {
    //         saveTenantInitialForAdditionalTerms(initial, this.state.term_id)
    //             .then(res => {
    //                 if (res.status === 200) {
    //                     message.info({
    //                         content: 'Initial has been saved'
    //                     })
    //                     const signed_terms = {...this.state.signed_terms, [this.state.term_alias]: true}
    //                     this.setState({loading: false, isVisibleSignatureModal: false, signed_terms})
    //                 }
    //             })
    //             .catch(err => this.setState({loading: false}))
    //     })
    // }

    private getTermIdByAlias = (term_alias: string): number => {
        const {Lease: {lease: {additional_terms}}} = this.injectedProps
        return additional_terms.reduce((prev, term): AdditionalTerms => {
            if (term.alias === term_alias) {
                prev = term
            }
            return prev
        }).id
    }

    private openModalForSetInitial = (term_alias: string): void => {
        this.setState({isVisibleSignatureModal: true, term_id: this.getTermIdByAlias(term_alias), term_alias})
    }

    private agree = () => {
        // const {signed_terms} = this.state
        const {history, nextPath} = this.props
        // const isSignedAllTerms = Object.values(signed_terms).every((term) => term)
        // if (isSignedAllTerms) {
            history.push(nextPath)
        // } else {
        //     message.warning({
        //         content: 'You have not signed all terms'
        //     })
        // }
    }

    render(): React.ReactElement {
        const {signed_terms} = this.state
        const {Core, Lease: { lease:{additional_terms, rent_increase, property:{state}}}} = this.injectedProps
        const title = state === "VIC" ? "Owners corporation & Condition report" : "Additional terms";

        return (
            <LeaseStepsContainer title={title}>
                {{
                    content: (
                        <div className="additional-terms-step__container">
                            <Icons.WebChecklist/>

                            <AdditionalTermsStepContent
                                rent_increase={rent_increase}
                                additional_terms={additional_terms}
                                signed_terms={signed_terms}
                                openModalForSetInitial={this.openModalForSetInitial}
                                state={state}
                            />

                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_two'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    // disabled={!isConfirm}
                                    onClick={() => this.agree()}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
