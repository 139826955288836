import React from "react"

export function Agreed() {
    return (
        <svg width={25} height={25} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M33 16.5C33 25.6128 25.6128 33 16.5 33C7.38722 33 0 25.6128 0 16.5C0 7.38722 7.38722 0 16.5 0C25.6128 0 33 7.38722 33 16.5Z"
                  fill="#F93A73"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.10477 14.3414C8.00158 17.3226 10.8984 20.3039 13.7952 23.2852C14.1562 23.6567 14.9912 23.5109 15.2971 23.1183C16.6033 21.4415 17.9855 19.8225 19.3961 18.2384C21.928 15.3954 24.5948 12.5588 27.6804 10.346C28.6392 9.65836 27.1941 9.23889 26.5745 9.68317C23.4419 11.9296 20.716 14.7796 18.1391 17.661C16.6734 19.2999 15.2387 20.9769 13.8845 22.7152C14.3851 22.6597 14.8858 22.604 15.3864 22.5485C12.4896 19.5672 9.59279 16.5859 6.69597 13.6047C6.21159 13.1061 4.57342 13.7945 5.10477 14.3414Z"
                  fill="white"
            />
        </svg>
    )
}
