import React, {Component} from "react"
import {Spin, Icon} from 'antd'
//@ts-ignore
import Div100vh from 'react-div-100vh'

import {MainLayoutContentContainer, MainLayoutFooter} from "../../components"

import {Props, State} from "./types"

import logo from '../../resources/images/svg/logo-with-text.svg'

import './styles.scss'

import {verificationTenant} from "../../networking/verification"
import {setItem} from "../../helpers/storage"
import {VERIFICATION_SMS_CODE, START_PAGE, ALREADY_SIGNED, INVALID_PAGE} from "../../routing/paths";

const antIcon = <Icon type="loading" style={{fontSize: 24}} spin/>;

export class VerificationTenantPage extends Component<Props, State> {

    componentDidMount(): void {
        const {match: {params: {code}}, history} = this.props
        setItem('link_code', code)
        verificationTenant(code)
            .then(res => {
                if (res.status === 202) {
                    return history.replace(ALREADY_SIGNED)
                }
                setItem('token', res.data.token)
                history.replace(START_PAGE)
            })
            .catch(err => {
                if (err.response.status === 410) {
                    setItem("phone_number", err.response.data.phone_number)
                    history.replace(VERIFICATION_SMS_CODE)
                } else if (err.response.status === 404) {
                    history.replace(INVALID_PAGE)
                }
            })
    }

    render(): React.ReactElement {
        return (
            <>
                <Div100vh style={{
                    minHeight: '100rvh',
                    backgroundColor: '#fff',
                    display: 'flex',
                    maxWidth: 991,
                    margin: '0 auto'
                }}>
                    <MainLayoutContentContainer>

                        <div className='verification-tenant-page__container'>
                            <p className='welcome-page__greeting-text_secondary'>
                                Please wait
                            </p>
                            <Spin indicator={antIcon}/>
                        </div>
                    </MainLayoutContentContainer>
                    <MainLayoutFooter>

                    </MainLayoutFooter>
                </Div100vh>
            </>
        )
    }
}
