import React from "react"
import {Button, message} from 'antd'
import PinInput from 'react-pin-input'
import {observer, inject} from "mobx-react"

import {MainLayoutContentContainer, MainLayoutFooter} from "../../components"
import * as Icons from '../../components/icons'

import {ComponentWithInjectedProps} from "../../services/ComponentWithInjectedProps"

import {Props, InjectedProps, State} from "./types"

import {verificationSmsCode, resendSmsForVerification} from "../../networking/verification"
import {getItem, setItem} from "../../helpers/storage"

import {START_PAGE} from "../../routing/paths"

import './styles.scss'

@inject('Lease')
@observer
export class VerificationSmsPage extends ComponentWithInjectedProps<Props, State, InjectedProps> {
    phone_number: string | null = getItem('phone_number')
    state = {
        code: '',
        isCodeCompleted: false,
        loading: false,
        phone_number: this.phone_number ? this.phone_number : ''
    }

    private resendCode = (): void => {
        const code = getItem('link_code')
        this.setState({loading: true}, () => {
            resendSmsForVerification(code ? code : '')
                .then(res => {
                    message.info({
                        content: 'Code has been sent'
                    })
                })
                .finally(() => this.setState({loading: false}))
        })
    }

    private onComplete = (value: string, index: number): void => {
        this.setState({isCodeCompleted: true, code: value}, () => {
            this.confirm()
        })
    }

    private onChange = (value: string) => {
        const {isCodeCompleted} = this.state
        if (value.length < 4 && isCodeCompleted) {
            this.setState({isCodeCompleted: false})
        }
    }

    private confirm = () => {
        const {code} = this.state
        const {history} = this.props
        const link_code = getItem('link_code')
        this.setState({loading: true}, () => {
            verificationSmsCode(code, link_code ? link_code : '')
                .then(res => {
                    setItem('token', res.data.token)
                    history.replace(START_PAGE)
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.setState({loading: false})
                        message.error({
                            content: 'Invalid code'
                        })
                    }
                })
        })
    }

    render(): React.ReactElement {
        const {isCodeCompleted, loading, phone_number} = this.state
        return (
            <>
                <MainLayoutContentContainer>
                    <div className='welcome-page__container'>
                        <Icons.PhoneVerification/>
                        <h3 className='welcome-page__greeting-text'>Verification Code</h3>
                        <p className='welcome-page__greeting-text_secondary'>
                            To view your lease please type the verification code sent to {phone_number}
                        </p>
                        <PinInput
                            length={4}
                            initialValue=""
                            onChange={this.onChange}
                            type="numeric"
                            style={{padding: '10px'}}
                            inputStyle={{borderColor: '#DFDFDF', width: 33, height: 33, marginLeft: 15}}
                            inputFocusStyle={{borderColor: '#4d7cfe'}}
                            onComplete={this.onComplete}
                        />
                        <div style={{marginTop: 25}}>
                            <Button
                                className='fullwidth t-uppercase app-basic-button_large'
                                shape="round"
                                type="default"
                                loading={loading}
                                disabled={loading}
                                onClick={() => this.resendCode()}
                            >
                                Resend code
                            </Button>
                        </div>
                    </div>
                </MainLayoutContentContainer>
                <MainLayoutFooter>
                    <div className='welcome-page__buttons-container'>
                        <Button
                            disabled={!isCodeCompleted}
                            className='fullwidth t-uppercase app-basic-button_large'
                            shape="round"
                            type="danger"
                            loading={loading}
                            onClick={this.confirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </MainLayoutFooter>
            </>
        )
    }
}
