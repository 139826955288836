import React from "react"
import {inject} from "mobx-react"
import {
  Button,
  message
} from 'antd'

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons"
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {
  Props,
  InjectedProps,
  State
} from "./types"

import './styles.scss'

@inject('Core', 'Lease')
export default class VICCPart extends ComponentWithInjectedProps<Props, State, InjectedProps> {

  state = {
    isConfirm: false,
    loading: false,
    isVisibleSignatureModal: false
  }

  // private changeConfirm = (): void => {
  //   this.setState({isConfirm: !this.state.isConfirm})
  // }

  // private saveTenantInitial = (signature: any): any => {
  //   const initial = signature.toDataURL()
  //   const {nextPath, history} = this.props
  //   if (signature.isEmpty()) {
  //     return message.warning({
  //       content: 'Set your initial please'
  //     })
  //   }
  //   this.setState({loading: true}, () => {
  //     saveTenantInitial(initial, 'agreements')
  //     .then(res => {
  //       if (res.status === 200) {
  //         // message.info({
  //         //     content: 'Initial has been saved'
  //         // })
  //         history.push(nextPath)
  //       }
  //     })
  //     .catch(err => this.setState({loading: false}))
  //   })
  // }

  render(): React.ReactElement {
    const {isConfirm, loading, isVisibleSignatureModal} = this.state
    const {Core, Lease: {getCurrentUser, lease: {property: {state}}}, Lease} = this.injectedProps
    const user = getCurrentUser()
    const {nextPath, history} = this.props
    return (
      <LeaseStepsContainer title={'Part C – Safety-related activities'}>
        {{
          content: (
            <div className="general-agreement-step__container">
              <Icons.WebChecklist/>

              <h4 className='heading-blue text-left w-100'>
                Electrical Safety Checks
              </h4>
              <div className="heading-secondary list alpha">
                <div className="list-item">
                  The rental provider must ensure an electrical safety check of all electrical installations, appliances
                  and fittings provided by a rental provider in the rented premises is conducted every 2 years by a
                  licensed or registered electrician and must provide the renter with the date of the most recent safety
                  check, in writing, on request of the renter.
                </div>
                <div className="list-item">
                  If an electrical safety check of the rented premises has not been conducted within the last 2 years at
                  the time the renter occupies the premises, the rental provider must arrange an electrical safety check
                  as soon as practicable.
                </div>
              </div>

              <h4 className='heading-blue text-left w-100'>
                Gas Safety Activities
              </h4>
              <p className="heading-secondary">This safety-related activity only applies if the rented premises contains any
                appliances, fixtures or fittings which use or supply gas.</p>
              <div className="heading-secondary list alpha">
                <div className="list-item">
                  The rental provider must ensure that a gas safety check of all gas installations and fittings in the
                  rented premises is conducted every 2 years by a licensed or registered gasfitter and must provide the
                  renter with the date of the most recent safety check, in writing, on request of the renter.
                </div>
                <div className="list-item">
                  If a gas safety check has not been conducted within the last 2 years at the time the renter occupies the
                  premises, the rental provider must arrange a gas safety check as soon as practicable.
                </div>
              </div>

              <h4 className='heading-blue text-left w-100'>
                Smoke Alarm Safety Activities
              </h4>
              <div className="heading-secondary list alpha">
                <div className="list-item">
                  The rental provider must ensure that—
                  <div className="heading-secondary list roman">
                    <div className="list-item">
                      any smoke alarm is correctly installed and in working condition; and
                    </div>
                    <div className="list-item">
                      any smoke alarm is tested according to the manufacturer's instructions at least once every 12 months; and
                    </div>
                    <div className="list-item">
                      the batteries in each smoke alarm are replaced as required.
                    </div>
                  </div>
                </div>
                <div className="list-item">
                  The rental provider must immediately arrange for a smoke alarm to be repaired or replaced as an urgent
                  repair if they are notified by the renter that it is not in working order.<br/>
                  <b>Note:</b> Repair or replacement of a hard-wired smoke alarm must be undertaken by a suitably qualified
                  person.
                </div>
                <div className="list-item">
                  The rental provider, on or before the commencement of the agreement, must provide the renter with the
                  following information in writing—
                  <div className="heading-secondary list roman">
                    <div className="list-item">
                      information about how each smoke alarm in the rented premises operates;
                    </div>
                    <div className="list-item">
                      information about how to test each smoke alarm in the rented premises;
                    </div>
                    <div className="list-item">
                      information about the renter's obligations to not tamper with any smoke alarms and to report if a smoke
                      alarm in the rented premises is not in working order.
                    </div>
                  </div>
                </div>
                <div className="list-item">
                  The renter must give written notice to the rental provider as soon as practicable after becoming aware that a
                  smoke alarm in the rented premises is not in working order.<br/>
                  <b>Note:</b> Regulations made under the <b>Building Act 1993</b> require smoke alarms to be installed in all
                  residential buildings.
                </div>
              </div>

              <h4 className='heading-blue text-left w-100'>
                Swimming Pool Barrier Safety Activities
              </h4>
              <p className="heading-secondary">These safety-related activities only apply if the rented premises
                contains a swimming pool.</p>
              <div className="heading-secondary list alpha">
                <div className="list-item">
                  The rental provider must ensure that the swimming pool barrier is maintained in good repair.
                </div>
                <div className="list-item">
                  The renter must give written notice to the rental provider as soon as practicable after becoming aware that
                  the swimming pool barrier is not in working order.
                </div>
                <div className="list-item">
                  The rental provider must arrange for a swimming pool barrier to be immediately repaired or replaced as an
                  urgent repair if they are notified by the renter that it is not in working order.
                </div>
                <div className="list-item">
                  The rental provider must provide the renter with a copy of the most recent certificate of swimming pool
                  barrier compliance issued under the Building Act 1993 on the request of the renter.
                </div>
              </div>

              <h4 className='heading-blue text-left w-100'>
                Relocatable Swimming Pool Safety Activities
              </h4>
              <p className="heading-secondary">These safety-related activities only apply if a relocatable swimming pool is
                erected, or is intended to be erected, on the rented premises.</p>
              <div className="heading-secondary list alpha">
                <div className="list-item">
                  The renter must not erect a relocatable swimming pool without giving written notice to the rental
                  provider before erecting the pool.
                </div>
                <div className="list-item">
                  The renter must obtain any necessary approvals before erecting a relocatable swimming pool.<br/>
                  <b>Note:</b> Regulations made under <b>Building Act 1993</b> apply to any person erecting a relocatable
                  swimming pool.
                  This safety-related activity only applies to swimming pools or spas that hold water deeper than 300 mm.
                </div>
              </div>

              <h4 className='heading-blue text-left w-100'>
                Bushfire Prone Area Activities
              </h4>
              <p className="heading-secondary">This safety-related activity only applies if the rented premises is in a
                bushfire prone area and is required to have a water tank for bushfire safety.<br/>
                If the rented premises is in a designated bushfire prone area under section 192A of the Building Act 1993 and
                a water tank is required for firefighting purposes, the rental provider must ensure the water tank and any
                connected infrastructure is maintained in good repair as required.<br/>
                The water tank must be full and clean at the commencement of the agreement.</p>

              {/*<div className='witness-step__confirmation-container w-100'>*/}
              {/*  <div>*/}
              {/*    <Checkbox checked={isConfirm} onChange={this.changeConfirm}>*/}
              {/*      <p className='small-paragraph mt-0'>*/}
              {/*        I {user.first_name} {user.last_name} agree to the general terms of*/}
              {/*        the agreement.*/}
              {/*      </p>*/}
              {/*    </Checkbox>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<SignatureModal*/}
              {/*  title={'Initial'}*/}
              {/*  isVisible={isVisibleSignatureModal}*/}
              {/*  onCancel={() => this.setState({isVisibleSignatureModal: false})}*/}
              {/*  onSave={this.saveTenantInitial}*/}
              {/*  loading={loading}*/}
              {/*/>*/}
            </div>
          ),
          footer: (
            <div className='footer__buttons-container_two'>
              <div>
                <Button
                  className='fullwidth t-uppercase app-basic-button_large'
                  shape="round"
                  type="default"
                  loading={false}
                  onClick={() => Core.toggleCallDialog()}
                >
                  Disagree
                </Button>
              </div>
              <div>
                <Button
                  // disabled={!isConfirm}
                  className='fullwidth t-uppercase app-basic-button_large'
                  shape="round"
                  type="danger"
                  loading={false}
                  onClick={() => history.push(nextPath)}
                  // onClick={() => this.setState({isVisibleSignatureModal: true})}
                >
                  Agree
                </Button>
              </div>
            </div>
          )
        }}
      </LeaseStepsContainer>
    )
  }
}
