import {UserTerms} from "./UserTerms"

export interface AdditionalTerms {
    alias: string
    created_at: string
    id: number
    lease_id: string
    meta_data: []
    title: string
    updated_at: string
    value: boolean
    user_terms: UserTerms
}

export const ADDITIONAL_TERM_ALIAS: { [key: string]: string } = {
    pay_for_water: 'pay_for_water',
    residential_premises: 'residential_premises',
    pets: 'pets',
    break_lease_fee: 'break_lease_fee',
    defence_clause: 'defence_clause',
    original_condition_report: 'original_condition_report',
    swimming_pool:'swimming_pool',
    gas_supplied:'gas_supplied',
    electricity_supplied:'electricity_supplied',
    corporation_rules:'corporation_rules',
    condition_report :'corporation_rules',
    condition_report_will_provided:'corporation_rules',
    electricity :'electricity',
    gas : 'gas',
    phone : 'phone',
    water : 'water',
    any_other : 'any_other',
    body_corporate : 'body_corporate',
    pets_allowed : 'pets_allowed'
};

