import 'core-js/features/promise'
import 'core-js/features/map'
import 'core-js/features/set'
import 'core-js/features/array/find'
import 'core-js/features/object/assign'
import 'core-js/features/object/keys'
import 'core-js/features/string/starts-with'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/index.scss'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App/>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
