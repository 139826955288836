import axios, {AxiosPromise} from 'axios'

export function verificationTenant(code: string): AxiosPromise<{ token: string }> {
    return axios.get(`/${code}`)
}

export function verificationSmsCode(short_code: string, link_code: string): AxiosPromise<{ token: string }> {
    return axios.post('/renew/code', {short_code, link_code})
}

export function resendSmsForVerification(code: string): AxiosPromise {
    return axios.post('/resend/code/sms', {code})
}

export function generateCode(): AxiosPromise {
    return axios.get('/test-code')
}


