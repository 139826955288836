import React from "react"
import {inject, observer} from "mobx-react"
import {Button} from 'antd'

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons";

import {Props, InjectedProps} from "./types"

import './styles.scss'

@inject('Lease', 'Core')
@observer
export class TenantDetailsSteps extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        const {Lease, Core} = this.injectedProps
        const {users} = Lease.lease
        const state = Lease.lease.property.state

        const title = state === 'vic' ? 'Renter Details' : 'Tenant Details';

        const sortedUser = [...users.filter(user => user.current_location.is_primary_user), ...users.filter(user => !user.current_location.is_primary_user)]
        return (
            <LeaseStepsContainer title={title}>
                {{
                    content: (
                        <div className='tenant-details-step__container'>
                            <Icons.UserCard/>
                            {sortedUser.map((user) => {
                                return (
                                    <div className="tenant-details-step__tenant-card" key={user.id}>
                                        {/*<h5 className="tenant-details-step__tenant-card__heading">{index === 0 ? 'Primary Tenant' : `Tenant ${index + 1}`}</h5>*/}
                                        <h4 className="heading-primary">{user.first_name} {user.last_name}</h4>
                                        <h5 className="heading-secondary">
                                            {user.primary_email != null ? user.primary_email.email : ""}
                                        </h5>
                                        <h5 className="heading-secondary w-100 text-center">
                                            {user.primary_phone_number.phone_number}
                                        </h5>
                                        {state === 'vic' &&
                                        <h5 className="heading-secondary w-100 text-center">
                                            {user.current_location.current_address}
                                        </h5>}
                                    </div>
                                )
                            })}
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_two'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}

            </LeaseStepsContainer>
        )
    }
}
