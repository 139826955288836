import React from "react"
import {inject, observer} from "mobx-react"
import {Button, Table} from 'antd'

import {LeaseStepsContainer} from ".."

import {Props, State, InjectedProps} from "./types"

import {emergencyColumns} from "./columns"

import './styles.scss'
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps";

@inject('Lease')
@observer
export class EmergencyContactStep extends ComponentWithInjectedProps<Props, State, InjectedProps> {

    state = {
        checkedIds: []
    }

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        const {Lease: {lease: {emergency_contacts}}} = this.injectedProps
        console.log(emergency_contacts)
        return (
            <LeaseStepsContainer
                background={false}
                title={'Agents Emergency Tradespeople Contacts'}
            >
                {{
                    content: (
                        <div className="emergency-contact-step__container table__row-with-border">
                            <Table
                                className="custom"
                                showHeader={false}
                                dataSource={Array.from(emergency_contacts)}
                                columns={emergencyColumns()}
                                rowKey={(record, index) => index.toString()}
                                rowClassName={(record: any): string => record.type}
                                loading={false}
                                pagination={false}
                            />
                            <div className='emergency-contact-step__button-container'>

                            </div>
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_one'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                                >
                                    Ok
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
