import React from "react"

export function ConnectionUser() {
    return (
        <svg width={174} height={198} viewBox="0 0 174 198" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M128.941 108.862L90.6595 118.796C88.2936 119.41 85.8111 119.422 83.4393 118.832L43.054 108.783C36.6494 107.188 32.155 101.448 32.155 94.8605V50.8299C32.155 23.6253 54.2541 1.5715 81.5147 1.5715H90.3414C117.602 1.5715 139.701 23.6253 139.701 50.8299V94.9756C139.7 101.509 135.278 107.218 128.941 108.862Z" fill="#B4B4B4" />
            <path fillRule="evenodd" clipRule="evenodd" d="M81.5146 2.74987C54.9489 2.74987 33.3359 24.3185 33.3359 50.8297V94.8607C33.3359 100.919 37.4497 106.173 43.3397 107.639L83.7251 117.688C85.8993 118.23 88.1939 118.218 90.3622 117.655L128.644 107.722C134.458 106.213 138.519 100.972 138.52 94.9754V50.8297C138.52 24.3185 116.907 2.74987 90.3414 2.74987H81.5146ZM86.9775 120.443C85.6914 120.443 84.4057 120.288 83.1538 119.976L42.7681 109.926C35.8239 108.198 30.9739 102.003 30.9739 94.8607V50.8297C30.9739 23.0185 53.6463 0.392731 81.5146 0.392731H90.3414C118.21 0.392731 140.882 23.0185 140.882 50.8297V94.9758C140.881 102.045 136.093 108.224 129.239 110.003L90.9567 119.936C89.6564 120.274 88.3172 120.443 86.9775 120.443Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M80.6575 116.925L83.725 117.688C85.8996 118.229 88.1939 118.218 90.3622 117.655L128.644 107.721C134.459 106.212 138.52 100.971 138.52 94.9754V50.8297C138.519 24.3185 116.907 2.75022 90.3413 2.75022H83.7356C104.33 9.28148 118.977 28.6152 118.977 50.8297V94.9758C118.977 102.044 114.189 108.224 107.334 110.003L80.6575 116.925ZM86.9774 120.442C85.6917 120.442 84.4056 120.287 83.1538 119.976L71.0868 116.973L106.739 107.722C112.554 106.213 116.615 100.972 116.615 94.9758V50.8297C116.615 26.8072 98.6469 6.31855 74.8203 3.17098L65.9734 2.00262L74.8203 0.83426C77.0374 0.541582 79.2896 0.393082 81.5142 0.393082H90.3413C118.209 0.393082 140.881 23.0189 140.882 50.8297V94.9754C140.882 102.044 136.094 108.224 129.239 110.002L90.9566 119.936C89.6563 120.273 88.3171 120.442 86.9774 120.442Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34.4394 81.581H44.9136V56.0618H34.4394C31.3621 56.0618 28.8674 58.5513 28.8674 61.6223V76.0209C28.8674 79.0915 31.3621 81.581 34.4394 81.581Z" fill="#727272" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34.4394 57.2404C32.0183 57.2404 30.0484 59.2058 30.0484 61.6223V76.0209C30.0484 78.437 32.0183 80.4024 34.4394 80.4024H43.7326V57.2404H34.4394ZM46.0946 82.7596H34.4394C30.7161 82.7596 27.6864 79.7366 27.6864 76.0209V61.6223C27.6864 57.9063 30.7161 54.8832 34.4394 54.8832H46.0946V82.7596Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M139.525 81.581H129.052V56.0618H139.525C142.603 56.0618 145.097 58.5513 145.097 61.6223V76.0209C145.098 79.0915 142.603 81.581 139.525 81.581Z" fill="#727272" />
            <path fillRule="evenodd" clipRule="evenodd" d="M130.232 80.4024H139.525C140.698 80.4024 141.801 79.9467 142.631 79.119C143.46 78.2912 143.917 77.1912 143.917 76.0209V61.6223C143.917 59.2058 141.947 57.2404 139.525 57.2404H130.232V80.4024ZM139.525 82.7596H127.87V54.8832H139.525C143.249 54.8832 146.279 57.9063 146.279 61.6223V76.0209C146.279 77.8206 145.577 79.513 144.301 80.7859C143.025 82.0587 141.329 82.7596 139.525 82.7596Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M110.633 126.108C106.97 125.374 104.334 122.162 104.334 118.433V100.787H86.9827H69.6312V118.433C69.6312 122.162 66.9956 125.374 63.3325 126.108L59.1046 126.956V155.293H86.9827H114.861V126.956L110.633 126.108Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M60.2856 154.115H113.68V127.922L110.4 127.264H110.4C106.201 126.421 103.153 122.708 103.153 118.433V101.966H70.8122V118.433C70.8122 122.708 67.7644 126.422 63.5652 127.264L60.2856 127.922V154.115ZM116.042 156.472H57.9236V125.991L63.0995 124.953C66.2 124.331 68.4502 121.589 68.4502 118.433V99.6088H105.515V118.433C105.515 121.589 107.766 124.331 110.866 124.953H110.866L116.042 125.991V156.472Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M146.76 135.622L111.957 124.794C110.876 124.457 109.698 124.73 108.877 125.507L86.9826 146.213L65.0885 125.507C64.2669 124.73 63.0895 124.457 62.0084 124.794L27.2053 135.622C11.7752 140.156 1.18088 154.291 1.18088 170.343V193.481C1.18088 195.323 2.67759 196.816 4.52309 196.816H169.442C171.288 196.816 172.784 195.322 172.784 193.481V170.343C172.784 154.291 162.19 140.156 146.76 135.622Z" fill="#F93A73" />
            <path fillRule="evenodd" clipRule="evenodd" d="M62.9376 125.831C62.7443 125.831 62.5499 125.859 62.3601 125.919L27.557 136.747C20.2147 138.905 13.9259 143.268 9.3527 149.369C4.77949 155.471 2.36199 162.723 2.36199 170.343V193.481C2.36199 194.67 3.33119 195.637 4.52282 195.637H169.442C170.634 195.637 171.603 194.67 171.603 193.481V170.343C171.603 162.723 169.186 155.471 164.613 149.369C160.039 143.268 153.751 138.905 146.426 136.753L146.408 136.747L111.606 125.919C110.934 125.709 110.2 125.879 109.689 126.363L86.9827 147.837L64.2761 126.363C63.91 126.016 63.4285 125.831 62.9376 125.831ZM169.442 197.994H4.52282C2.02895 197.994 0 195.969 0 193.481V170.343C0 162.21 2.58008 154.469 7.46113 147.957C12.3426 141.445 19.0546 136.789 26.872 134.492L61.657 123.668C63.1439 123.206 64.7701 123.582 65.9007 124.651L86.9827 144.589L108.065 124.651C109.196 123.582 110.822 123.206 112.309 123.668L147.103 134.494C154.916 136.792 161.625 141.448 166.504 147.957C171.385 154.47 173.965 162.21 173.965 170.343V193.481C173.965 195.969 171.936 197.994 169.442 197.994Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M86.9827 115.46C63.0955 115.46 43.7315 96.1349 43.7315 72.2967V60.1665C43.7315 36.3291 63.0959 17.0045 86.9827 17.0045C110.87 17.0045 130.234 36.3291 130.234 60.1665V72.2967C130.234 96.1353 110.87 115.46 86.9827 115.46Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M86.9827 18.1829C63.7852 18.1829 44.9125 37.0165 44.9125 60.1668V72.297C44.9125 95.4469 63.7852 114.281 86.9827 114.281C110.181 114.281 129.053 95.4469 129.053 72.297V60.1668C129.053 37.0165 110.181 18.1829 86.9827 18.1829ZM86.9827 116.638C62.4826 116.638 42.5505 96.7465 42.5505 72.297V60.1668C42.5505 35.7169 62.4826 15.8258 86.9827 15.8258C111.482 15.8258 131.415 35.7169 131.415 60.1668V72.297C131.415 96.7465 111.482 116.638 86.9827 116.638Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M65.0885 125.507L86.9826 146.213L73.4799 150.804C70.1715 151.928 66.51 150.951 64.2067 148.327L47.5055 129.306L62.0081 124.794C63.0887 124.457 64.2669 124.73 65.0885 125.507Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M49.5901 129.892L65.095 147.55C67.0905 149.824 70.2327 150.662 73.0994 149.688L84.7515 145.727L64.2761 126.363H64.2765C63.7656 125.88 63.0302 125.71 62.3602 125.919L49.5901 129.892ZM70.6835 152.446C67.9109 152.446 65.2202 151.269 63.3188 149.104L45.4208 128.721L61.6567 123.669C63.1436 123.206 64.7694 123.582 65.9008 124.651L89.2141 146.699L73.8607 151.919C72.8163 152.274 71.7436 152.446 70.6835 152.446Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M108.877 125.507L86.9828 146.213L99.2207 150.373C103.294 151.758 107.803 150.555 110.639 147.324L126.46 129.306L111.957 124.794C110.877 124.457 109.698 124.73 108.877 125.507Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M89.214 145.726L99.6016 149.257C103.236 150.493 107.22 149.43 109.751 146.548L124.375 129.892L111.606 125.919C110.934 125.71 110.2 125.88 109.689 126.363L89.214 145.726ZM102.664 152.124C101.388 152.124 100.097 151.916 98.8395 151.489L84.7514 146.7L108.065 124.651C109.196 123.582 110.823 123.206 112.309 123.669L128.545 128.72L111.527 148.101C109.239 150.707 106.001 152.124 102.664 152.124Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M61.1473 179.689H38.8855C37.6494 179.689 36.6475 178.689 36.6475 177.456V167.616C36.6475 166.382 37.6494 165.382 38.8855 165.382H61.1473C62.3834 165.382 63.3852 166.382 63.3852 167.616V177.456C63.3852 178.689 62.3834 179.689 61.1473 179.689Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.8854 166.561C38.3027 166.561 37.8284 167.034 37.8284 167.616V177.456C37.8284 178.037 38.3027 178.51 38.8854 178.51H61.1471C61.7302 178.51 62.2041 178.037 62.2041 177.456V167.616C62.2041 167.034 61.7302 166.561 61.1471 166.561H38.8854ZM61.1471 180.868H38.8854C37.0001 180.868 35.4664 179.337 35.4664 177.456V167.616C35.4664 165.734 37.0001 164.204 38.8854 164.204H61.1471C63.0324 164.204 64.5661 165.734 64.5661 167.616V177.456C64.5661 179.337 63.0324 180.868 61.1471 180.868Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M139.7 59.9278C139.7 59.9278 121.04 58.4499 114.655 41.3206C114.655 41.3206 69.5457 77.3613 32.1546 59.9278V50.8297C32.1546 23.6251 54.2538 1.57127 81.5143 1.57127H90.3411C117.602 1.57127 139.701 23.6251 139.701 50.8297V59.9278H139.7Z" fill="#D8D8D8" />
            <path fillRule="evenodd" clipRule="evenodd" d="M33.3356 59.1711C52.0772 67.5503 72.8002 62.1423 86.9607 56.0785C102.584 49.3881 113.805 40.4895 113.917 40.4008L115.192 39.3817L115.761 40.9099C120.864 54.5966 134.314 57.8778 138.519 58.579L138.52 50.8299C138.52 24.3184 116.907 2.74972 90.3411 2.74972H81.5143C54.9482 2.74972 33.3356 24.3184 33.3356 50.8299V59.1711ZM54.4936 65.8292C46.2467 65.8296 38.573 64.2212 31.6547 60.9955L30.9736 60.6781V50.8299C30.9736 23.0188 53.646 0.392578 81.5144 0.392578H90.3411C118.209 0.392578 140.882 23.0188 140.882 50.8299V61.2037L139.607 61.1027C139.408 61.087 134.668 60.6871 129.078 58.1182C122.145 54.9321 117.008 49.7924 114.138 43.1955C110.746 45.7157 100.774 52.7282 87.8917 58.2447C76.0987 63.2953 64.8446 65.8292 54.4936 65.8292Z" fill="#343F4D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M94.6934 110.093C91.9621 110.093 89.5462 108.132 89.0691 105.354C88.5357 102.251 90.6245 99.3033 93.7344 98.7714L103.368 97.1225C106.479 96.5894 109.432 98.6747 109.965 101.778C110.499 104.881 108.41 107.829 105.3 108.361L95.6661 110.01C95.3394 110.066 95.0142 110.093 94.6934 110.093Z" fill="#F93A73" />
            <path fillRule="evenodd" clipRule="evenodd" d="M110.042 102.955L109.888 100.603C109.946 100.599 115.805 100.186 121.669 97.5685C129.322 94.1526 133.394 88.7531 133.773 81.5195L136.132 81.6424C135.708 89.7365 131.019 95.9974 122.573 99.7473C116.327 102.52 110.296 102.938 110.042 102.955Z" fill="#343F4D" />
        </svg>

    )
}
