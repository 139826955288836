import React from "react"
import {inject} from "mobx-react"
import {Button} from 'antd'

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons";

import {Props, InjectedProps} from "./types"

import {formatDate} from "../../../helpers/date"

import './styles.scss'

@inject('Lease', 'Core')
export class RentalDetailsStep extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        const {Lease, Core} = this.injectedProps
        const {agency_manager, land_lord} = Lease.lease
        const {lease} = Lease
        const state = lease.property.state
        const periods: any = {yearly: 'Years', monthly: 'Months', weekly: 'Weeks',daily: 'Days' };
        const blockWidth = state == 'NSW' ? {flex: "0 0 33.333333%"} : {flex: "0 0 50%"};

        return (
            <LeaseStepsContainer title={'Terms and Rental Details'}>
                {{
                    content: (
                        <div className='rental-details-step__container'>
                            <Icons.UserCard/>
                            <div className="tenant-details-step__tenant-card">
                                <h5 className="tenant-details-step__tenant-card__heading">
                                    You will be renting the place from
                                </h5>
                                <h4 className="heading-primary">{land_lord.full_name}</h4>
                                <h5 className="heading-secondary">{land_lord.phone}</h5>
                            </div>
                            <div className="tenant-details-step__tenant-card">
                                <h5 className="tenant-details-step__tenant-card__heading">And your agent is</h5>
                                <h4 className="heading-primary">{agency_manager.first_name} {agency_manager.last_name}</h4>
                                <h5 className="heading-secondary">{agency_manager.email}</h5>
                                <h5 className="heading-secondary">{agency_manager.phone_number}</h5>
                            </div>
                            <div className='rental-details-step__lease-info__container'>
                                {(state == 'VIC' || state == 'QLD') &&
                                <div style={blockWidth}>
                                    <h5>Term</h5>
                                    <h4 className='heading-primary text-center'>
                                        {(lease.term_type == "periodic" || lease.end_date == null) ? "Periodic" : "Fixed"}
                                    </h4>
                                </div>
                                }
                                {!(lease.term_type == "periodic" || lease.end_date == null) &&
                                <div style={blockWidth}>
                                    <h5>Period</h5>
                                    <h4
                                      className='heading-primary text-center'>{lease.period} {periods[lease.period_type]}</h4>
                                </div>
                                }
                                <div style={blockWidth}>
                                    <h5>Starting on</h5>
                                    <h4 className='heading-primary text-center'>{formatDate(lease.start_date)}</h4>
                                </div>
                                {lease.end_date &&
                                <div style={blockWidth}>
                                    <h5>Ending on</h5>
                                    <h4 className='heading-primary text-center'>{formatDate(lease.end_date)}</h4>
                                </div>
                                }
                            </div>
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_two'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
