import React from 'react'
//@ts-ignore
import Div100vh from 'react-div-100vh'

import {MainLayoutContentContainer, MainLayoutFooter} from "../../components"

import {Props} from "./types"

import image from "../../resources/images/svg/congratulation.svg";

import './styles.scss'

export function LeaseAlreadySigned(props: Props) {
    return (
        <>
            <Div100vh style={{
                minHeight: '100rvh',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 991,
                margin: '0 auto'
            }}>
                <MainLayoutContentContainer>
                    <div className='congratulation-page__container'>
                        <h3 className='heading-red__bold-secondary'>Congratulations your lease is signed and
                            complete</h3>
                        <img className="image" src={image}/>
                        <p className='heading-secondary'>
                            A PDF copy will be emailed to you shortly
                        </p>
                    </div>
                </MainLayoutContentContainer>
                <MainLayoutFooter>
                </MainLayoutFooter>
            </Div100vh>
        </>
    )
}
