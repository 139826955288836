import {observable, action, computed} from "mobx"

import {ILease} from "./types"
import {Witness} from '../../types/Witness'
import {File} from '../../types/File'

import {getLease, saveManualLocation} from "../../networking/lease"
import {CurrentLocation} from "../../types/CurrentLocation";

export class Lease implements ILease {
  //@ts-ignore
    @observable currentLease = {
        additional_terms: [],
        "id": "765eceff-4f06-46b7-a37f-89a28eebc306",
        "start_date": "2019-12-09",
        "end_date": "2019-12-09",
        "full_rent": 54,
        "rent_per_month": 45,
        "period": 123,
        "send_for_signing": null,
        "status": null,
        "period_type": "yearly",
        "property_id": "55b8433d-764d-47d4-920e-d71e8291ad79",
        "agency": {
            abn: 928971151,
            acn: 465310173,
            additional_address: "8542 Bashirian Creek Suite 880↵Brownmouth, AL 04354-3122",
            address: "5342 Cristian Village Apt. 398↵Kassulkeview, ID 81260-9003",
            agency_managers: [],
            alias: "dr-delphia-romaguera-phd-agency",
            city: "Ruthestad",
            country: "Dominica",
            created_at: "2020-01-10 09:54:24",
            deleted_at: null,
            email: "lgislason@gmail.com",
            id: "42e9a7f2-fb78-492c-bc5a-e01ef733a95c",
            legal_business_name: "Kuphal-Ferry_lbs",
            logo_id: null,
            name: "Dr. Delphia Romaguera PhD_agency",
            phone_number: "951.991.2910",
            postcode: "39506-8818",
            state: "Fiji",
            type: "default",
            updated_at: "2020-01-10 09:54:24",
            website: "http://www.kerluke.org/quidem-reprehenderit-dolor-voluptate-temporibus-facilis-corporis",
            emergency_contacts: []
        },
        "agency_id": "d474e9c4-b8cf-4c0c-ad7c-771526004c40",
        "agency_manager_id": "89b52021-1654-4712-b70f-d1bdd04f2615",
        "is_active": false,
        "has_connection" : false,
        "is_primary_tenant" : false,
        "created_at": "2019-12-20 14:46:46",
        "updated_at": "2019-12-20 14:47:06",
        "deleted_at": null,
        "property": {
            "id": "55b8433d-764d-47d4-920e-d71e8291ad79",
            "address": "asdsad",
            "additional_address": "sadasd",
            "state": "NSW",
            "city": "sadsad",
            "country": null,
            "post_code": "asdsad",
            "property_type": "Apartment",
            "bedrooms_count": 0,
            "bathrooms_count": 0,
            "created_at": "2019-12-20 14:46:46",
            "updated_at": "2019-12-20 14:46:46",
            "deleted_at": null,
            meta_data: {
                cords: {
                    lng: 0,
                    lat: 0
                }
            }
        },
        "land_lord": {
            "id": "2a9f1edd-8159-440d-843a-ca69f2d3d79e",
            "lease_id": "765eceff-4f06-46b7-a37f-89a28eebc306",
            "property_id": "55b8433d-764d-47d4-920e-d71e8291ad79",
            "full_name": 'Oleh',
            "notice_address": "6079 Macejkovic Plains Suite 054\nJerdeland, WA 04352-0428",
            "phone": "12123123",
            "use_agency_address": 1,
            "use_agent_phone": 0,
            "created_at": "2019-12-20 14:46:51",
            "updated_at": "2019-12-20 14:46:51",
            electronic_notice:false
        },
        "users": [
            {
                "id": "033600a9-fd9f-4a65-948c-078b5a129c60",
                "main_picture_id": null,
                "first_name": "Tom",
                "middle_name": null,
                "last_name": "Foster",
                "salutation": null,
                "gender": null,
                "password": null,
                "is_completed": false,
                "two_fa": false,
                "remember_token": null,
                "b_day": null,
                "created_at": "2019-12-20 14:46:59",
                "updated_at": "2019-12-20 14:46:59",
                "deleted_at": null,
                witness: null,
                electronic_notice:false,
                signature: null,
                primary_email: {
                    "id": 3,
                    "user_id": "033600a9-fd9f-4a65-948c-078b5a129c60",
                    "email": "tom@gmail.com",
                    "type": "personal",
                    "is_primary": true,
                    "created_at": "2019-12-20 14:46:59",
                    "updated_at": "2019-12-20 14:46:59",
                    "verified_at": null
                },
                "primary_phone_number":
                    {
                        "id": 3,
                        "user_id": "033600a9-fd9f-4a65-948c-078b5a129c60",
                        "phone_number": "+380965709695",
                        "iso_code": null,
                        "international_code": null,
                        "type": "personal",
                        "is_primary": true,
                        "created_at": "2019-12-20 14:46:59",
                        "updated_at": "2019-12-20 14:46:59",
                        "verified_at": null
                    },
                "me": true,
                current_location: {
                    is_primary_user: false,
                    meta_data: null
                }
            }
        ],
        "tenant_agent": null,
        "agency_manager": {
            "id": "89b52021-1654-4712-b70f-d1bdd04f2615",
            "first_name": "Jewel",
            "last_name": "Kautzer",
            "address": null,
            "email": "agency@manager.com",
            "title": "Property Manager",
            "phone_number": "593-435-8023 x75357",
            "avatar_id": null,
            "signature_id": null,
            "agency_id": "d474e9c4-b8cf-4c0c-ad7c-771526004c40",
            "is_completed": true,
            "created_at": "2019-12-16 09:50:30",
            "updated_at": "2019-12-16 13:08:08",
            "deleted_at": null,
            "public_phone_number": null,
            "sync_for_agency": false,
            "is_primary_manager": false,
            "avatar": null,
            "signature": null
        },
        "rent": {
            "id": "b046cc4e-3140-48e8-8d05-ceea1d0cdbb6",
            "lease_id": "765eceff-4f06-46b7-a37f-89a28eebc306",
            "frequency": "month",
            "rate": 123,
            "bond": 213123,
            held:false,
            "start_on": "2019-12-17",
            "created_at": "2019-12-20 14:47:17",
            "updated_at": "2019-12-20 14:47:17"
        },
        rent_increase: {
          id:'',
          lease_id:'',
          frequency: 'month',
          rate: 100,
          "start_on": "",
          "created_at": "",
          "updated_at": ""
        },
        smoke_alarm:{
          battery_operated: false,
          created_at: '',
          description: '',
          id: 1,
          property_id: '',
          replaceable: false,
          type: '',
          updated_at: ''
        },
        "payment_methods": [],
        "inclusion": {
            "id": 0,
            "lease_id": "765eceff-4f06-46b7-a37f-89a28eebc306",
            "property_furnished": false,
            "message": "sadasd",
            "created_at": "2019-12-20 14:47:24",
            "updated_at": "2019-12-20 14:47:24"
        },
        "occupant": {
            "id": "cb7977ab-365c-435c-b6f2-9e05ea4df3d0",
            "lease_id": "765eceff-4f06-46b7-a37f-89a28eebc306",
            "count": 123,
            "created_at": "2019-12-20 14:47:27",
            "updated_at": "2019-12-20 14:47:27"
        },
        "additional_term": null,
        "special_conditions": [],
        emergency_contacts: [],
        "documents": []
    }
    //@ts-ignore
    @computed get lease() {
        return this.currentLease
    }

    @action setLease() {
        return getLease()
            .then(res => {
                //@ts-ignore
                this.currentLease = res.data
                localStorage.setItem('current_lease_id', this.currentLease.id)
                return this.currentLease
            })
    }

    @action setWitness(witness: Witness): void {
        this.currentLease.users = this.currentLease.users.map(user => {
            if (user.id === witness.user_id) {
                //@ts-ignore
                user.witness = witness
            }
            return user
        })
    }

    @action setTenantSignature(signature: File) {
        this.currentLease.users = this.currentLease.users.map(user => {
            if (user.me) {
                //@ts-ignore
                user.signature = signature
            }
            return user
        })
    }

    @action setCurrentLocation = (current_location: { city: string, post_code: string }) => {
        return saveManualLocation(current_location)
            .then(res => {
                this.currentLease.users = this.currentLease.users.map(user => {
                    if (user.me) {
                        //@ts-ignore
                        user.current_location = res.data
                    }
                    return user
                })
                return res
            })
    }

    @action attachCurrentLocation = (current_location: CurrentLocation) => {
        this.currentLease.users = this.currentLease.users.map(user => {
            if (user.me) {
                //@ts-ignore
                user.current_location = current_location
            }
            return user
        })
    }
    //@ts-ignore
    getCurrentUser = () => {
        return this.lease.users.find((user) => user.me);
    }
    getValueFromAdditionalTerms = (alias: string): boolean =>{
      return this.lease.additional_terms.reduce((prev, term) =>{
          //@ts-ignore
          if(term.alias === alias){
            //@ts-ignore
            prev = term.value
          }
          return prev
        }, false)
    }
}
