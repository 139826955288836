import React, {Component} from "react"
import {Button} from 'antd'

import {MainLayoutContentContainer, MainLayoutFooter} from "../../components"
import * as Icons from "../../components/icons";

import {Props} from './types'

import './styles.scss'

export class InformationPage extends Component<Props, any> {

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        return (
            <>
                <MainLayoutContentContainer >
                    <div className='information-page__container'>
                        <Icons.CheckList/>
                        <p className='heading-secondary text-center'>If the following information is correct in the lease agreement, hit
                            <br/>
                            <span className='information__agree-button agree'>Agree</span>
                            to continue.
                        </p>
                        <p className='heading-secondary text-center'>If you need to edit then hit
                            <br/>
                            <span className='information__agree-button disagree'>Disagree</span>
                            to request a change.
                        </p>
                    </div>
                </MainLayoutContentContainer>
                <MainLayoutFooter>
                    <div className='information__buttons-container'>
                        <Button
                            className='fullwidth t-uppercase app-basic-button_large'
                            shape="round"
                            type="danger"
                            loading={false}
                            onClick={() => history.push(nextPath)}
                        >
                            Got It!
                        </Button>
                    </div>
                </MainLayoutFooter>
            </>
        )
    }
}
