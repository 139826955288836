import React from "react"
import {Button} from 'antd'
import {
    observer,
    inject
} from "mobx-react"

import {
    MainLayoutContentContainer,
    MainLayoutFooter
} from "../../components"
import {ComponentWithInjectedProps} from "../../services/ComponentWithInjectedProps"
import {BaseModal} from "../../components"

import {
    Props,
    InjectedProps,
    State
} from "./types"

import {removeItem} from "../../helpers/storage"

import './styles.scss'

@inject('Lease', 'StaticPage')
@observer
export class WelcomePage extends ComponentWithInjectedProps<Props, State, InjectedProps>{

    constructor(props: Props){
        super(props)
        removeItem('phone_number')
        this.state = {
            isVisibleTermsConditionModal: false
        }
    }

    componentDidMount(): void{
        const {StaticPage: {setPage}} = this.injectedProps
        setPage()
    }

    render(): React.ReactElement{
        const {history, nextPath} = this.props
        const {isVisibleTermsConditionModal} = this.state
        const {Lease: {lease: {property, agency_manager, agency, location}, getCurrentUser}, StaticPage: {page}} = this.injectedProps
        const state =  property.state;
        return (
                <>
                    <MainLayoutContentContainer>
                        <div className='welcome-page__container'>
                            <div className="content-wrap">
                                <p  className='welcome-page__greeting-text_secondary'>
                                    Hi {getCurrentUser().first_name} {getCurrentUser().last_name},<br/>
                                    your residential {state == 'VIC' ? "rental" : "tenancy"} agreement for:
                                </p>
                            </div>

                            <div className="content-wrap address">
                                <p className='heading-red__bold-primary'>
                                    {`${property.unit ? property.unit + '/' + property.address : property.address}, ${property.city}, ${property.state}, ${property.post_code}`}
                                </p>
                            </div>

                            <p className='ready'>is ready to sign</p>

                            <div className="support">
                                <p className='welcome-page__help-text'>If you need help click</p>

                                <a className="call-link" href={`tel:${agency_manager.phone_number}`}>
                                    <i className="la la-phone-alt c-pointer"/>
                                </a>

                                <p className='welcome-page__help-text'>to give me a call</p>

                                <p className='welcome-page__manager-text'>
                                    Regards, {agency_manager.first_name} {agency_manager.last_name}
                                    <br/>
                                    {location ? location.name : agency.name}
                                </p>
                            </div>


                            <p className='welcome-page__terms-text'>By clicking on “Get Started” you agree
                                <br/>
                                to our <span onClick={() => this.setState({isVisibleTermsConditionModal: true})}>Terms and conditions which can be view Here</span>
                            </p>
                        </div>
                        <BaseModal
                                onCancel={() => this.setState({isVisibleTermsConditionModal: false})}
                                closable={true}
                                visible={isVisibleTermsConditionModal}
                        >
                            <div dangerouslySetInnerHTML={{__html: page.content}}/>
                            <div style={{width: 100, margin: 'auto'}}>
                                <Button
                                        className='fullwidth t-uppercase app-basic-button_large'
                                        shape="round"
                                        type="danger"
                                        loading={false}
                                        onClick={() => this.setState({isVisibleTermsConditionModal: false})}
                                >
                                    Close
                                </Button>
                            </div>
                        </BaseModal>
                    </MainLayoutContentContainer>
                    <MainLayoutFooter>
                        <div className='welcome-page__buttons-container'>
                            <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                            >
                                Get Started
                            </Button>
                        </div>
                    </MainLayoutFooter>
                </>
        )
    }
}

