import React from "react"

export function LogoWithText() {
    return (
        <svg width="143px" height="55px" viewBox="0 0 154 55" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Onboarding-Process" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g id="20-|-onboarding" transform="translate(-75.000000, -79.000000)" fill="#F93A73">
                    <g id="logo-/-horizontal-2" transform="translate(75.000000, 79.000000)">
                        <g id="logo-/-horizontal">
                            <g id="Group-33">
                                <g id="Group-32">
                                    <path
                                        d="M63.8224113,12.3546824 C63.8224113,11.2676941 63.0926623,10.5000471 61.9850808,10.5000471 L60.2029929,10.5000471 L60.2029929,14.2093176 L61.9850808,14.2093176 C63.0926623,14.2093176 63.8224113,13.4409882 63.8224113,12.3546824 M68.1490724,12.3451294 C68.1490724,15.5501412 65.7368088,17.8851529 62.4890849,17.8851529 L60.2029929,17.8851529 L60.2029929,22.1068706 L55.9861351,22.1068706 L55.9861351,6.82352941 L62.4890849,6.82352941 C65.7368088,6.82352941 68.1490724,9.14011765 68.1490724,12.3451294"
                                        id="Fill-1"/>
                                    <path
                                        d="M77.5358264,10.2602682 L77.5358264,13.9763624 L75.959705,13.9763624 C74.5227134,13.9763624 73.921182,14.6068565 73.921182,16.1107624 L73.921182,22.1065976 L69.8618682,22.1065976 L69.8618682,10.3162212 L72.5817176,10.3162212 L73.2146213,11.6249741 C74.0459895,10.6635388 75.05809,10.2602682 76.4609812,10.2602682 L77.5358264,10.2602682 Z"
                                        id="Fill-3"/>
                                    <path
                                        d="M87.3384008,16.2122282 C87.3384008,14.6626047 86.2765138,13.5790282 84.7965556,13.5790282 C83.3172795,13.5790282 82.2553925,14.6530518 82.2553925,16.2115459 C82.2553925,17.7707224 83.3172795,18.8447459 84.7965556,18.8447459 C86.2765138,18.8447459 87.3384008,17.7707224 87.3384008,16.2122282 M78.1899406,16.2115459 C78.1899406,12.5568635 80.9548025,9.93048706 84.7965556,9.93048706 C88.6267146,9.93048706 91.4038527,12.5568635 91.4038527,16.2115459 C91.4038527,19.8539459 88.6267146,22.4932871 84.7965556,22.4932871 C80.9548025,22.4932871 78.1899406,19.8539459 78.1899406,16.2115459"
                                        id="Fill-5"/>
                                    <path
                                        d="M102.259994,16.2115459 C102.259994,14.6619224 101.142182,13.5135224 99.6226674,13.5135224 C98.1017887,13.5135224 96.984659,14.6619224 96.984659,16.2115459 C96.984659,17.7604871 98.1017887,18.9095694 99.6226674,18.9095694 C101.133316,18.9095694 102.259994,17.7604871 102.259994,16.2115459 M106.34386,16.2115459 C106.34386,19.8853341 103.768596,22.5055694 100.165546,22.5055694 C99.0995669,22.5055694 98.124295,22.2251224 97.2929268,21.7222282 L97.2929268,26.3642753 L93.2793075,26.3642753 L93.2793075,10.3160165 L96.0400774,10.3160165 L96.5481736,11.2528871 C97.5179895,10.4149576 98.7647008,9.92980471 100.165546,9.92980471 C103.768596,9.92980471 106.34386,12.53844 106.34386,16.2115459"
                                        id="Fill-7"/>
                                    <path
                                        d="M111.587004,14.9261294 L116.351514,14.9261294 C115.994824,13.7477059 115.146405,13.1124353 113.921518,13.1124353 C112.738234,13.1124353 111.915732,13.7552118 111.587004,14.9261294 Z M120.350129,16.1004588 C120.350129,16.4552824 120.318757,16.8107882 120.266242,17.1751647 L111.516075,17.1751647 C111.781376,18.5275882 112.640025,19.227 113.9222,19.227 C114.86951,19.227 115.662004,18.8114471 116.052113,18.1215882 L120.091648,18.1215882 C119.262326,20.8121059 116.878025,22.4934235 113.9222,22.4934235 C110.244129,22.4934235 107.52087,19.8042706 107.52087,16.2028118 C107.52087,12.5877059 110.222987,9.93062353 113.9222,9.93062353 C117.719623,9.93062353 120.350811,12.6416118 120.350811,16.0997765 L120.350129,16.1004588 Z"
                                        id="Fill-9"/>
                                    <path
                                        d="M129.870693,10.2602682 L129.870693,13.9763624 L128.294572,13.9763624 C126.85758,13.9763624 126.255367,14.6068565 126.255367,16.1107624 L126.255367,22.1065976 L122.196735,22.1065976 L122.196735,10.3162212 L124.915221,10.3162212 L125.549488,11.6249741 C126.380174,10.6635388 127.392275,10.2602682 128.795166,10.2602682 L129.870693,10.2602682 Z"
                                        id="Fill-11"/>
                                    <path
                                        d="M139.549347,18.5498329 L139.549347,22.1069388 L136.757205,22.1069388 C134.151251,22.1069388 132.566945,20.5129624 132.566945,17.8927271 L132.566945,13.4444682 L130.410435,13.4444682 L130.410435,12.4946329 L135.690544,6.86931529 L136.523958,6.86931529 L136.523958,10.3165624 L139.483874,10.3165624 L139.483874,13.4444682 L136.626259,13.4444682 L136.626259,17.1960447 C136.626259,18.0401153 137.135719,18.5498329 137.992322,18.5498329 L139.549347,18.5498329 Z"
                                        id="Fill-13"/>
                                    <g id="Group-17" transform="translate(139.811715, 9.716706)">
                                        <path
                                            d="M13.9538912,0.599583529 L9.01478661,11.8652306 C7.42638912,15.4741953 5.96620921,16.7774894 2.92308787,16.7774894 L1.53315481,16.7774894 L1.53315481,13.2879365 L2.64960251,13.2879365 C4.20253556,13.2879365 4.67107531,12.8833012 5.22077406,11.4162424 L0.35123431,0.599583529 L4.76178243,0.599583529 L7.2620251,7.09490118 L9.59312971,0.599583529 L13.9538912,0.599583529 Z"
                                            id="Fill-15"/>
                                    </g>
                                    <path
                                        d="M55.2426778,43.3736965 L59.5843431,43.3736965 C59.5843431,44.4661435 60.4266234,44.9970141 61.3862092,44.9970141 C62.267364,44.9970141 63.1089623,44.5275553 63.1089623,43.6930376 C63.1089623,42.7343318 61.9202218,42.4559318 60.4654979,42.1038376 C58.2585188,41.5545435 55.4397782,40.8776494 55.4397782,37.3983318 C55.4397782,34.3884729 57.6494854,32.6641671 61.2518536,32.6641671 C64.9142385,32.6641671 67.0141423,34.5454141 67.0141423,37.6548965 L62.7938745,37.6548965 C62.7938745,36.6880024 62.0736736,36.23492 61.2007029,36.23492 C60.4511757,36.23492 59.7316569,36.55972 59.7316569,37.2734612 C59.7316569,38.1414141 60.882887,38.4286847 62.3212427,38.7896494 C64.5698243,39.3703318 67.5092803,40.1216024 67.5092803,43.61252 C67.5092803,46.9499082 64.9456109,48.6824024 61.3978033,48.6824024 C57.6590335,48.6824024 55.2426778,46.7056259 55.2426778,43.3736965"
                                        id="Fill-18"/>
                                    <g id="Group-22" transform="translate(68.882845, 35.646118)">
                                        <path
                                            d="M9.61072552,6.76737176 C9.61072552,5.21843059 8.49291381,4.06934824 6.97339916,4.06934824 C5.4525205,4.06934824 4.33539079,5.21843059 4.33539079,6.76737176 C4.33539079,8.31699529 5.4525205,9.46539529 6.97339916,9.46539529 C8.4840477,9.46539529 9.61072552,8.31699529 9.61072552,6.76737176 M13.6945916,6.76805412 C13.6945916,10.44116 11.119328,13.0620776 7.51627782,13.0620776 C6.45029874,13.0620776 5.47502678,12.7809482 4.64365858,12.2787365 L4.64365858,16.9207835 L0.630039331,16.9207835 L0.630039331,0.872524706 L3.39080921,0.872524706 L3.89890544,1.80871294 C4.86872134,0.970783529 6.11543264,0.486312941 7.51627782,0.486312941 C11.119328,0.486312941 13.6945916,3.09426588 13.6945916,6.76805412"
                                            id="Fill-20"/>
                                    </g>
                                    <path
                                        d="M93.1234686,42.4108282 C93.1234686,40.8803106 92.0056569,39.7305459 90.4861423,39.7305459 C88.9563975,39.7305459 87.8481339,40.8803106 87.8481339,42.4108282 C87.8481339,43.9502165 88.9652636,45.0992988 90.4861423,45.0992988 C92.0056569,45.0992988 93.1234686,43.9502165 93.1234686,42.4108282 Z M96.984318,36.5064282 L96.984318,48.2968047 L93.8470795,48.2968047 L93.4992552,47.4622871 C92.5342134,48.2449459 91.3120544,48.6952988 89.9432636,48.6952988 C86.3395314,48.6952988 83.7635858,46.0648282 83.7635858,42.4012753 C83.7635858,38.7500047 86.3395314,36.1509224 89.9432636,36.1509224 C91.3304686,36.1509224 92.5703598,36.6149224 93.5435858,37.4180518 L93.9589289,36.5064282 L96.984318,36.5064282 Z"
                                        id="Fill-23"/>
                                    <path
                                        d="M98.8659109,42.3975224 C98.8659109,38.7489812 101.580986,36.1505812 105.386593,36.1505812 C108.697744,36.1505812 111.181618,38.2392635 111.625605,41.37604 L107.671321,41.37604 C107.329635,40.4139224 106.43143,39.7895694 105.372953,39.7895694 C103.946191,39.7895694 102.931363,40.8745106 102.931363,42.4104871 C102.931363,43.9471459 103.959831,45.0559694 105.372953,45.0559694 C106.462802,45.0559694 107.345321,44.3886282 107.680187,43.33644 L111.66789,43.33644 C111.210262,46.5373576 108.690923,48.6949576 105.372953,48.6949576 C101.593944,48.6949576 98.8659109,46.0460635 98.8659109,42.3975224"
                                        id="Fill-25"/>
                                    <path
                                        d="M116.803277,41.1287553 L121.566423,41.1287553 C121.211097,39.9503318 120.361996,39.3143788 119.137109,39.3143788 C117.953825,39.3143788 117.131323,39.9571553 116.803277,41.1287553 M125.481833,43.3777906 L116.731666,43.3777906 C116.996967,44.7295318 117.855615,45.4296259 119.137109,45.4296259 C120.085101,45.4296259 120.878277,45.0133906 121.267703,44.3242141 L125.307239,44.3242141 C124.477235,47.0147318 122.094297,48.6960494 119.137109,48.6960494 C115.460402,48.6960494 112.736461,46.0062141 112.736461,42.4047553 C112.736461,38.7903318 115.438578,36.1325671 119.137109,36.1325671 C122.935896,36.1325671 125.56572,38.8435553 125.56572,42.3024024 C125.56572,42.6579082 125.53503,43.0134141 125.481833,43.3777906"
                                        id="Fill-27"/>
                                    <g id="Group-31" transform="translate(0.000000, 0.163765)">
                                        <path
                                            d="M14.9462763,39.5285522 C14.9511808,39.5285522 15.017958,54.3666087 15.017958,54.3666087 L0.000272803347,54.4241424 L0.000272803347,26.5992611 L10.512577,26.5992611 C10.512577,29.257845 12.5702179,30.5536696 14.9157173,30.5536696 C17.0691853,30.5536696 19.127958,29.409388 19.127958,27.3761542 C19.127958,25.0417146 16.2222099,24.3648474 12.6660449,23.5048501 C7.27030227,22.1691655 0.379808332,20.5206179 0.379808332,12.0484971 C0.379808332,5.06510813 5.28811572,0.931705936 13.3643752,0.555292581 L15.475966,0.547395797 L22.8859634,0.518440924 C34.3637063,0.474068521 42.9213648,8.59985891 42.9666374,19.8839867 C43.0096464,31.1696187 34.5157469,39.4266461 23.0376267,39.4710185 L15.9290696,39.4980932 L14.9462763,39.5285522 Z"
                                            id="Fill-29"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}
