import React from "react"

import {Props} from "./types"
import {ADDITIONAL_TERM_ALIAS} from "../../../../../types/AdditionalTerms"

export function QLDGeneralAgreementStepContent(props: Props): React.ReactElement {
  const {Lease: {getValueFromAdditionalTerms}} = props
  return (
    <>
      <ol style={{counterReset: 'item 0'}} className="nested-list heading-secondary">
        <h3 className='heading blue text-left w-100'>
          Division 1 Preliminary
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Interpretation
          </h4>
          In this agreement –
          <ol>
            <li>
              a reference to the premises includes a reference to any inclusions for the premises stated in
              this agreement for item 5.2; and
            </li>
            <li>
              a reference to a numbered section is a reference to the section in the Act with that number; and
            </li>
            <li>
              a reference to a numbered item is a reference to the item with that number in part 1; and
            </li>
            <li>
              a reference to a numbered clause is a reference to the clause of this agreement with that number.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Terms of a general tenancy agreement
          </h4>
          <ol>
            <li>
              This part states, under the Residential Tenancies and Rooming Accommodation Act 2008 (the Act), section
              55, the standard terms of a general tenancy agreement.
            </li>
            <li>
              The Act also imposes duties on, and gives entitlements to, the lessor and tenant that are taken to
              be included as terms of this agreement.
            </li>
            <li>
              The lessor and tenant may agree on other terms of this agreement (special terms).
            </li>
            <li>
              A duty or entitlement under the Act overrides a standard term or special term if the term is
              inconsistent with the duty or entitlement.
            </li>
            <li>
              A standard term overrides a special term if they are inconsistent.<br/>
              <i>Note</i> – Some breaches of this agreement may also be an offence under the Act, for example, if –
              <ol>
                <li>
                  the lessor or the lessor’s agent enters the premises in contravention of the rules of entry
                  under sections 192 to 199; or
                </li>
                <li>
                  the tenant does not sign and return the condition report to the lessor or the lessor’s agent
                  under section 65
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            More than 1 lessor or tenant
          </h4>
          <ol>
            <li>
              This clause applies if more than 1 person is named in this agreement for item 1 or 2.
            </li>
            <li>
              Each lessor named in this agreement for item 1 must perform all of the lessor’s obligations under
              this agreement.
            </li>
            <li>
              Each tenant named in this agreement for item 2 –<br/>
              <ol>
                <li>
                  holds their interest in the tenancy as a tenant in common unless a special term states the
                  tenants are joint tenants; and
                </li>
                <li>
                  must perform all the tenant’s obligations under this agreement.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Division 2 Period of tenancy
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Start of tenancy
          </h4>
          <ol>
            <li>
              The tenancy starts on the day stated in this agreement for item 6.2.
            </li>
            <li>
              However, if no day is stated or if the stated day is before the signing of this agreement, the
              tenancy starts when the tenant is or was given a right to occupy the premises.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Entry condition report – s 65
          </h4>
          <ol>
            <li>
              The lessor must prepare, in the approved form, sign and give the tenant 1 copy of a condition
              report for the premises.
            </li>
            <li>
              The copy must be given to the tenant on or before the day the tenant occupies the premises
              under this agreement.
            </li>
            <li>
              The tenant must mark the copy of the report to show any parts the tenant disagrees with, and
              sign and return the copy to the lessor not later than 7 days after the later of the following days –
              <ol>
                <li>
                  the day the tenant is entitled to occupy the premises;
                </li>
                <li>
                  the day the tenant is given the copy of the condition report.
                </li>
              </ol>
              <i>Note</i> – A well completed condition report can be very important to help the parties if there is
              a dispute about the condition of the premises when the tenancy started. For more information
              about condition reports, see the information statement.
            </li>
            <li>
              After the copy of the condition report is returned to the lessor by the tenant, the lessor
              must copy the condition report and return it to the tenant within 14 days.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Continuation of fixed term agreement – s 70
          </h4>
          <ol>
            <li>
              this agreement is a fixed term agreement; and
            </li>
            <li>
              none of the following notices are given, or agreements or applications made before the day the term
              ends (the end day end day) –
              <ol>
                <li>
                  a notice to leave;
                </li>
                <li>
                  a notice of intention to leave;
                </li>
                <li>
                  an abandonment termination notice;
                </li>
                <li>
                  a notice, agreement or application relating to the death of a sole tenant under section 277(7);
                </li>
                <li>
                  a written agreement between the lessor and tenant to end the agreement.
                </li>
              </ol>
            </li>
            <li>
              This agreement, other than a term about this agreement’s term, continues to apply after the end
              day on the basis that the tenant is holding over under a periodic agreement. <br/>
              <i>Note</i> – For more information about the notices, see the information statement.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Costs apply to early ending of fixed term agreement
          </h4>
          <ol>
            <li>
              This clause applies if –
              <ol>
                <li>
                  this agreement is a fixed term agreement; and
                </li>
                <li>
                  the tenant terminates it before the term ends in a way not permitted under the Act.
                </li>
              </ol>
            </li>
            <li>
              The tenant must pay the reasonable costs incurred by the lessor in reletting the premises.<br/>
              <i>Note</i> – For when the tenant may terminate early under the Act, see clause 36 and the
              information statement. Under section 362, the lessor has a general duty to mitigate
              (avoid or reduce) the costs.
            </li>
            <li>
            This clause does not apply if, after experiencing domestic violence, the tenant ends this agreement or the tenant's interest in this agreement under chapter 5, part 1, division 3, subdivision 2A of the Act
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Division 3 Rent
        </h3>
        <li>
          <h4 className='text-left w-100'>
            When, how and where rent must be paid – ss 83 and 85– ss 83 and 8
          </h4>
          <ol>
            <li>
              The tenant must pay the rent stated in this agreement for item 7.
            </li>
            <li>
              The rent must be paid at the times stated in this agreement for item 8.
            </li>
            <li>
              The rent must be paid –
              <ol>
                <li>
                  in the way stated in this agreement for item 9; or
                </li>
                <li>
                  in the way agreed after the signing of this agreement by –
                  <ol>
                    <li>
                      the lessor or tenant giving the other party a notice proposing the way; and
                    </li>
                    <li>
                      the other party agreeing to the proposal in writing; or
                    </li>
                  </ol>
                </li>
                <li>
                  if there is no way stated in this agreement for item 9 or no way agreed after the signing of
                  this agreement – in an approved way under section 83(4).<br/>
                  <i>Note</i> – If the way rent is to be paid is another way agreed on by the lessor and tenant
                  under section 83(4)(g), the lessor or the lessor’s agent must comply with the obligations
                  under section84(2).
                </li>
              </ol>
            </li>
            <li>
              The rent must be paid at the place stated in this agreement for item 10.
            </li>
            <li>
              However, if, after the signing of this agreement, the lessor gives a notice to the tenant stating
              a different place for payment and the place is reasonable, the rent must be paid at the place while
              the notice is in force.
            </li>
            <li>
              If no place is stated in this agreement for item 10 and there is no notice stating a place, the rent
              must be paid at an appropriate place.<br/>
              <i>Examples of an appropriate place</i> –
              <ol>
                <li>
                  the lessor’s address for service
                </li>
                <li>
                  the lessor’s agent’s office
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Rent in advance – s 87
          </h4>
          The lessor may require the tenant to pay rent in advance only if the payment is not more than –
          <ol>
            <li>
              for a periodic agreement – 2 weeks rent; or
            </li>
            <li>
              for a fixed term agreement – 1 month rent.
            </li>
          </ol>
          <i>Note</i> – Under section 87(2), the lessor or the lessor’s agent must not require a payment of rent
          under this agreement in a period for which rent has already been paid
        </li>
        <li>
          <h4 className='text-left w-100'>
            Rent increases – ss 91 and 93
          </h4>
          <ol>
            <li>
              If the lessor proposes to increase the rent, the lessor must give notice of the proposal to the tenant
            </li>
            <li>
              The notice must state the amount of the increased rent and the day from when it is payable
            </li>
            <li>
              The day stated must not be earlier than the later of the following –
              <ol>
                <li>
                  2 months after the notice is given;
                </li>
                <li>
                  6 months after the day the existing rent became payable by the tenant.
                </li>
              </ol>
            </li>
            <li>
              Subject to an order of a tribunal, the increased rent is payable from the day stated in the
              notice, and this agreement is taken to be amended accordingly.
            </li>
            <li>
              However, if this agreement is a fixed term agreement, the rent may be increased before the
              term ends only if a special term –
              <ol>
                <li>
                  provides for a rent increase; and
                </li>
                <li>
                  states the amount of the increase or how the amount of the increase is to be worked out.
                </li>
              </ol>
            </li>
            <li>
              A rent increase is payable by the tenant only if the rent is increased under this clause.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Application to tribunal about excessive increase – s 92
          </h4>
          <ol>
            <li>
              If a notice of proposed rent increase is given and the tenant considers the increase is excessive,
              the tenant may apply to a tribunal for an order setting aside or reducing the increase.
            </li>
            <li>
              However, the application must be made –
              <ol>
                <li>
                  within 30 days after the notice is received; and
                </li>
                <li>
                  for a fixed term agreement – before the term ends.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Rent decreases – s 94
          </h4>
          Under section 94, the rent may decrease in certain situations.<br/>
          <i>Note</i> – For details of the situations, see the information statement.
        </li>
        <h3 className='heading blue text-left w-100'>
          Division 4 Rental bond
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Rental bond required – ss 111 and 116
          </h4>
          <ol>
            <li>
              If a rental bond is stated in this agreement for item 11, the tenant must pay to the lessor or the
              lessor’s
              agent the rental bond amount –
              <ol>
                <li>
                  if a special term requires the bond to be paid at a stated time – at the stated time; or
                </li>
                <li>
                  if a special term requires the bond to be paid by instalments – by instalments; or
                </li>
                <li>
                  otherwise – when the tenant signs this agreement.
                </li>
              </ol>
              <i>Note</i> – There is a maximum bond that may be required. See section 146 and the information statement.
            </li>
            <li>
              The lessor or the lessor’s agent must, within 10 days of receiving the bond or a part of the
              bond, pay it to the authority and give the authority a notice, in the approved form, about the bond.
            </li>
            <li>
              The bond is intended to be available to financially protect the lessor if the tenant breaches
              this agreement.<br/>
              <i>Example</i> – The lessor may claim against the bond if the tenant does not leave the premises in
              the required condition at the end of the tenancy.<br/>
              <i>Note</i> – For how to apply to the authority or a tribunal for the bond at the end of the tenancy,
              see the information statement and sections 125 to 141. Delay in applying may mean that payment
              is made on another application for payment
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Increase in bond – s 154
          </h4>
          <ol>
            <li>
              The tenant must increase the rental bond if –
              <ol>
                <li>
                  the rent increases and the lessor gives notice to the tenant to increase the bond; and
                </li>
                <li>
                  the notice is given at least 11 months after –
                  <ol>
                    <li>
                      this agreement started; or
                    </li>
                    <li>
                      if the bond has been increased previously by a notice given under this clause –
                      the day stated in the notice, or the last notice, for making the increase.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              The notice must state the increased amount and the day by which the increase must be made.
            </li>
            <li>
              For subclause (2), the day must be at least 1 month after the tenant is given the notice.
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Division 5 Outgoings
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Outgoings – s 163
          </h4>
          <ol>
            <li>
              The lessor must pay all charges, levies, premiums, rates or taxes for the premises, other than a
              service charge.<br/>
              <i>Examples</i> – body corporate levies, council general rates, sewerage charges, environment levies, land
              tax
            </li>
            <li>
              This clause does not apply if—
              <ol>
                <li>
                  the lessor is the State; and
                </li>
                <li>
                  rent is not payable under the agreement; and
                </li>
                <li>
                  the tenant is an entity receiving financial or other assistance from the State to supply
                  rented accommodation to persons
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            General service charges – ss 164 and 165
          </h4>
          The tenant must pay a service charge, other than a water service charge, for a service supplied to the
          premises during the tenancy if –
          <ol>
            <li>
              the tenant enjoys or shares the benefit of the service; and
            </li>
            <li>
              the service is stated in this agreement for item 12.1; and
            </li>
            <li>
              either –
              <ol>
                <li>
                  the premises are individually metered for the service; or
                </li>
                <li>
                  this agreement states for item 13 how the tenant’s apportionment of the cost of the service
                  is to be worked out; and
                </li>
              </ol>
            </li>
            <li>
              this agreement states for item 14 how the tenant must pay for the service.
            </li>
          </ol>
          <i>Note</i> – Section 165(3) limits the amount the tenant must pay.
        </li>
        <li>
          <h4 className='text-left w-100'>
            Water service charges – ss 164 and 166
          </h4>
          <ol>
            <li>
              The tenant must pay an amount for the water consumption charges for the premises if –
              <ol>
                <li>
                  the tenant is enjoying or sharing the benefit of a water service to the premises; and
                </li>
                <li>
                  the premises are individually metered for the supply of water or water is supplied to
                  the premises by delivery by means of a vehicle; and
                </li>
                <li>
                  this agreement states for item 12.2 that the tenant must pay for water supplied to the premises.
                </li>
              </ol>
              <i>Note</i> – A water consumption charge does not include the amount of a water service charge that
              is a fixed charge for the water service.
            </li>
            <li>
              However, the tenant does not have to pay an amount –
              <ol>
                <li>
                  that is more than the amount of the water consumption charges payable to the relevant
                  water supplier; or
                </li>
                <li>
                  that is a fixed charge for the water service to the premises.
                </li>
              </ol>
            </li>
            <li>
              Also, the tenant does not have to pay an amount for a reasonable quantity of water supplied to the
              premises for a period if, during the period, the premises are not water efficient for section 166.<br/>
              <i>Note</i> – For details about water efficiency, see the information statement.
            </li>
            <li>
              In deciding what is a reasonable quantity of water for subclause (3), regard must be had to the
              matters mentioned in section 169(4)(a) to (e)
            </li>
            <li>
              The tenant must pay the amount of the charge to the lessor within 1 month of the lessor giving the
              tenant copies of relevant documents about the incurring of the amount.
            </li>
            <li>
              In this clause – water consumption charge, for premises, means the variable part of a water
              service charge assessed on the volume of water supplied to the premises.<br/>
              <i>Note</i> – If there is a dispute about how much water (or any other service charge) the tenant should
              pay, the lessor or the tenant may attempt to resolve the dispute by conciliation. See the
              information statement for details.
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Division 6 Rights and obligations concerning the premises during tenancy<br/>
          Subdivision 1 Occupation and use of premises
        </h3>
        <li>
          <h4 className='text-left w-100'>
            No legal impediments to occupation – s 181
          </h4>
          The lessor must ensure there is no legal impediment to occupation of the premises by the tenant as a
          residence for the term of the tenancy if, when entering into this agreement, the lessor knew about the
          impediment or ought reasonably to have known about it.<br/>
          Examples of possible legal impediments –
          <ul>
            <li>
              if there is a mortgage over the premises, the lessor might need to obtain approval from
              the mortgagee before the tenancy can start
            </li>
            <li>
              a certificate might be required under the Building Act 1975 before the premises can lawfully
              be occupied
            </li>
            <li>
              the zoning of the land might prevent use of a building on the land as a residence
            </li>
          </ul>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Vacant possession and quiet enjoyment – ss 182 and 183
          </h4>
          <ol>
            <li>
              The lessor must ensure the tenant has vacant possession of the premises (other than a part of
              the premises that the tenant does not have a right to occupy exclusively) on the day the tenant is
              entitled to occupy the premises under this agreement.<br/>
              <i>Editor’s note</i> – Parts of the premises where the tenant does not have a right to occupy
              exclusively may be identified in a special term.
            </li>
            <li>
              The lessor must take reasonable steps to ensure the tenant has quiet enjoyment of the premises.
            </li>
            <li>
              The lessor or the lessor’s agent must not interfere with the reasonable peace, comfort or privacy
              of the tenant in using the premises.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Lessor’s right to enter the premises – ss 192–199
          </h4>
          The lessor or the lessor’s agent may enter the premises during the tenancy only if the obligations under
          sections 192 to 199 have been complied with.<br/>
          <i>Note</i> – See the information statement for details.
        </li>
        <li>
          <h4 className='text-left w-100'>
            Tenant’s use of premises – ss 10 and 184
          </h4>
          <ol>
            <li>
              The tenant may use the premises only as a place of residence or mainly as a place of residence or for
              another use allowed under a special term.
            </li>
            <li>
              The tenant must not –
              <ol>
                <li>
                  use the premises for an illegal purpose; or
                </li>
                <li>
                  cause a nuisance by the use of the premises; or<br/>
                  <i>Examples of things that may constitute a nuisance –</i>
                  <ul>
                    <li>
                      using paints or chemicals on the premises that go onto or cause odours on adjoining land
                    </li>
                    <li>
                      causing loud noises
                    </li>
                    <li>
                      allowing large amounts of water to escape onto adjoining land
                    </li>
                  </ul>
                </li>
                <li>
                  interfere with the reasonable peace, comfort or privacy of a neighbour of the tenant; or
                </li>
                <li>
                  allow another person on the premises to interfere with the reasonable peace, comfort or
                  privacy of a neighbour of the tenant.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Units and townhouses – s 69
          </h4>
          <ol>
            <li>
              The lessor must give the tenant a copy of any body corporate by-laws under the <i>Body Corporate
              and Community Management Act 1997 or Building Units and Group Titles</i> Act 1980 applicable to –
              <ol>
                <li>
                  the occupation of the premises; or
                </li>
                <li>
                  any common area available for use by the tenant with the premises.
                </li>
              </ol>
            </li>
            <li>
              The tenant must comply with the by-laws.

            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Number of occupants allowed
          </h4>
          No more than the number of persons stated in this agreement for item 15 may reside at the premises.
        </li>
        <h3 className='heading blue text-left w-100'>
          Subdivision 2 Standard of premises
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Lessor’s obligations – s 185
          </h4>
          <ol>
            <li>
              At the start of the tenancy, the lessor must ensure –
              <ol>
                <li>
                  the premises are clean; and
                </li>
                <li>
                  the premises are fit for the tenant to live in; and
                </li>
                <li>
                  the premises are in good repair; and
                </li>
                <li>
                  the lessor is not in breach of a law dealing with issues about the health or safety
                  of persons using or entering the premises.
                </li>
              </ol>
            </li>
            <li>
              While the tenancy continues, the lessor must –
              <ol>
                <li>
                  maintain the premises in a way that the premises remain fit for the tenant to live in; and
                </li>
                <li>
                  maintain the premises in good repair; and
                </li>
                <li>
                  ensure the lessor is not in breach of a law dealing with issues about the health or safety
                  of persons using or entering the premises; and
                </li>
                <li>
                  keep any common area included in the premises clean.
                </li>
              </ol>
              <i>Note</i> – For details about the maintenance, see the information statement.
            </li>
            <li>
              However, the lessor is not required to comply with subclause (1)(c) or (2)(a) for any non-standard
              items and the lessor is not responsible for their maintenance if –
              <ol>
                <li>
                  the lessor is the State; and
                </li>
                <li>
                  the non-standard items are stated in this agreement and this agreement states the
                  lessor is not responsible for their maintenance; and
                </li>
                <li>
                  the non-standard items are not necessary and reasonable to make the premises a fit place
                  in which to live; and
                </li>
                <li>
                  the non-standard items are not a risk to health or safety; and
                </li>
                <li>
                  for fixtures – the fixtures were not attached to the premises by the lessor.
                </li>
              </ol>
            </li>
            <li>
              In this clause – <b>non-standard</b> items means the fixtures attached to the premises and inclusions
              supplied with the premises stated in this agreement for item 5.2. <b>premises</b> include any common area
              available for use by the tenant with the premises.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Tenant’s obligations – s 188(2), (3) and (5)
          </h4>
          <ol>
            <li>
              The tenant must keep the premises clean, having regard to their condition at the start of the tenancy.
            </li>
            <li>
              The tenant must not maliciously damage, or allow someone else to maliciously damage, the premises.
            </li>
            <li>
            The tenant’s obligations under this clause do not apply to the extent the obligations would have the effect of requiring the tenant to repair, or compensate the lessor for, damage to the premises or inclusions caused by an act of domestic violence experienced by the tenant
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Subdivision 3 The dwelling
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Fixtures or structural changes – ss 207–209
          </h4>
          <ol>
            <li>
              The tenant may attach a fixture, or make a structural change, to the premises only if the
              lessor agrees to the fixture’s attachment or the structural change. <br/>
              <i>Note</i> – Fixtures are generally items permanently attached to land or to a building that
              are intended to become part of the land or building. An attachment may include, for example,
              something glued, nailed or screwed to a wall.
            </li>
            <li>
              The lessor’s agreement must be written, describe the nature of the fixture or change and
              include any terms of the agreement. <br/>
              <i>Examples of terms –</i>
              <ul>
                <li>
                  that the tenant may remove the fixture
                </li>
                <li>
                  that the tenant must repair damage caused when removing the fixture
                </li>
                <li>
                  that the lessor must pay for the fixture if the tenant can not remove it
                </li>
              </ul>
            </li>
            <li>
              If the lessor does agree, the tenant must comply with the terms of the lessor’s agreement
            </li>
            <li>
              The lessor must not act unreasonably in failing to agree.
            </li>
            <li>
              If the tenant attaches a fixture, or makes a structural change, to the premises without the
              lessor’s agreement, the lessor may –
              <ol>
                <li>
                  take action for a breach of a term of this agreement; or
                </li>
                <li>
                  waive the breach (that is, not take action for the breach) and treat the fixture or
                  change as an improvement to the premises for the lessor’s benefit (that is, treat it as
                  belonging to the lessor, without having to pay the tenant for it).
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Supply of locks and keys – s 210
          </h4>
          <ol>
            <li>
              The lessor must supply and maintain all locks necessary to ensure the premises are reasonably secure
            </li>
            <li>
              The lessor must give the tenant, or if there is more than 1 tenant, 1 of the tenants, a key for
              each lock that –
              <ol>
                <li>
                  secures an entry to the premises; or
                </li>
                <li>
                  secures a road or other place normally used to gain access to, or leave, the area or
                  building in which the premises are situated; or
                </li>
                <li>
                  is part of the premises.
                </li>
              </ol>
            </li>
            <li>
              If there is more than 1 tenant, the lessor must give the other tenants a key for the locks mentioned
              in subclause (2)(a) and (b).
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Changing locks – ss 211 and 212
          </h4>
          <ol>
            <li>
              The lessor or the tenant may change locks if –
              <ol>
                <li>
                  both agree to the change; or
                </li>
                <li>
                  there is a tribunal order permitting the change; or
                </li>
                <li>
                  there is a reasonable excuse for making the change.<br/>
                  <i>Example of a reasonable excuse – an emergency requiring the lock to be changed quickly</i>
                </li>
              </ol>
            </li>
            <li>
              The lessor or tenant must not act unreasonably in failing to agree to the change of a lock.
            </li>
            <li>
              If a lock is changed, the party changing it must give the other party a key for the changed lock
              unless –
              <ol>
                <li>
                  a tribunal orders that a key not be given; or
                </li>
                <li>
                  the other party agrees to not being given a key
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Subdivision 4 Damage and repairs
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Meaning of emergency and routine repairs – ss 214 and 215
          </h4>
          <ol>
            <li>
              Emergency repairs are works needed to repair any of the following –
              <ol>
                <li>a burst water service or serious water service leak;</li>
                <li>a blocked or broken lavatory system;</li>
                <li>a serious roof leak;</li>
                <li>a gas leak;</li>
                <li>a dangerous electrical fault;</li>
                <li>flooding or serious flood damage;</li>
                <li>serious storm, fire or impact damage;</li>
                <li>a failure or breakdown of the gas, electricity or water supply to the premises;</li>
                <li>a failure or breakdown of an essential service or appliance on the premises for hot
                  water, cooking or heating;
                </li>
                <li>a fault or damage that makes the premises unsafe or insecure;</li>
                <li>a fault or damage likely to injure a person, damage property or unduly inconvenience
                  a resident of the premises;
                </li>
                <li>a serious fault in a staircase, lift or other common area of the premises that unduly
                  inconveniences a resident in gaining access to, or using, the premises.
                </li>
              </ol>
            </li>
            <li>
            Also, emergency repairs are works needed for the premises or inclusions to comply with the prescribed minimum housing standards.
            </li>
            <li>
              Routine repairs are repairs other than emergency repairs.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Nominated repairer for emergency repairs – s 216
          </h4>
          <ol>
            <li>
                The lessor’s nominated repairer for emergency repairs of a particular type must be stated either –
              <ol>
                <li>
                  in this agreement for item 18; or
                </li>
                <li>
                  in a written notice given by the lessor to the tenant.
                </li>
              </ol>
            </li>
            <li>
              Item 18 or the written notice must state –
              <ol>
                <li>
                the name and telephone number of the nominated repairer; and
                </li>
                <li>
                whether or not the nominated repairer is the tenant’s first point of contact for notifying of the need for emergency repairs.
                </li>
              </ol>
            </li>
            <li>
              The lessor must give written notice to the tenant of any change of the lessor’s nominated repairer or the telephone number of the nominated repairer.
            </li>
            <li>
              This clause does not apply if –
              <ol>
                <li>
                the lessor has given the tenant a telephone number of the lessor; and
                </li>
                <li>
                under this agreement the lessor is to arrange for emergency repairs to be made to the premises or inclusions.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Notice of damage – s 217
          </h4>
          <ol>
            <li>
              If the tenant knows the premises have been damaged, the tenant must give notice as soon
              as practicable of the damage
            </li>
            <li>
              If the premises need routine repairs, the notice must be given to the lessor.
            </li>
            <li>
              If the premises need emergency repairs, the notice must be given to –
              <ol>
                <li>
                  the nominated repairer for the repairs; or
                </li>
                <li>
                  if there is no nominated repairer for the repairs or the repairer can not be contacted
                  – the lessor.
                </li>
              </ol>
            </li>
            <li>
            This clause does not apply to the tenant for damage caused by an act of domestic violence experienced by the tenant.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Emergency repairs arranged by tenant – ss 218 and 219
          </h4>
          <ol>
            <li>
              The tenant may arrange for a suitably qualified person to make emergency repairs or apply to
              the tribunal under section 221 for orders about the repairs if –
              <ol>
                <li>
                  the tenant has been unable to notify the lessor or nominated repairer of the need for
                  emergency repairs of the premises; or
                </li>
                <li>
                  the repairs are not made within a reasonable time after notice is given.
                </li>
              </ol>
            </li>
            <li>
              The maximum amount that may be incurred for emergency repairs arranged to be made by the
              tenant is an amount equal to the amount payable under this agreement for 2 weeks rent.<br/>
              <i>Note</i> – For how the tenant may require reimbursement for the repairs, see sections 219(2) and (3)
              and 220 and the information statement.
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Subdivision 5 Pets
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Keeping pets and other animals at premises – ss 183B and 184G
          </h4>
          <ol>
            <li>
              The tenant may keep a pet or other animal at the premises only with the approval of the lessor.
            </li>
            <li>
              However, the tenant may keep a working dog at the premises without the lessor’s approval.
            </li>
            <li>
              The tenant has the approval of the lessor to keep a pet at the premises if keeping the pet at the premises is consistent with item 17. Notes –
              <ol>
                <li>
                  If item 17 states 2 cats, the tenant is approved by the lessor to keep up to 2 cats at the premises.
                </li>
                <li>
                  For additional approvals to keep a pet or other animal at the premises see clause 35.
                </li>
              </ol>
            </li>
            <li>
              An authorisation to keep the pet or working dog at the premises continues for the life of the pet or working dog and is not affected by any of the following matters –
              <ol>
                <li>
                  the ending of this agreement, if the tenant continues occupying the premises under a new agreement;
                </li>
                <li>
                  a change in the lessor or lessor’s agent;
                </li>
                <li>
                  for a working dog – the retirement of the dog from the service the dog provided as a working dog.
                </li>
              </ol>
            </li>
            <li>
              An authorisation to keep a pet, working dog or other animal at the premises may be restricted by a body corporate by-law or other law about keeping animals at the premises. Examples –
              <ol>
                <li>
                  The premises may be subject to a local law that limits the number or types of animals that may be kept at the premises.
                </li>
                <li>
                  The premises may be subject to a body corporate by-law that requires the tenant to obtain approval from the body corporate before keeping a pet at the premises.
                </li>
                
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Tenant responsible for pets and other animals – s 184C
          </h4>
          <ol>
            <li>
              The tenant is responsible for all nuisance caused by a pet or other animal kept at the premises, including, for example, noise caused by the pet or other animal.
            </li>
            <li>
              The tenant is responsible for repairing any damage to the premises or inclusions caused by the pet or other animal.
            </li>
            <li>
              Damage to the premises or inclusions caused by the pet or other animal is not fair wear and tear.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Request for approval to keep pet – ss 184D and 184E
          </h4>
          <ol>
            <li>
              The tenant may, using the approved form, request the lessor’s approval to keep a stated pet at the premises.
            </li>
            <li>
              The lessor must respond to the tenant’s request within 14 days after receiving the request.
            </li>
            <li>
              The lessor’s response to the request must be in writing and state –
              <ol>
                <li>
                  whether the lessor approves or refuses the tenant’s request; and
                </li>
                <li>
                  if the lessor approves the tenant’s request subject to conditions – the conditions of the approval; and Note – See clause 36 for limitations on conditions of approval to keep a pet at the premises.
                </li>
                <li>
                  if the lessor refuses the tenant’s request –
                  <ol>
                    <li>
                      the grounds for the refusal; and
                    </li>
                    <li>
                      the reasons the lessor believes the grounds for the refusal apply to the request.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              The lessor may refuse the request for approval to keep a pet at the premises only on 1 or more of the following grounds –
              <ol>
                <li>
                  keeping the pet would exceed a reasonable number of animals being kept at the premises;
                </li>
                <li>
                  the premises are unsuitable for keeping the pet because of a lack of appropriate fencing, open space or another thing necessary to humanely accommodate the pet;
                </li>
                <li>
                  keeping the pet is likely to cause damage to the premises or inclusions that could not practicably be repaired for a cost that is less than the amount of the rental bond for the premises;
                </li>
                <li>
                  keeping the pet would pose an unacceptable risk to the health and safety of a person, including, for example, because the pet is venomous;
                </li>
                <li>
                  keeping the pet would contravene a law;
                </li>
                <li>
                  keeping the pet would contravene a body corporate by-law applying to the premises;
                </li>
                <li>
                  if the lessor proposed reasonable conditions for approval and the conditions comply with clause 36 – the tenant has not agreed to the conditions;
                </li>
                <li>
                  the animal stated in the request is not a pet as defined in section 184A;
                </li>
                <li>
                  another ground prescribed by a regulation under section 184E(1)(j).
                </li>
              </ol>
            </li>
            <li>
             The lessor is taken to approve the keeping of the pet at the premises if –
             <ol>
               <li>
                the lessor does not comply with subclause (2); or
               </li>
               <li>
                the lessor’s response does not comply with subclause (3).
               </li>
             </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Conditions for approval to keep pet at premises – s 184F
          </h4>
          <ol>
            <li>
              The lessor’s approval to keep a pet at the premises may be subject to conditions if the conditions –
              <ol>
                <li>
                  relate only to keeping the pet at the premises; and
                </li>
                <li>
                  are reasonable having regard to the type of pet and the nature of the premises; and
                </li>
                <li>
                  are stated in the written approval given to the tenant in a way that is consistent with clause 35.3.
                </li>
              </ol>
            </li>
            <li>
              Without limiting subclause (1)(b), the following conditions of the lessor’s approval are taken to be reasonable –
              <ol>
                <li>
                  if the pet is not a type of pet ordinarily kept inside – a condition requiring the pet to be kept outside at the premises;
                </li>
                <li>
                  if the pet is capable of carrying parasites that could infest the premises – a condition requiring the premises to be professionally fumigated at the end of the tenancy;
                </li>
                <li>
                  if the pet is allowed inside the premises – a condition requiring carpets in the premises to be professionally cleaned at the end of the tenancy.
                </li>
              </ol>
            </li>
            <li>
              A condition of the lessor’s approval to keep a pet at the premises is void if the condition –
              <ol>
                <li>
                  would have the effect of the lessor contravening section 171 or 172; or
                </li>
                <li>
                  would, as a term of this agreement, be void under section 173; or
                </li>
                <li>
                  would increase the rent or rental bond payable by the tenant; or
                </li>
                <li>
                  would require any form of security from the tenant.
                </li>
              </ol>
            </li>
            <li>
              For subclause (2), the premises are professionally fumigated, and carpets are professionally cleaned, if the fumigation and cleaning are done to a standard ordinarily achieved by businesses selling those services.
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Division 7 Restrictions on transfer or subletting by tenant
        </h3>
        <li>
          <h4 className='text-left w-100'>
            General – ss 238 and 240
          </h4>
          <ol>
            <li>
              Subject to clause 35, the tenant may transfer all or a part of the tenant’s interest under this
              agreement, or sublet the premises, only if the lessor agrees in writing or if the transfer or
              subletting is made under a tribunal order.
            </li>
            <li>
              The lessor must act reasonably in failing to agree to the transfer or subletting.
            </li>
            <li>
              The lessor is taken to act unreasonably in failing to agree to the transfer or subletting if
              the lessor acts in a capricious or retaliatory way.
            </li>
            <li>
              The lessor or the lessor’s agent must not require the tenant to pay, or accept from the tenant,
              an amount for the lessor’s agreement to a transfer or subletting by the tenant, other than an
              amount for the reasonable expenses incurred by the lessor in agreeing to the transfer or subletting.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            State assisted lessors or employees of lessor – s 237
          </h4>
          <ol>
            <li>
              This clause applies if –
              <ol>
                <li>
                  the lessor is the State; or
                </li>
                <li>
                  the lessor is an entity receiving assistance from the State to supply rented accommodation; or
                </li>
                <li>
                  the tenant’s right to occupy the premises comes from the tenant’s terms of employment.
                </li>
              </ol>
            </li>
            <li>
              The tenant may transfer the whole or part of the tenant’s interest under this agreement, or
              sublet the premises, only if the lessor agrees in writing to the transfer or subletting.
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Division 8 When agreement ends
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Ending of agreement – s 277
          </h4>
          <ol>
            <li>
              This agreement ends only if –
              <ol>
                <li>
                  the lessor and tenant agree, in a separate written document, to end this agreement; or
                </li>
                <li>
                  the lessor gives a notice to leave premises to the tenant under section 326 and the tenant hands over vacant possession of the premises to the lessor on or before the handover day; or
                </li>
                <li>
                  the tenant gives a notice of intention to leave premises to the lessor under section 327 and hands over vacant possession of the premises to the lessor on or before the handover day; or
                </li>
                <li>
                 the tenant vacates, or is removed from, the premises after receiving a notice from a mortgagee or appointed person under section 317; or
                </li>
                <li>
                  the tenant abandons the premises and the period for which the tenant paid rent has ended; or
                </li>
                <li>
                  the tribunal makes an order terminating this agreement.
                </li>
              </ol>
            </li>
            <li>
              Also, this agreement ends for a sole tenant if –
              <ol>
                <li>
                  the tenant gives the lessor a notice ending tenancy interest and hands over vacant possession of the premises; or
                </li>
                <li>
                  See chapter 5, part 1, division 3, subdivision 2A of the Act for the obligations of the lessor and tenant relating to a notice ending tenancy interest.
                </li>
                <li>
                  the tenant dies.
                </li>
                <i>Note</i> See section 324A for when this agreement ends if a sole tenant dies.
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Condition premises must be left in – s 188(4) and (5)
          </h4>
          <ol>
            <li>
              At the end of the tenancy, the tenant must leave the premises, as far as possible, in the same
              condition they were in at the start of the tenancy, fair wear and tear excepted.<br/>
              <i>Examples of what may be fair wear and tear –</i>
              <ul>
                <li>wear that happens during normal use</li>
                <li>changes that happen with ageing</li>
              </ul>
            </li>
            <li>
              The tenant’s obligation mentioned in subclause (1) does not apply to the extent the obligation would have the effect of requiring the tenant to repair, or compensate the lessor for, damage to the premises or inclusions caused by an act of domestic violence experienced by the tenant.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Keys
          </h4>
          At the end of the tenancy, the tenant must return to the lessor all keys for the premises
        </li>
        <li>
          <h4 className='text-left w-100'>
            Tenant’s forwarding address – s 205(2) and (3)
          </h4>
          <ol>
            <li>
              When handing over possession of the premises, the tenant must, if the lessor or the lessor’s
              agent asks the tenant in writing to state the tenant’s new residential address, tell the lessor
              or the agent the tenant’s new residential address.
            </li>
            <li>
              However, subclause (1) does not apply if –
              <ol>
                <li>
                  the tenant has a reasonable excuse for not telling the lessor or agent the new address; or
                </li>
                <li>
                  after experiencing domestic violence, the tenant ended this agreement, or the tenant’s interest in this agreement, under chapter 5, part 1, division 3, subdivision 2A of the Act.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Exit condition report – s 66
          </h4>
          <ol>
            <li>
              As soon as practicable after this agreement ends, the tenant must prepare, in the approved form,
              and sign a condition report for the premises and give 1 copy of the report to the lessor or the
              lessor’s agent.<br/>
              <i>Example of what might be as soon as practicable</i> – when the tenant returns the keys to the premises
              to the lessor or the lessor’s agent<br/>
              <i>Note</i> – For the approved form for the condition report, see the information statement.
              The report may be very important in deciding who is entitled to a refund of the rental bond if
              there is a dispute about the condition of the premises.
            </li>
            <li>
              The lessor or the lessor’s agent must, within 3 business days after receiving the copy of the report –
              <ol>
                <li>
                  sign the copy; and
                </li>
                <li>
                  if the lessor or agent does not agree with the report – show the parts of the report the lessor
                  or agent disagrees with by marking the copy in an appropriate way; and
                </li>
                <li>
                  if the tenant has given a forwarding address to the lessor or agent – make a copy of the
                  report and return it to the tenant at the address.
                </li>
              </ol>
            </li>
            <li>
              The lessor or agent must keep a copy of the condition report signed by both parties for at least
              1 year after this agreement ends.
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Goods or documents left behind on premises – ss 363 and 364
          </h4>
          <ol>
            <li>
              The tenant must take all of the tenant’s belongings from the premises at the end of the tenancy.
            </li>
            <li>
              The lessor may not treat belongings left behind as the lessor’s own property, but must deal with
              them under sections 363 and 364.<br/>
              <i>Note</i> – For details of the lessor’s obligations under sections 363 and 364, see the
              information statement. They may include an obligation to store goods and may allow the lessor
              to sell goods and pay the net sale proceeds (after storage and selling costs) to the public
              trustee.
            </li>
          </ol>
        </li>
        <h3 className='heading blue text-left w-100'>
          Division 9 Miscellaneous
        </h3>
        <li>
          <h4 className='text-left w-100'>
            Supply of goods and services – s 171
          </h4>
          <ol>
            <li>
              The lessor or the lessor’s agent must not require the tenant to buy goods or services from the
              lessor or a person nominated by the lessor or agent.
            </li>
            <li>
              Subclause (1) does not apply to –
              <ol>
                <li>
                  a requirement about a service charge; or <br/>
                  <i>Note</i> – See section 164 for what is a service charge.
                </li>
                <li>
                  condition of an approval to keep a pet if the condition –
                  <ul>
                    <li>
                      requires the carpets to be cleaned, or the premises to be fumigated, at the end of the tenancy; and
                    </li>
                    <li>
                      complies with clause 36; and
                    </li>
                    <li>
                      does not require the tenant to buy cleaning or fumigation services from a particular person or business.
                    </li>
                  </ul>
                </li>
              </ol>
              
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Lessor’s agent
          </h4>
          <ol>
            <li>
              The name and address for service of the lessor’s agent is stated in this agreement for item 3.
            </li>
            <li>
              Unless a special term provides otherwise, the agent may –
              <ol>
                <li>
                  stand in the lessor’s place in any application to a tribunal by the lessor or the tenant; or
                </li>
                <li>
                  do any thing else the lessor may do, or is required to do, under this agreement
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h4 className='text-left w-100'>
            Notices
          </h4>
          <ol>
            <li>
              A notice under this agreement must be written and, if there is an approved form for the notice,
              in the approved form.
              <i>Note</i> – Download approved forms via the RTA website rta.qld.gov.au.
            </li>
            <li>
              A notice from the tenant to the lessor may be given to the lessor’s agent.
            </li>
            <li>
              A notice may be given to a party to this agreement or the lessor’s agent –
              <ol>
                <li>
                  by giving it to the party or agent personally; or
                </li>
                <li>
                  if an address for service for the party or agent is stated in this agreement for
                  item 1, 2 or 3 – by leaving it at the address, sending it by prepaid post as a letter to
                  the address; or
                </li>
                <li>
                  if a facsimile number for the party or agent is stated in this agreement for item 1, 2
                  or 3 and item 4 indicates that a notice may be given by facsimile – by sending it by
                  facsimile to the facsimile number in accordance with the <i>Electronic Transactions
                  (Queensland)</i> Act 2001; or
                </li>
                <li>
                  if an email address for the party or agent is stated in this agreement for item 1, 2 or 3
                  and item 4 indicates that a notice may be given by email – by sending it electronically
                  to the email address in accordance with the <i>Electronic Transactions
                  (Queensland)</i> Act 2001.
                </li>
              </ol>
            </li>
            <li>
              A party or the lessor’s agent may withdraw his or her consent to notices being given to them
              by facsimile or email only by giving notice to each other party that notices are no longer to
              be given to the party or agent by facsimile or email.
            </li>
            <li>
              If no address for service is stated in this agreement for item 2 for the tenant, the tenant’s
              address for service is taken to be the address of the premises.
            </li>
            <li>
              A party or the lessor’s agent may change his or her address for service, facsimile number or
              email address only by giving notice to each other party of a new address for service, facsimile
              number or email address.
            </li>
            <li>
              On the giving of a notice of a new address for service, facsimile number or email address
              for a party or the lessor’s agent, the address for service, facsimile number or email address
              stated in the notice is taken to be the party’s or agent’s address for service, facsimile
              number or email address stated in this agreement for item 1, 2 or 3.
            </li>
            <li>
              Unless the contrary is proved –
              <ol>
                <li>
                  a notice left at an address for service is taken to have been received by the party to
                  whom the address relates when the notice was left at the address; and
                </li>
                <li>
                  a notice sent by post is taken to have been received by the person to whom it was
                  addressed when it would have been delivered in the ordinary course of post; and
                </li>
                <li>
                  a notice sent by facsimile is taken to have been received at the place where the
                  facsimile was sent when the sender’s facsimile machine produces a transmission report
                  indicating all pages of the notice have been successfully sent; and
                </li>
                <li>
                  a notice sent by email is taken to have been received by the recipient when the email
                  enters the recipient’s email server.
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </>
  )
}
