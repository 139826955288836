import {observable, action, computed} from "mobx"

import {IStaticPage} from "./types";
import {getTermsConditions} from "../../networking/core"


export class StaticPage implements IStaticPage {
    @observable staticPage = {
        alias: '',
        content: '',
        created_at: '',
        description: '',
        id: 0,
        title: '',
        updated_at: '',
        version: ''
    }

    @action setPage = () => {
        return getTermsConditions()
            .then(res => {
                this.staticPage = res.data
                return res
            })
    }

    @computed get page() {
        return this.staticPage
    }
}
