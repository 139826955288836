import React from 'react'
import {createMuiTheme} from "@material-ui/core"
import {Icon} from "antd"

const DropDownIcon = (props: any) => (<Icon {...props} component={() => <i className="la la-angle-down"/>}/>)
// const DropUpIcon = (props: any) => (<Icon {...props} component={() => <i className="la la-angle-down" />} />)

export const themeOverrides = createMuiTheme({
    overrides: {
        MuiFormControl: {
            root: {
                verticalAlign: 'middle',
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: '14px',
                color: '#98a9bc',
                fontFamily: 'CircularBook',
                lineHeight: '26px',
                '&$filled': {
                    fontSize: '12px',
                },
                '&$focused': {
                    fontSize: '12px',
                    color: '#4d7cfe',
                },
            },
        },
        MuiFormControlLabel: {
            root: {
                marginRight: '-10px',
                marginLeft: '0',
                '&$labelPlacementStart': {
                    marginRight: '0',
                    marginLeft: '-10px',
                },
            },
            label: {
                fontSize: '14px',
                color: '#3a3a44',
                fontFamily: 'CircularBook',
                lineHeight: '1.2',
                marginLeft: '10px',
                marginRight: '10px',
            }
        },
        MuiInput: {
            root: {
                height: '40px',
                color: '#252631',
                fontFamily: 'CircularBook',
                fontSize: '14px',
                '&$underline:before': {
                    borderBottomColor: '#dfdfdf',
                    borderBottomWidth: '1px !important',
                },
                '&$underline:after': {
                    borderBottomColor: '#4d7cfe',
                    borderBottomWidth: '1px !important',
                },
                '&& > .anticon': {
                    fontSize: '22px',
                    color: '#98a9bc',
                },
                '&& > .anticon:first-child': {
                    position: 'absolute',
                    top: '50%',
                    left: '0',
                    marginTop: '-11px',
                },
                '&& > .anticon:first-child + *': {
                    paddingLeft: '32px',
                },
            },
            input: {
                height: '100%',
                padding: '0',
            },
        },
        MuiSelect: {
            root: {
                color: '#98a9bc'
            },
            selectMenu: {
                height: '40px',
                lineHeight: '44px',
                paddingRight: '20px'
            },
            icon: {
                fontSize: '22px',
                color: '#98a9bc',
            },
        },
        MuiSwitch: {
            root: {
                width: '64px',
                height: '39px',
                padding: '6px',
            },
            track: {
                borderRadius: '13px',
                backgroundColor: '#f4f0e9',
                opacity: 1,
            },
            switchBase: {
                padding: '6px',
                '&$checked': {
                    transform: 'translateX(26px)',
                },
                '&$checked&$colorPrimary': {
                    color: '#4d7cfe',
                    '& + $track': {
                        backgroundColor: '#4d7cfe',
                    },
                },
            },
            thumb: {
                width: '26px',
                height: '26px',
            },
        },
        MuiMenu: {
            paper: {
                backgroundColor: '#fff',
                boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.09)',
                borderRadius: 0,
            },
        },
        MuiMenuItem: {
            root: {
                minHeight: 0,
                color: '#343f4d',
                fontSize: '14px',
                fontFamily: 'CircularBook',
            },
        },
    },
    props: {
        MuiTextField: {
            SelectProps: {
                IconComponent: DropDownIcon,
                MenuProps: {
                    variant: 'menu',
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                },
            }
        },
        MuiMenu: {
            variant: 'menu',
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            },
        },
        MuiSwitch: {
            disableRipple: true,
        },
        MuiRadio: {
            disableRipple: true,
        },
        MuiCheckbox: {
            disableRipple: true,
        },
        MuiButtonBase: {
            disableRipple: true,
        },
    },
})
