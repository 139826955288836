import React, {Component} from "react"
import {withRouter} from 'react-router'
import {
    inject,
    observer
} from "mobx-react"
import {
    Spin,
    Icon
} from 'antd'
//@ts-ignore
import Div100vh from 'react-div-100vh'

import BottomDrawer from "../../drawers/BottomDrawer/BottomDrawer"

import {
    InjectedProps,
    Props,
    State
} from "./types"

import {getItem, setItem} from "../../../helpers/storage"
import {setJwtToken} from "../../../networking/core"

import {
  WELCOME_PAGE,
  CONGRATULATION_STEP,
  CONNECTION_CALLBACK_STEP,
  START_PAGE,
} from "../../../routing/paths"

import logo from '../../../resources/images/svg/logo-with-text.svg'

import './styles.scss'

const antIcon = <Icon type="loading" style={{fontSize: 24}} spin/>

@inject('Core', 'Lease')
@observer
class MainLayout extends Component<Props, State>{

    state = {
        loading: false,
        leaseLoaded: false
    }

    get injected(){
        return this.props as InjectedProps
    }

    componentWillMount() {
        const link_code = getItem('link_code')
        //@ts-ignore
        !getItem('resuming')&&setItem('resuming', JSON.stringify({link_code:'', location:''}))
        //@ts-ignore
        const resuming = JSON.parse(getItem('resuming'))
        if(resuming && resuming.link_code === link_code){
           return  this.props.history.push(resuming.location)
        }
            setItem('resuming',JSON.stringify({link_code, location:''}))
    }

    componentDidMount(): void{
        const {loadLease} = this.props
        if(loadLease){
            const token = getItem('token')
            if(token){
                setJwtToken(token)
            }
            this.setState({loading: true}, () => {
                this.injected.Lease.setLease()
                        .then(res => {
                            let agencyCustomization = res.agency.customization;
                            if(agencyCustomization){
                                let customColor = agencyCustomization.brand_color;
                                if (customColor){
                                    document.documentElement.style.setProperty('--primary-color', customColor);
                                }
                            }
                        })
                        .finally(() => this.setState({loading: false, leaseLoaded: true}))
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void{
        if(prevProps.location.pathname !== this.props.location.pathname){
            window.scrollTo(0, 0)
        }
    }

    render(): React.ReactElement{
        const {children, history, location} = this.props
        const resuming = JSON.parse(getItem('resuming')||'')
        setItem('resuming', JSON.stringify({...resuming,location:location.pathname}) )
        const {Core, Lease} = this.injected
        const {loading} = this.state
        const showLogo: boolean = ![CONNECTION_CALLBACK_STEP].includes(location.pathname)
        const showBackButton: boolean = ![START_PAGE, CONGRATULATION_STEP, CONNECTION_CALLBACK_STEP].includes(location.pathname)
        const showCallButton: boolean = ![CONGRATULATION_STEP, CONNECTION_CALLBACK_STEP].includes(location.pathname)

        let logoElement = <img src={logo} alt="Logo"/>;
        let agencyCustomization = Lease.currentLease.agency.customization;
        if(agencyCustomization){
            // @ts-ignore
            let customLogo = agencyCustomization.tenancy_logo;
            if (customLogo){
                logoElement = <img src={customLogo.full_url} alt="Logo"/>;
            }
        }

        return (
                <Div100vh style={{
                    minHeight: '100rvh',
                    backgroundColor: '#fff',
                    display: 'flex',
                    maxWidth: 991,
                    margin: '0 auto'
                }}>
                    {
                        loading ?
                                <div className='loading-container'>
                                    <Spin indicator={antIcon}/>
                                </div>
                                :
                                <div className='main-layout__container'>

                                    {location.pathname != "/lease/start" &&
                                        <div className='main-layout__header'>
                                            <div>
                                                {showBackButton &&
                                                <i
                                                        className="la la-angle-left c-pointer"
                                                        onClick={() => history.goBack()}
                                                />
                                                }
                                            </div>
                                            {showLogo && logoElement}
                                            <div>
                                                {showCallButton &&
                                                <i className="la la-phone-alt c-pointer" onClick={() => {
                                                    Core.toggleCallDialog()
                                                }}/>}
                                            </div>
                                        </div>
                                    }

                                    {this.state.leaseLoaded && children}
                                </div>
                    }

                    <BottomDrawer
                            Lease={Lease}
                            onClose={() => Core.toggleCallDialog()} visible={Core.VisibleCallDialog}/>
                </Div100vh>
        )
    }
}

//@ts-ignore
const MainLayoutWithRouter = withRouter(MainLayout)

export {MainLayoutWithRouter}
