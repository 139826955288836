import * as components from '../pages'
import * as paths from './paths'
import {Route} from "./types"

export const routes: Array<Route> = [
  // {
  //     path: paths.VERIFICATION_SMS_CODE,
  //     component: components.VerificationSmsPage,
  //     nextPath: paths.WELCOME_PAGE,
  //     exact: true,
  // },
  {
    path: paths.START_PAGE,
    component: components.StartPage,
    nextPath: paths.WELCOME_PAGE,
    exact: true,
    private: true
  },
  {
    path: paths.WELCOME_PAGE,
    component: components.WelcomePage,
    nextPath: paths.INFORMATION_STATEMENT,
    exact: true,
    private: true
  },
  {
    path: paths.INFORMATION_STATEMENT,
    component: components.InformationStatementStep,
    nextPath: paths.INFORMATION_PAGE,
    exact: true,
    private: true
  },
  // {
  //   path: paths.CURRENT_LOCATION_PAGE,
  //   component: components.GrabLocationPage,
  //   nextPath: paths.INFORMATION_PAGE,
  //   private: true,
  //   exact: true
  // },
  {
    path: paths.INFORMATION_PAGE,
    component: components.InformationPage,
    nextPath: paths.PROPERTY_DETAILS_LEASE_STEP,
    private: true
  },
  {
    path: paths.PROPERTY_DETAILS_LEASE_STEP,
    component: components.PropertyWithMapsStep,
    nextPath: paths.TENANT_DETAILS_LEASE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.TENANT_DETAILS_LEASE_STEP,
    component: components.TenantDetailsSteps,
    nextPath: paths.RENTAL_DETAILS_LEASE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.RENTAL_DETAILS_LEASE_STEP,
    component: components.RentalDetailsStep,
    nextPath: paths.RENT_BOND_DETAILS_LEASE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.RENT_BOND_DETAILS_LEASE_STEP,
    component: components.RentBondStep,
    nextPath: paths.OCCUPANCY_DETAILS_LEASE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.OCCUPANCY_DETAILS_LEASE_STEP,
    component: components.OccupancyDetailsStep,
    nextPath: paths.INCLUSION_DETAILS_LEASE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.INCLUSION_DETAILS_LEASE_STEP,
    component: components.InclusionDetailsStep,
    nextPath: paths.EMERGENCY_CONTACTS_LEASE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.EMERGENCY_CONTACTS_LEASE_STEP,
    component: components.EmergencyContactStep,
    nextPath: paths.SMOKE_ALARMS_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.SMOKE_ALARMS_STEP,
    component: components.SmokeAlarmsStep,
    nextPath: paths.CONDITION_REPORT_TENANCY_LAWS,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.CONDITION_REPORT_TENANCY_LAWS,
    component: components.ConditionReportTenancyLawsStep,
    nextPath: paths.ELECTRONIC_SERVICES,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.ELECTRONIC_SERVICES,
    component: components.ElectronicServicesStep,
    nextPath: paths.WATER_USAGES_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.WATER_USAGES_STEP,
    component: components.WaterUsegesStep,
    nextPath: paths.NOTES_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.NOTES_STEP,
    component: components.NotesStep,
    nextPath: paths.IMPORTANT_INFORMATION_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.IMPORTANT_INFORMATION_STEP,
    component: components.ImportantInformationStep,
    nextPath: paths.GENERAL_AGREEMENT_LEASE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.GENERAL_AGREEMENT_LEASE_STEP,
    component: components.GeneralAgreementStep,
    nextPath: paths.ADDITIONAL_TERMS_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.ADDITIONAL_TERMS_STEP,
    component: components.AdditionalTermsStep,
    nextPath: paths.SPECIAL_CONDITION_LEASE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.SPECIAL_CONDITION_LEASE_STEP,
    component: components.SpecialConditionStep,
    nextPath: paths.SIGNATURE_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.SIGNATURE_STEP,
    component: components.SignatureStep,
    nextPath: paths.CONGRATULATION_STEP,
    isLeaseSteps: true,
    private: true
  },
  // {
  //     path: paths.WITNESS_LEASE_STEP,
  //     component: components.WitnessStep,
  //     nextPath: paths.CONGRATULATION_STEP,
  //     isLeaseSteps: true,
  //     private: true
  // },
  {
    path: paths.CONGRATULATION_STEP,
    component: components.CongratulationStep,
    nextPath: paths.CONNECTION_CALLBACK_STEP,
    isLeaseSteps: true,
    private: true
  },
  {
    path: paths.CONNECTION_CALLBACK_STEP,
    component: components.ConnectionCallBackStep,
    nextPath: paths.SIGNATURE_STEP,
    isLeaseSteps: true,
    private: true
  }
]
