import React from "react"
import {
    inject,
    observer
} from "mobx-react"
import {
    Button,
    Collapse,
    Icon
} from 'antd'

//@ts-ignore
import ReactHtmlParser from 'react-html-parser';

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons"

import {
    Props,
    State,
    InjectedProps
} from "./types"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import './styles.scss'

const {Panel} = Collapse

@inject('Lease')
@observer
export class SpecialConditionStep extends ComponentWithInjectedProps<Props, State, InjectedProps>{

    state: State

    constructor(props: Props){
        super(props)
        this.state = {
            isVisibleModal: false,
            activeTab: undefined,
            agreedConditions: []
        }
        const {Lease: {lease: {special_conditions}}} = this.injectedProps
        //If special condition is not exist skip this screen
        if(!special_conditions.length){
            const {history, nextPath} = this.props
            history.replace(nextPath)
        }
    }

    private setAgreedCondition = (condition_id: number): void => {
        const {agreedConditions} = this.state
        this.setState({agreedConditions: agreedConditions.includes(condition_id) ? agreedConditions : [...agreedConditions, condition_id]})
    }

    private checkAgreedAllCondition = (): boolean => {
        const {agreedConditions} = this.state
        const {Lease: {lease: {special_conditions}}} = this.injectedProps
        if(special_conditions.length !== agreedConditions.length){
            return false
        }
        return special_conditions.every((condition) => agreedConditions.includes(condition.id))
    }

    private sign = () => {
        const {history, nextPath} = this.props
        history.push(nextPath)
    }

    render(): React.ReactElement{
        const {activeTab, agreedConditions} = this.state
        const {Lease: {lease: {special_conditions, property}}} = this.injectedProps

        return (
                <LeaseStepsContainer title={property.state == "VIC" ? "Part E - Additional Terms" : 'Special Condition'}>
                    {{
                        content: (
                                <div className="special-condition-step__container">
                                    <Icons.WebChecklist/>
                                    <h4 className='heading-blue'>{property.state == "VIC" ? "Additional Terms (if any)" : 'Special Condition'}</h4>

                                    {property.state == "VIC" &&
                                    <>
                                        <p className="heading-secondary w-100">List any additional terms to this agreement. The terms
                                            listed must not exclude, restrict or modify any of the rights and duties included in the Act.<br/>
                                            Additional terms must also comply with the Australian Consumer Law (Victoria). For example, they cannot be
                                            unfair terms, which will have no effect. Contact Consumer Affairs Victoria on 1300 558 181 for further
                                            information or visit<br/>
                                            consumer.vic.gov.au/products-and-services/business-practices/contracts/unfair-contract-terms.</p>
                                        {/*<div className="heading-secondary list w-100">*/}
                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Meanings in these additional terms</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                In these additional terms “I”, “me”, or “my”, are used to describe the rental provider and “you” or*/}
                                        {/*                “your”, therenter. The descriptions apply even if there is more than one rental provider or renter.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                <b>*Important advice about “writing”.</b> In these additional terms the word “writing” means all ways of*/}
                                        {/*                representing or reproducing words, figures, or symbols in a visible form, unless a form prescribed by*/}
                                        {/*                the Residential Tenancies Act or Regulations or some other legislation must be used. These are examples*/}
                                        {/*                of “writing”: an SMS message, an email, a facsimile and a letter.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                Before you use an electronic means to send a message or document to me check clause 9.1 to see if I*/}
                                        {/*                have consented to the electronic service of notices or other documents. If I have, check if I have*/}
                                        {/*                provided another email address to the one in clause 9.1 or if I have withdrawn my consent. If you can*/}
                                        {/*                give me a notice or other document by electronic service also check to see if you need to use email*/}
                                        {/*                instead of an SMS message. If I have not given, or have withdrawn, my consent to receive notices or*/}
                                        {/*                other documents by electronic means, you will need to use the post or delivery by hand to serve me*/}
                                        {/*                with notices or other documents.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Other use of the rented premises</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must use the premises primarily as your home. If you also want to use them for some ancillary*/}
                                        {/*                purpose – for example, as an office for your business, as a rooming house, for short term accommodation,*/}
                                        {/*                or to provide services to clients visiting the premises, you must ask me in *writing for permission*/}
                                        {/*                beforehand. Before I decide I may ask you to provide reasonable information about the proposed use,*/}
                                        {/*                including any proposed alterations to the premises, and if I give my permission, I may ask you to comply*/}
                                        {/*                with reasonable conditions. Before the rental agreement ends you must also comply with section 64(2) of*/}
                                        {/*                the Residential Tenancies Act. You cannot use an SMS message to ask me for my permission.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                Use of the rental premises primarily as a home does not include:*/}
                                        {/*                <ul style={{width: "100%"}} className="heading-secondary">*/}
                                        {/*                    <li>the storage of flammable liquids or gases apart from in small quantities for normal domestic use,*/}
                                        {/*                    </li>*/}
                                        {/*                    <li>the service or repair of a vehicle or boat of any description except for routine minor*/}
                                        {/*                        maintenance,*/}
                                        {/*                    </li>*/}
                                        {/*                    <li>disposal on the premises, including the land, of any liquid fuels, oils, tyres, paints, or other*/}
                                        {/*                        polluting substances.*/}
                                        {/*                    </li>*/}
                                        {/*                </ul>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Storage and removal of waste and rubbish</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must store rubbish and waste in appropriate containers with close fitting lids.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If a place or places are provided for rubbish and waste containers you will keep them there.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You will have rubbish and waste removed regularly in accordance with the municipality’s rubbish and*/}
                                        {/*                waste removal timetables.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                The only waste containers the Rental Tenancies Regulations require me to provide are a rubbish bin and a*/}
                                        {/*                recycling bin which are provided by the local council, or which are vermin proof and compatible with*/}
                                        {/*                local council collection.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="list-item">*/}
                                        {/*        <b>My insurance on the premises</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If I provide you with a copy of my insurance policy for the rented premises you will not do anything*/}
                                        {/*                that may invalidate it or result in my insurance premium or excess being increased, unless you are*/}
                                        {/*                entitled to do so by the Residential Tenancies Act 1997 or some other legislation.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If it is found you are liable to compensate or reimburse me for damage to any part of the premises,*/}
                                        {/*                and I recover part or all of the loss I have suffered by making a successful claim on my insurance,*/}
                                        {/*                you will only be liable to reimburse me for that part of your liability which is not covered by the*/}
                                        {/*                amount I recover from my insurance.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                My insurance policy does not cover your goods and personal belongings against theft, loss, or damage.*/}
                                        {/*                If you wish to insure your goods and personal belongings against theft, loss, or damage it is your*/}
                                        {/*                responsibility to do so.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Locks (see clause 23) and alarms</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                Key of a lock means a device or information normally used to operate the lock.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                Lock means a device for securing a door or window or other part of the premises.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                Master key system means a set of locks in which each lock or subset of locks has a unique key, and*/}
                                        {/*                one single key or master key can operate all the locks in the set.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You may change locks at the rented premises but only if you install replacement locks that will not be*/}
                                        {/*                capable of being operated by the keys already provided and will instead be operated by new keys.*/}
                                        {/*                Any change of locks must not breach the minimum standards for locks contained in the Residential*/}
                                        {/*                Tenancies Act and Regulations.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If the lock is keyed alike with other locks in the premises and you make a change to the keying of any of*/}
                                        {/*                those locks, you must change all the keyed alike locks so that they can continue to be operated by one*/}
                                        {/*                key.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you change the locks, you must purchase the same number of keys as were supplied to you at the*/}
                                        {/*                commencement of the tenancy and supply them to me or my managing agent at the end of the tenancy.*/}
                                        {/*                In addition, you must give to me or my managing agent duplicates of the new key/s as soon as*/}
                                        {/*                practicable and preferrable within one business day of changing the locks.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You may change the code of an alarm at the rented premises.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you change the code or install an alarm system you must tell me or my managing agent in *writing of*/}
                                        {/*                the code as soon as practicable and preferable within one business day of the change or installation.*/}
                                        {/*                You cannot use and SMS message to tell me of the new code.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Defects (see clauses 25 and 26)</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                When you become aware of a defect at the rented premises that may injure someone or cause damage you*/}
                                        {/*                must, in addition to telling me or my managing agent as soon as possible, take reasonable action to*/}
                                        {/*                avoid risk of injury to yourself or anybody else and to prevent further damage.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Light globes, fluorescent tubes, and LED lights</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                At the commencement of the rental agreement light globes, fluorescent tubes and LED lights will be in*/}
                                        {/*                working order. If on taking possession of the rental premises you find this to not be the case, you*/}
                                        {/*                must notify me or my managing agent as soon as possible so that the problem may be rectified at my*/}
                                        {/*                expense.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                During the rental agreement you must replace any light globes, fluorescent tubes, starters, and LED*/}
                                        {/*                lights that cease to function, unless it has ceased to function due to actions taken by me, my*/}
                                        {/*                managing agent, or my contractor. The requirement for you to replace LED lights does not extend*/}
                                        {/*                to instances where the light fitting needs to be replaced, because as with other faulty light fittings,*/}
                                        {/*                their replacement is my responsibility.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If for whatever reason you cannot personally fulfil your obligations, you may at your expense*/}
                                        {/*                engage a suitable contractor to complete the tasks.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Sewers and septic systemss</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                Things that may cause a blockage must not be placed into the sewerage or septic system, in addition*/}
                                        {/*                to the toilet/s this includes drainage from the kitchen, laundry and bathroom/s. These are examples*/}
                                        {/*                of things that may cause a blockage: cotton waste, disposable nappies, excessive amounts of toilet*/}
                                        {/*                paper, paper towel, tampons, sanitary pads, continence pads, wipes, cooking fats and oils, and food*/}
                                        {/*                waste. This is not a complete list and has been provided as a guide only.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                When you become aware to a blockage or defect in the sewerage of septic system you must tell me or my*/}
                                        {/*                managing agent as soon as possible, preferably within 24 hours, even if you, or anyone you have allowed*/}
                                        {/*                to come on to the rented premises, including me or my managing agent or my contractor, caused it.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you, or anyone you have allowed to come on to the rented premises negligently or intentionally causes*/}
                                        {/*                a blockage or defect in the sewerage or septic system you must pay to me the reasonable expenses I incur*/}
                                        {/*                in having it rectified. You do not have to do so if I, or my managing agent or my contractor caused the*/}
                                        {/*                blockage or defect.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Gutters, downpipes, and stormwater drains</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                As part of your obligation under the Residential Tenancies Act to keep the rented premises reasonably*/}
                                        {/*                clean you must, unless otherwise agreed in writing, keep the gutters, downpipes and stormwater drains*/}
                                        {/*                free of debris and other matter to the extent that their proper functioning is not impeded. If for*/}
                                        {/*                whatever reason you cannot personally fulfil this obligation you may at your expense engage a suitable*/}
                                        {/*                contractor to complete the tasks.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If the proper functioning of the gutters, downpipes or stormwater drains is impeded due to a fault or*/}
                                        {/*                need for repair, you must notify me or my managing agent as soon as possible. If the need for repair or*/}
                                        {/*                replacement is the result of you, other occupants of the rented premises or your visitors intentionally*/}
                                        {/*                or negligently causing damage to the rented premises or common areas I will arrange for the necessary*/}
                                        {/*                repair or replacement, however you will be required to reimburse me for the expense.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Gardens and lands</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If under this rental agreement you are provided with, in addition to the built structures, gardens or*/}
                                        {/*                land as part of the premises, you must unless otherwise agreed in writing, maintain them in a*/}
                                        {/*                reasonably clean condition and free from damage apart from fair wear and tear.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                These are examples of things you may need to do to care for the garden and land: mow grass; water*/}
                                        {/*                subject to water restrictions, as and when required; remove weeds; rake up and remove lawn cuttings*/}
                                        {/*                and fallen flowers and leaves; maintain trees, shrubs flowers and other plants; and as far as reasonably*/}
                                        {/*                possible keep the garden free of pests and vermin. In bushfire prone areas you must take reasonable*/}
                                        {/*                action to minimise the fuel load on the land during the fire season. This is not a complete list of*/}
                                        {/*                things you may need to do. I have provided the examples as a guide only. If for whatever reason you*/}
                                        {/*                cannot personally fulfil this obligation you may at your expense engage a suitable contractor to*/}
                                        {/*                complete the tasks.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must make a request in *writing for my permission if you wish to remove any plants apart from weeds,*/}
                                        {/*                or if you wish to change the layout of garden beds, grassed areas, paved areas and so on. The request*/}
                                        {/*                must not be made by SMS message.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must make a request in *writing for my permission if you wish to plant any trees, shrubs, or vines,*/}
                                        {/*                apart from those that form part of a vegetable or herb garden. The request must not be made by SMS*/}
                                        {/*                message.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must not dispose of any polluting or toxic substance on the land.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Mould</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You should take all reasonable steps to prevent the development of mould (fungi) in the rented premises.*/}
                                        {/*                These steps include but are not limited to; ventilating the premises by use of exhaust fans and openable*/}
                                        {/*                windows provided, particularly if you need to dry washing inside the premises; using an appropriate*/}
                                        {/*                household cleaner to regularly clean surfaces on which condensation forms; and preventing window*/}
                                        {/*                furnishings, furniture and clothes being in contact with surfaces on which condensation forms.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you see signs of mould in the premises you must notify me or my managing agent as soon as possible.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If the mould has developed due to a fault, such as a leak in the premises, or is related to the building*/}
                                        {/*                structure I will arrange for it to be rectified and the mould treated. In the meantime, you should take*/}
                                        {/*                all reasonable steps to avoid exposure to the mould.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>You cannot use your bond to pay rent</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You acknowledge the Residential Tenancies Act 1997 provides you may not refuse to pay rent for the*/}
                                        {/*                premises on the ground you intend to regard the bond as rent paid and it allows VCAT to impose a penalty*/}
                                        {/*                if satisfied a breach of the bond requirements has occurred.<br/>*/}
                                        {/*                <b>‘To Let’, ‘Auction’, ‘For Sale’ etc signs at the rented premises</b>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You will allow me, or my managing agent, to put up a sign on the rented premises during the final month*/}
                                        {/*                of the rental agreement indicating the premises will be available for renting. The sign will be*/}
                                        {/*                positioned so as not to interfere with your use of the rented premises.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You will allow me or my estate agent to put up a sign on the premises at any time indicating that it is*/}
                                        {/*                available to be purchased. The sign will be positioned so as not to interfere with your use of the*/}
                                        {/*                rented premises.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Assigning, subletting, or abandoning the rented premises (see clause 27)</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If during the period of the rental agreement the people in occupation of the rented premises are to*/}
                                        {/*                change you must notify or my managing agent as soon as possible after you become aware the change is*/}
                                        {/*                to happen, or has happened, preferably within 24 hours and ask me or my managing agent in *writing for*/}
                                        {/*                permission to assign your rental agreement or sub-let the rental premises. Neither I nor my managing*/}
                                        {/*                agent will unreasonably withhold permission. You cannot use an SMS message to ask me or my managing*/}
                                        {/*                agent for permission.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If the rental agreement is assigned to a new renter or combination of renters, even if some are*/}
                                        {/*                continuing, I may require you to reimburse me for my reasonable expenses incurred due to the assignment.*/}
                                        {/*                These expenses will be calculated according to the following formula:<br/>*/}
                                        {/*                <b>1 weeks rental</b>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you assign or sublet the rental premises without obtaining written permission beforehand and I*/}
                                        {/*                terminate your rental agreement, or if you abandon the rental premises, I may ask you to reimburse*/}
                                        {/*                me for expenses I incur in reletting. If I do this the expenses will be calculated according to the*/}
                                        {/*                following formula:<br/>*/}
                                        {/*                - a pro-rata letting fee calculated at 5.5% of the annual rent;<br/>*/}
                                        {/*                - $300.00 for advertising or marketing expenses incurred;<br/>*/}
                                        {/*                - rental data base checks on applicants; and<br/>*/}
                                        {/*                - rent on the premises until such time as the lease is assigned or cancelled or the expiry of the*/}
                                        {/*                tenancy, whichever first occurs.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                My managing agent cannot require payment from you, however they can on my behalf require you to reimburse*/}
                                        {/*                me for expenses I incur.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Leaving the premises at the end of the fixed term (see clause 5)</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you intend to leave the rental premises at the end of the fixed term on this agreement you need to*/}
                                        {/*                tell me or my managing agent about your intention at least 28 days before the fixed term comes to an*/}
                                        {/*                end, or 14 days before the fixed term comes to an end if you fall within one of the categories set out*/}
                                        {/*                in section 91ZB of the Residential Tenancies Act 1997.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must tell me or my managing agent about your intention to leave in *writing by giving notice in a*/}
                                        {/*                form which is not an SMS message.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must return all the keys and any key cards and remote controls to me or my managing agent when you*/}
                                        {/*                leave the rented premises.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must continue to pay rent to me or my managing agent until the end of the fixed term; or to and*/}
                                        {/*                including the day on which you return all the keys, key cards and remote controls to me or my managing*/}
                                        {/*                agent if it is after the end of the fixed term. If, with your agreement, the premises are relet from a*/}
                                        {/*                date before the end of fixed term and you return the keys etc before that date you will only be required*/}
                                        {/*                to pay rent to and including the day before the new rental agreement commences.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Leaving the premises after the fixed term ends</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you remain in occupation of the rental premises after the fixed term of this agreement ends and you*/}
                                        {/*                do not enter into a new fixed term agreement with me, you must tell me or my managing agent of your*/}
                                        {/*                intention to leave specifying a date not less than 28 days after the date you tell me or my managing*/}
                                        {/*                agent, or 14 days if you fall within one of the categories set out in section 91ZB of the Residential*/}
                                        {/*                Tenancies Act 1997.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You must tell me, or my managing agent, about your intention to leave in *writing in a form that is not*/}
                                        {/*                an SMS message.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Receipt of condition report /statement of rights and duties</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                You acknowledge, before you took occupation of the rented premises, you received from me or my managing agent:*/}
                                        {/*                <ul style={{width: "100%"}} className="heading-secondary">*/}
                                        {/*                    <li>two copies of a condition report signed by me or my managing agent, and</li>*/}
                                        {/*                    <li>a written guide “Renting a home: a guide for tenants” as authorised and published by the Victorian government setting out my</li>*/}
                                        {/*                </ul>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                rights and duties as a rental provider and your rights and duties as a renter. If you consented to*/}
                                        {/*                receiving notices electronically this guide may be provided to you electronically.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Rental provider’s signature</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                I may authorise my managing agent to sign this rental agreement on my behalf. In the event you and*/}
                                        {/*                I (or my managing agent acting on my behalf) have agreed that you will rent the rented premises on the*/}
                                        {/*                terms set out in this document or we have conducted ourselves in such a way as to imply that this was*/}
                                        {/*                the case, the terms of this rental agreement will be binding even if, through an oversight, a party has*/}
                                        {/*                neglected to sign it. The Residential Tenancies Act 1997 provides the following definition of a*/}
                                        {/*                residential rental agreement in section 5:<br/>*/}
                                        {/*                “residential rental agreement means an agreement, whether or not in writing and whether express or*/}
                                        {/*                implied, under which a person lets premises as a residence (but does not include an SDA residency*/}
                                        {/*                agreement) and includes a fixed term residential rental agreement and a periodic residential rental*/}
                                        {/*                agreement;”<br/>*/}
                                        {/*                SDA means Specialist Disability Accommodation.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="list-item">*/}
                                        {/*        <b>Modifications (see clause 22)</b>*/}
                                        {/*        <div className="heading-secondary list w-100">*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you make any modification that does not require my consent you must notify me that you intend to*/}
                                        {/*                makethat modification along with a description of the modification at least 48 hours before making*/}
                                        {/*                the modification.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you intend to install non-permanent window film for insulation, reduced heat transfer or privacy or*/}
                                        {/*                install security lights, alarm systems or security cameras, I may require you to engage a suitably*/}
                                        {/*                qualified person to carry out the work.*/}
                                        {/*            </div>*/}
                                        {/*            <div className="list-item">*/}
                                        {/*                If you intend to replace curtains you must inform me of where and the manner in which you intend to*/}
                                        {/*                store the original curtains.*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </>
                                    }

                                    <Collapse
                                            className='special-condition-step__accordion'
                                            defaultActiveKey={[1]}
                                            onChange={(key) => {
                                                this.setState({activeTab: key[key.length - 1]})
                                            }}
                                            activeKey={activeTab}
                                    >
                                        {special_conditions.map(condition => {
                                            return (
                                                    <Panel
                                                            showArrow={false}
                                                            extra={
                                                                agreedConditions.includes(condition.id)
                                                                        ? <Icons.Agreed/>
                                                                        : <Icon
                                                                                type="down"
                                                                                rotate={activeTab === condition.id.toString() ? 0 : -90}
                                                                        />
                                                            }
                                                            header={condition.title}
                                                            key={condition.id}
                                                    >
                                                        <p className='heading-secondary'>{ReactHtmlParser(condition.clause)}</p>
                                                        <div className='special-condition-step__accordion__agree-button-container'>
                                                            {!agreedConditions.includes(condition.id) &&
                                                            <Button
                                                                    className='fullwidth t-uppercase app-basic-button_large'
                                                                    shape="round"
                                                                    type="danger"
                                                                    onClick={() => this.setAgreedCondition(condition.id)}
                                                            >
                                                                Agree
                                                            </Button>
                                                            }
                                                        </div>
                                                    </Panel>
                                            )
                                        })}
                                    </Collapse>
                                </div>
                        ),
                        footer: (
                                <div className='footer__buttons-container_one'>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="danger"
                                                loading={false}
                                                disabled={!this.checkAgreedAllCondition()}
                                                onClick={this.sign}
                                        >
                                            Sign Now
                                        </Button>
                                    </div>
                                </div>
                        )
                    }}
                </LeaseStepsContainer>
        )
    }
}
