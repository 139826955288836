import React from "react"
import {
    Button,
    message
} from 'antd'
import {inject} from 'mobx-react'

import {
    Checkbox,
    MainLayoutContentContainer,
    MainLayoutFooter
} from "../../../components"
import {SignatureModal} from "../components/SignatureModal/SignatureModal"
import * as Icons from "../../../components/icons"
//@ts-ignore
import fileNSW from '../../../resources/file/FT_TenantInformationStatement_FS_0320.pdf'
//@ts-ignore
import fileQLD from '../../../resources/file/Form-17a-Pocket-guide-for-tenants-houses-and-units.pdf'

import {
    Props,
    State,
    InjectedProps
} from "./types"
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {
    saveInformationStatementSignature, saveTenantInitial
} from "../../../networking/lease"
import {SIGNATURE_STEP} from "../../../routing/paths";

@inject('Lease')
export class InformationStatementStep extends ComponentWithInjectedProps<Props, State, InjectedProps>{

    state = {
        isVisibleSignatureModal: false,
        loading: false,
        isConfirm: false
    }

    private saveSignature = (signature: any) => {
        const {nextPath, history} = this.props
        const {Lease: {getCurrentUser, lease}} = this.injectedProps
        const state = lease.property.state;
        const signatureImg = signature.toDataURL()
        // history.push(nextPath)
        if(signature.isEmpty()){
            return message.warning( 'Sign please')
        }

        const missedSignature = localStorage.getItem('signature');
        if(state == "VIC" || state == "QLD"){
            this.setState({loading: true}, () => {
                saveTenantInitial(signatureImg, 'notes')
                .then((res: any) => {
                    if(res.status == 200) {
                        if(missedSignature != "" && missedSignature != null){
                            localStorage.setItem('signature', "");
                            history.push(SIGNATURE_STEP);
                        } else {
                            history.push(nextPath)
                        }
                    } else {
                        this.setState({loading: false})
                        message.error('Internal error occur, please try again')
                    }
                })
                .catch(() => this.setState({loading: false}))
            })
        } else {
            this.setState({loading: true}, () => {
                saveInformationStatementSignature(signatureImg)
                .then((res: any) => {
                    if(res.status == 200) {
                        if(missedSignature != "" && missedSignature != null){
                            localStorage.setItem('signature', "");
                            history.push(SIGNATURE_STEP);
                        } else {
                            history.push(nextPath)
                        }
                    } else {
                        this.setState({loading: false})
                        message.error('Internal error occur, please try again')
                    }
                })
                .catch(() => this.setState({loading: false}))
            })
        }
    }

    changeConfirm = () => {
        this.setState({isConfirm: !this.state.isConfirm})
    }

    componentDidMount(): void {
        const missedSignature = localStorage.getItem('signature');
        if(missedSignature != "" && missedSignature != null){
            message.warn('Something wrong with saving your signature on this step. Please sign again.')
        }
    }

    render(): React.ReactElement{
        const {isVisibleSignatureModal, loading, isConfirm} = this.state
        const {nextPath, history} = this.props
        const {Lease: {getCurrentUser, lease}} = this.injectedProps
        const state = lease.property.state;

        let file = fileNSW;
        if(state == "QLD") file = fileQLD;

        return (
                <>
                    <MainLayoutContentContainer>

                        <div className="special-condition-witness-step welcome-page__container "
                             style={{
                                 marginTop: 25,
                                 flex:'inherit',
                                 height: '100%',
                                 position: 'relative',
                                 padding: '15px 30px'
                             }}>
                            {(state == "NSW" || state == "QLD") &&
                            <div style={{alignItems: 'center',display: 'flex',flexDirection: 'column'}}>
                                <Icons.UserCard/>
                                <p className='heading-red__bold-secondary'>
                                    Tenant information statement
                                </p>
                                {state == "NSW" &&
                                <p className='heading-secondary text-center'>
                                    The tenant acknowledges that, at or before the time of signing this residential
                                    tenancy agreement,
                                    the tenant was given a copy of an information statement published by NSW Fair
                                    Trading.
                                </p>}
                                {/*{state == "QLD" &&*/}
                                {/*<p className='heading-secondary text-center'>*/}
                                {/*    The tenant acknowledges that, at or before the time of signing this tenancy*/}
                                {/*    agreement, the tenant was given a copy of an information statement published by the*/}
                                {/*    Residential Tenancy Authority.*/}
                                {/*</p>}*/}
                                <a href={file} download className='heading-blue'
                                   style={{fontSize: 16, textAlign: 'center'}}>
                                    Tenant Information Statement PDF
                                </a>

                                <p className='small-paragraph  text-center'>
                                    Note: a copy of the Tenant Information Statement has also been sent to your email
                                    address.
                                </p>
                            </div>
                            }


                            {state == "VIC" &&
                            <div style={{alignItems: 'center',display: 'flex',flexDirection: 'column'}}>
                                <Icons.Inclusion/>
                                <p className='heading-red__bold-secondary'>
                                    Residential rental agreement no more than 5 years.
                                </p>
                                <p className='heading-secondary' style={{width: "100%"}}>
                                    Residential Tenancies Act 1997 Section 26(1) <br/>
                                    Residential Tenancies Regulations 2021 Regulation 10(1)
                                </p>
                                <ul style={{padding: "25px 0 0"}}>
                                    <li className='heading-secondary'>This is your residential rental agreement.
                                        It is a binding contract under the <b>Residential Tenancies Act 1997</b> (the Act).</li>
                                    <li className='heading-secondary'>Parts A, B, C and E are the terms of your agreement.
                                        Part D is a summary of your rights and obligations.</li>
                                    <li className='heading-secondary'>Do not sign this agreement if there is anything in
                                        it that you do not understand.</li>
                                    <li className='heading-secondary'>Please refer to <a href="https://www.consumer.vic.gov.au/housing/renting/renting-guide">
                                            Renters Guide</a> for details about your rights and responsibility.</li>
                                    <li className='heading-secondary'>
                                        For further information, visit the renting section of the Consumer Affairs Victoria
                                        (CAV) website at <a href="http://www.consumer.vic.gov.au/renting">
                                        www.consumer.vic.gov.au/renting</a> or call 1300 558 181.
                                    </li>
                                </ul>
                            </div>
                            }


                            <div className='witness-step__confirmation-container'
                                 style={{width: '100%'}}>
                                <div>
                                    <Checkbox
                                      checked={isConfirm}
                                      onChange={this.changeConfirm}
                                    >
                                        {state == "VIC" &&
                                        <p className='small-paragraph mt-0'>
                                            I {getCurrentUser().first_name} {getCurrentUser().last_name} approve for my
                                            initials to be inserted on the bottom of each page of this agreement
                                        </p>
                                        }
                                        {state == "NSW" &&
                                        <p className='small-paragraph mt-0'>
                                            I {getCurrentUser().first_name} {getCurrentUser().last_name} acknowledge
                                            that I have received the tenancy information statement.
                                        </p>
                                        }
                                        {state == "QLD" &&
                                        <p className='small-paragraph mt-0'>
                                            I {getCurrentUser().first_name} {getCurrentUser().last_name} approve for
                                            my initials to be inserted on the bottom of each page of this agreement
                                        </p>
                                        }
                                    </Checkbox>
                                </div>
                            </div>
                            <SignatureModal
                                    title={'Sign'}
                                    loading={loading}
                                    isVisible={isVisibleSignatureModal}
                                    onCancel={() => this.setState({isVisibleSignatureModal: false})}
                                    onSave={this.saveSignature}
                            />
                        </div>
                    </MainLayoutContentContainer>
                    <MainLayoutFooter>
                        <div className='welcome-page__buttons-container'>
                            <Button
                              className='fullwidth t-uppercase app-basic-button_large'
                              shape="round"
                              type="danger"
                              loading={false}
                              onClick={() => this.setState({isVisibleSignatureModal: true})}
                              disabled={!isConfirm}
                            >
                                Agree
                            </Button>
                        </div>
                    </MainLayoutFooter>
                </>
        )
    }
}
