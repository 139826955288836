import React from "react"
import {inject} from "mobx-react"
import {Button} from "antd"

import {LeaseStepsContainer} from ".."
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import * as Icons from "../../../components/icons"

import {
    Props,
    InjectedProps
} from "./types"

@inject('Core', 'Lease')
export class ElectronicServicesStep extends ComponentWithInjectedProps<Props, any, InjectedProps>{

    render(): React.ReactElement{
        const {history, nextPath} = this.props
        const {Core, Lease: {lease: {land_lord, agency_manager, users, property:{state}}, getCurrentUser}} = this.injectedProps
        const user = getCurrentUser()
        return (
                <LeaseStepsContainer title={'Giving notices and other documents electronically'}>
                    {{
                        content: (
                                <div className="additional-terms__container">
                                    <Icons.UserCard/>
                                    <p className='heading-secondary' style={{marginTop: 25}}>
                                        Indicate below for each person whether the person provides express consent to
                                        any notice and any other document under section 223 of the Residential Tenancies
                                        Act 2010 being given or served on them by email.
                                    </p>
                                    <p className='heading-secondary' style={{marginTop: 25}}>
                                        The Electronic Transactions Act 2000 applies to notices and other documents you
                                        send or receive
                                        electronically.
                                    </p>
                                    <p className='small-paragraph'>
                                        [You should only consent to electronic service if you check your emails
                                        regularly. If there is more than one tenant on the agreement, all tenants should
                                        agree on a single email address for electronic service. This will help ensure
                                        co-tenants receive notices and other documents at the same time.]
                                    </p>
                                    <p className='heading-blue'>
                                        Does the landlord give express consent to the electronic service of notices and
                                        documents?
                                    </p>
                                    <p className='heading-secondary'>
                                        {land_lord.electronic_notice ? 'YES' : 'NO'}
                                    </p>

                                    {state == "NSW" &&
                                    <p className='small-paragraph'>
                                        If yes, see clause 50.
                                    </p>
                                    }

                                    {land_lord.electronic_notice ?
                                            <p className='small-paragraph'>
                                                {agency_manager.email}
                                            </p> :
                                            null
                                    }

                                    <p className='heading-blue'>
                                        Does the tenant give express consent to the electronic service of notices and
                                        documents?
                                    </p>
                                    <p className='heading-secondary'>
                                        {user.current_location.electronic_notice ? 'YES' : 'NO'}
                                    </p>

                                    {state == "NSW" &&
                                    <p className='small-paragraph'>
                                        If yes, see clause 50.
                                    </p>
                                    }

                                    {user.current_location.electronic_notice ?
                                            <div >
                                                {users.map(user => {
                                                    return (
                                                        <p key={user.id} className='small-paragraph text-center'>
                                                            {user.primary_email != null ? user.primary_email.email : ""}
                                                        </p>
                                                        )
                                                })}
                                            </div> :
                                            null
                                    }
                                </div>
                        ),
                        footer: (
                                <div className='footer__buttons-container_two'>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="default"
                                                loading={false}
                                                onClick={() => Core.toggleCallDialog()}
                                        >
                                            Disagree
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="danger"
                                                loading={false}
                                                onClick={() => history.push(nextPath)}
                                        >
                                            Agree
                                        </Button>
                                    </div>
                                </div>
                        )
                    }}
                </LeaseStepsContainer>
        )
    }
}
