import React from "react"

import {Props} from './types'

export function MainLayoutFooter(props: Props): React.ReactElement {
    const {children} = props
    return (
        <div className='main-layout__footer'>
            {children}
        </div>
    )
}
