import React from "react"

export function ImportantInformationStepContent(): React.ReactElement{
    return (
            <ol style={{counterReset: 'item 0'}} className="nested-list heading-secondary">
                <li>
                    This form is your written record of your tenancy agreement. This is a binding contract under the
                    Residential Tenancies Act 2010, so please read all terms and conditions carefully.
                </li>
                <li>
                    If you need advice or information on your rights and responsibilities, please call NSW Fair Trading
                    on <a href="tel:133220">13 32 20</a> or visit <a href="https://www.fairtrading.nsw.gov.au"
                                                                     target='_blank'
                                                                     rel="noopener noreferrer"
                >
                    www.fairtrading.nsw.gov.au
                </a> before
                    signing the Agreement.
                </li>
                <li>
                    If you require extra space to list additional items and terms, attach a separate sheet. All
                    attachments
                    should be signed and dated by both the landlord or the landlord’s agent and the tenant to show that
                    both parties have read and agree to the attachments.
                </li>
                <li>
                    The landlord or the landlord’s agent must give the tenant a copy of the signed Agreement and any
                    attachments, two copies or one electronic copy of the completed condition report and a copy of NSW
                    Fair Trading’s Tenant Information Statement publication.
                </li>
            </ol>
    )
}
