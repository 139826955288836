import {observable, action, computed} from 'mobx'

import {ICore} from "./types"

export class Core implements ICore{
    @observable isVisibleCallDialog = false

    @computed
    get VisibleCallDialog(){
        return this.isVisibleCallDialog
    }

    @action
    toggleCallDialog() {
        this.isVisibleCallDialog = !this.isVisibleCallDialog
    }
}