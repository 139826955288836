import React from "react"

export function NoteStepContent(): React.ReactElement {
    return (
        <>
            <h4 className='heading-blue text-left w-100'>
                Landlord
            </h4>
            <p className='heading-secondary'>
                Means the person who grants the right to occupy residential
                premises under this agreement,
                and includes a successor in title to the residential premises who's interest is subject to that of the
                tenant
            </p>
            <h4 className='heading-blue text-left w-100'>
                Landlords agent
            </h4>
            <div className='heading-secondary'>
                <p>
                    Means a person who acts as the agent of the landlord and who (whether or not the person carries on
                    any
                    other business) carries on business as an agent for:
                </p>
                <p>
                    (a) the letting of residential premises, or
                </p>

                <p>
                    (b) the collection of rents payable for any tenancy of residential premises.
                </p>

            </div>
            <h4 className='heading-blue text-left w-100'>
                LFAI Register
            </h4>
            <p className='heading-secondary'>
                Means the register of residential premises that contain or have contained loose-fill asbestos
                insulation that is required to be maintained under Division 1A of Part 8 of the Home Building Act 1989
            </p>
            <h4 className='heading-blue text-left w-100'>
                Rental bond
            </h4>
            <p className='heading-secondary text-left w-100'>
                Means money paid by the tenant as security to carry out this agreement.
            </p>
            <h4 className='heading-blue text-left w-100'>
                Residential premises
            </h4>
            <p className='heading-secondary text-left w-100'>
                Means any premises or part of premises (including any land occupied with the premises)
                used or intended to be used as a place of residence.
            </p>
            <h4 className='heading-blue text-left w-100'>
                Tenancy
            </h4>
            <p className='heading-secondary text-left w-100'>
                Means the right to occupy residential premises under this agreement.
            </p>
        </>
    )
}