import React from 'react'

import {MainLayoutContentContainer} from "../../../components"
import * as Icons from "../../../components/icons"


export function  ConnectionCallBackStep(): React.ReactElement {

        return (
            <>
                <MainLayoutContentContainer>
                    <div className='congratulation-page__container'>
                        <Icons.LogoWithText/>
                        <h3 className='heading-red__book' style={{fontSize:16, marginTop:0, marginLeft:20}}>Connect</h3>
                        <div style={{marginTop:20, marginBottom:20}}>
                            <Icons.ConnectionUser/>
                        </div>
                        <p className='heading-secondary'>
                            Our movologist will be in contact with you shortly to discuss the best plan for you.
                        </p>
                    </div>
                </MainLayoutContentContainer>
            </>
        )
}

