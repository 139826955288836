import moment from 'moment'

export function formatDate(date: string, format: string = 'DD/MM/YYYY'): string {
    return moment(date).format(format)
}

export function ordinal(n: number) {
    const s = ["th", "st", "nd", "rd"];
    const v = n%100;
    return n + (s[(v-20)%10] || s[v] || s[0]);
}
