import axios, {AxiosPromise} from 'axios'

declare const SYSTEM_API_URL: string

export function setJwtToken(token: string): void {
    axios.defaults.headers["Authorization"] = 'Bearer ' + token
}

export function getTermsConditions(): AxiosPromise {
    return axios.get(`${SYSTEM_API_URL}/terms-conditions`)
}
