export interface Rent {
  day: string;
  place_of_rental: string;
  bond_payment_due: string;
  "id": string
  "lease_id": string
  "frequency": string
  "rate": number
  "bond": number
  held: boolean
  "start_on": string
  provided_to: 'landlord' | 'agent' | 'fair'
  "created_at": string
  "updated_at": string
}

export const PROVIDED_TO: {[key: string]: string} = {
  landlord: 'The landlord or another person',
  agent: 'The landlord\'s agent',
  fair: 'NSW fair trading through rental bonds online'
}
