import React from "react"

import {Props} from './types'

export function MainLayoutContentContainer(props:Props): React.ReactElement {
    const {children} = props
    return (
        <div className='main-layout__content-container'>
            {children}
        </div>
    )
}
