import React from "react"
import {inject} from "mobx-react"
import {Button} from 'antd'

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"
//@ts-ignore
import filePet from '../../../resources/file/Form-21-Request-for-approval-to-keep-a-pet-in-rental-property.pdf'

import {
    Props,
    InjectedProps
} from "./types"
import {
    ADDITIONAL_TERM_ALIAS
} from "../../../types/AdditionalTerms"

import './styles.scss'
import {toJS} from "mobx";

@inject('Core', 'Lease')
export class WaterUsegesStep extends ComponentWithInjectedProps<Props, any, InjectedProps>{

    render(): React.ReactElement{
        const {history, nextPath} = this.props
        const {Core, Lease: {getValueFromAdditionalTerms, lease: {property: {state}}}} = this.injectedProps
        const pay_for_water = getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.pay_for_water)
        const residential_premises = getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.residential_premises)
        const gas_supplied = getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.gas_supplied)
        const electricity_supplied = getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.electricity_supplied)
        const {Lease: {currentLease: {additional_terms}}} = this.injectedProps

        let content = (
          <div className="additional-terms__container">
              <Icons.WebChecklist/>
              <h4 className='heading-blue'>Will the tenant be required to pay separately for water
                  usage?
              </h4>
              <p className='heading-secondary'>
                  {pay_for_water ? 'YES' : 'NO'}
              </p>
              <p className='small-paragraph'>If yes see clause 12, 13</p>

              <h4 className='heading-blue'>
                  Is electricity supplied to the premises from an embedded network?
              </h4>
              <p className='heading-secondary'>
                  {electricity_supplied ? 'YES' : 'NO'}
              </p>

              <h4 className='heading-blue'>
                  Is gas supplied to the premises from an embedded network?
              </h4>
              <p className='heading-secondary'>
                  {gas_supplied ? 'YES' : 'NO'}
              </p>

              <h4 className='heading-blue'>Are there any strata or community scheme by-laws
                  applicable to
                  the residential premises?
              </h4>
              <p className='heading-secondary'>
                  {residential_premises ? 'YES' : 'NO'}
              </p>
              <p className='small-paragraph'>If yes see clause 38, 39</p>
          </div>
        );


        if(state == "QLD"){
            const {Lease: {currentLease: {additional_terms}}} = this.injectedProps
            const QLDContent: any = [];

            const withPercentage = [
              "gas",
              "phone",
              "water",
              "electricity",
              'any_other'
            ];
            const withType = ["any_other"];
            const withPets = ["pets_allowed"];

            additional_terms.map(function (item, index) {
                const metaData: [] | null = toJS(item.meta_data);
                let percent = null;
                let descr = null;
                let type = null;

                // console.log(item);


                if(!!item.value && (withType.indexOf(item.alias) > -1) && metaData){
                  metaData.map(function (item, index) {
                    if(index == 2) type = item
                  })
                }

                if(!!item.value && (withPercentage.indexOf(item.alias) > -1) && metaData){
                  metaData.map(function (item, index) {
                    if(index == 0) percent = item + '%'
                    if(index == 1) descr = item
                  })
                }



                QLDContent.push(
                  <div key={index} style={{textAlign: 'center'}}>
                    <h4 className='heading-blue'>{item.title}</h4>
                    <p className='heading-secondary'>
                      {!!item.value && (withPets.indexOf(item.alias)) > -1 ? "YES - PENDING APPROVAL" : (!!item.value ? 'YES' : 'NO')}
                    </p>

                    {(!!item.value && (withType.indexOf(item.alias) > -1)) &&
                        <p className=''>
                          {type}
                        </p>
                    }

                    {(!!item.value && (withPercentage.indexOf(item.alias) > -1)) &&<>
                    <p className='blue'>
                        If the premises is not individually metred for this service, apportionment of the cost of
                        the service for which the tenant must pay
                    </p>
                    <p className=''>{percent}</p>
                    <p className=''>{descr}</p>
                    </>}

                  </div>
                )
                  
                  if(!!item.value && (withPets.indexOf(item.alias) > -1) && metaData){
                    console.log(item)
                    metaData.map(function (item: any, index) {
                      QLDContent.push(
                        <div key={'pets'+index} style={{textAlign: 'center', padding: "15px 0"}}>
                          <p>Type: {item.type}</p>
                          <p>Breed: {item.breed}</p>
                          <p>Number: {item.number}</p>
                          <p>Age: {item.age}</p>
                        </div>
                      )
                    })
                    QLDContent.push(
                      <div>Note: A copy of the Pet Approval Form was attached to your Tenancy Agreement email.
                      <a href={filePet} download className='heading-blue'
                                   style={{fontSize: 16, textAlign: 'center'}}>
                                    Or download here.
                                </a>
                      </div>
                    )
                  }else{
                    if(item.alias == "pets_allowed" && metaData){
                      metaData.map(function (item: any, index) {
                        QLDContent.push(
                          <div key={'pets'+index} style={{textAlign: 'center', padding: "15px 0"}}>
                            <p>Reason: {item.type}</p>
                          </div>
                        )
                      })
                    }
                  }
            })

            content = (
              <div className="additional-terms__container">
                  <Icons.WebChecklist/>
                  {QLDContent}
              </div>
            );
        }

        return (
                <LeaseStepsContainer title={'Utilities and Strata by-laws'}>
                    {{
                        content: content,
                        footer: (
                                <div className='footer__buttons-container_two'>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="default"
                                                loading={false}
                                                onClick={() => Core.toggleCallDialog()}
                                        >
                                            Disagree
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="danger"
                                                loading={false}
                                                onClick={() => history.push(nextPath)}
                                        >
                                            Agree
                                        </Button>
                                    </div>
                                </div>
                        )
                    }}
                </LeaseStepsContainer>
        )
    }
}
