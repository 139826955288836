import React, {Component} from "react"

import {MainLayoutContentContainer, MainLayoutFooter} from "../../../components"
import {LeaseSteppers} from "../components"

import {Props} from "./types"

import './styles.scss'

export class LeaseStepsContainer extends Component<Props, any> {

    render(): React.ReactElement {
        const {title, children, background = true, right_side_text = ''} = this.props
        return (
            <>
                <MainLayoutContentContainer>
                    <div className='lease-steps__container'>
                        <div className='lease-steps__steps-container'>
                            <LeaseSteppers/>
                        </div>
                        <div className='lease-steps__heading-container'>
                            <h4 className='lease-steps__heading'>{title}</h4>
                            <span className='lease-steps__heading_right'>{right_side_text}</span>
                        </div>
                        <div className='lease-steps__content-container'>
                            <div
                                className={`lease-steps__content ${!background ? 'without-background' : ''}`}>{children.content}</div>
                        </div>
                    </div>
                </MainLayoutContentContainer>
                <MainLayoutFooter>
                    {children.footer}
                </MainLayoutFooter>
            </>
        )
    }
}
