import React from "react"
import {inject} from "mobx-react"
import {
    Button,
    message
} from 'antd'
import TextField from "@material-ui/core/TextField"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {LeaseStepsContainer} from ".."
import {
    Checkbox,
    BaseModal
} from "../../../components"
import {SignatureModal} from "../components/SignatureModal/SignatureModal"
import * as Icons from "../../../components/icons"

import {
    Props,
    InjectedProps,
    State
} from "./types"
import {User} from '../../../types/User'

import {saveWitnessSignature} from "../../../networking/lease"

import './styles.scss'

@inject('Lease')
export class WitnessStep extends ComponentWithInjectedProps<Props, State, InjectedProps>{

    state = {
        isVisibleModal: true,
        isVisibleSignatureModal: false,
        first_name: '',
        last_name: '',
        isConfirm: false,
        loading: false
    }

    componentDidMount(): void{
        const currentUser = this.getCurrentUser()
        if(currentUser.witness){
            this.setState({first_name: currentUser.witness.first_name, last_name: currentUser.witness.last_name})
        }
    }

    private onChange = (name: string, value: string): void => {
        //@ts-ignore
        this.setState({[name]: value.trimStart()})
    }

    private changeConfirm = (): void => {
        this.setState({isConfirm: !this.state.isConfirm})
    }

    private saveWitness = (signature: any) => {
        const {last_name, first_name} = this.state
        const {nextPath, history} = this.props
        const {Lease} = this.injectedProps
        const signatureImg = signature.toDataURL()
        if(signature.isEmpty()){
            return message.warning({
                content: 'Sign please'
            })
        }
        this.setState({loading: true}, () => {
            saveWitnessSignature({first_name, last_name, signature: signatureImg})
            .then(res => {
                if(res.status === 200) {
                    this.setState({loading: false})
                    Lease.setWitness(res.data)
                    history.push(nextPath)
                } else {
                    this.setState({loading: false})
                    message.error('Internal error occur, please try again')
                }
            })
            .catch(err => this.setState({loading: false}))
        })
    }

    private getCurrentUser = (): User => {
        const {Lease: {lease: {users}}} = this.injectedProps
        return users.reduce((prev, user) => {
            if(user.me){
                prev = user
            }
            return prev
        })
    }

    render(): React.ReactElement{
        const {Lease} = this.injectedProps
        const {isVisibleModal, isVisibleSignatureModal, first_name, last_name, isConfirm, loading} = this.state
        const currentUser = Lease.getCurrentUser()
        return (
                <LeaseStepsContainer title={'Witness'}>
                    {{
                        content: (
                                <div className='witness-step__container'>
                                    <Icons.UserCard/>
                                    <h4 className='heading-blue'>HI, what's your name</h4>
                                    <TextField
                                            // error={errors.has('salutation')}
                                            // helperText={errors.first('salutation')}
                                            label="First name"
                                            fullWidth
                                            margin="normal"
                                            value={first_name}
                                            onChange={(e) => this.onChange('first_name', e.target.value)}
                                    />
                                    <TextField
                                            // error={errors.has('salutation')}
                                            // helperText={errors.first('salutation')}
                                            label="Last name"
                                            fullWidth
                                            margin="normal"
                                            value={last_name}
                                            onChange={(e) => this.onChange('last_name', e.target.value)}
                                    />
                                    <div className='witness-step__confirmation-container'>
                                        <div>
                                            <Checkbox checked={isConfirm} onChange={this.changeConfirm}>
                                                <p className='small-paragraph mt-0'>
                                                    I confirm I am present in the same location
                                                    as {currentUser.first_name} {currentUser.last_name} and
                                                    I have witnessed them sign the Residential Tenancy Agreement
                                                </p>
                                            </Checkbox>
                                        </div>
                                    </div>
                                    <h4 className='heading-blue'>Address</h4>
                                    <p className='heading-red__bold-primary'>
                                        {`${currentUser.current_location.meta_data ? currentUser.current_location.meta_data.post_code : ''} ${currentUser.current_location.meta_data ? currentUser.current_location.meta_data.city : ''}`}
                                    </p>
                                    <BaseModal visible={isVisibleModal}>
                                        <p className='heading-red__bold-secondary'>IMPORTANT</p>
                                        <p className='heading-secondary text-center'>The witness CANNOT be your or
                                            another
                                            tenants named on this lease</p>
                                        <div className='witness-step__modal__button-container'>
                                            <Button
                                                    className=' t-uppercase app-basic-button_large'
                                                    shape="round"
                                                    type="danger"
                                                    loading={false}
                                                    onClick={() => this.setState({isVisibleModal: false})}
                                            >
                                                Agree
                                            </Button>
                                        </div>
                                    </BaseModal>
                                    <SignatureModal
                                            title={'Sign here'}
                                            loading={loading}
                                            isVisible={isVisibleSignatureModal}
                                            onCancel={() => this.setState({isVisibleSignatureModal: false})}
                                            onSave={this.saveWitness}
                                    />
                                </div>
                        ),
                        footer: (
                                <div className='footer__buttons-container_two'>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="default"
                                                loading={false}
                                                // onClick={() => history.push('/location')}
                                        >
                                            Disagree
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="danger"
                                                loading={false}
                                                disabled={!first_name || !last_name || !isConfirm}
                                                onClick={() => this.setState({isVisibleSignatureModal: true})}
                                        >
                                            Sign now
                                        </Button>
                                    </div>
                                </div>
                        )
                    }}
                </LeaseStepsContainer>
        )
    }
}
