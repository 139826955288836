import React from "react"
import {inject} from "mobx-react"
import {
  Button,
  message
} from 'antd'

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons"


import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {
  Props,
  InjectedProps,
  State
} from "./types"

import './styles.scss'

@inject('Core', 'Lease')
export default class VICDPart extends ComponentWithInjectedProps<Props, State, InjectedProps> {

  state = {
    isConfirm: false,
    loading: false,
    isVisibleSignatureModal: false
  }

  render(): React.ReactElement {
    const {isConfirm, loading, isVisibleSignatureModal} = this.state
    const {Core, Lease: {getCurrentUser, lease: {property: {state}}}, Lease} = this.injectedProps
    const user = getCurrentUser()
    const {nextPath, history} = this.props
    return (
      <LeaseStepsContainer title={'Part D – Rights and Obligations'}>
        {{
          content: (
            <div className="general-agreement-step__container">
              <Icons.WebChecklist/>

              <p className="heading-secondary">This is a summary of selected rights and obligations of renters and rental
                providers under the Act. Any reference to VCAT refers to the Victorian Civil and Administrative Tribunal.<br/>
                For more information, visit consumer.vic.gov.au/renting.</p>

              <h4 className='heading-blue text-left w-100'>
                Use of the Premises
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The renter—</p>
              <ul className="heading-secondary">
                <li>is entitled to quiet enjoyment of the premises. The rental provider may only enter the premises in
                  accordance with the Act; and
                </li>
                <li>must not use the premises for illegal purposes; and</li>
                <li>must not cause a nuisance or interfere with the reasonable peace, comfort or privacy of neighbours; and</li>
                <li>must avoid damaging the premises and common areas. Common areas include hallways, driveways, gardens and
                  stairwells. Where damage occurs, the renter must notify the rental provider in writing; and
                </li>
                <li>must keep the premises reasonably clean.</li>
              </ul>

              <h4 className='heading-blue text-left w-100'>
                Condition of the Premises
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The rental provider —</p>
              <ul className="heading-secondary">
                <li>must ensure that the premises comply with the rental minimum standards, and is vacant and reasonably
                  clean when the renter moves in; and
                </li>
                <li>must maintain the premises in good repair and in a fit condition for occupation; and</li>
                <li>agrees to do all the safety-related maintenance and repair activities set out in Part C of the Agreement.
                </li>
              </ul>
              <p style={{width: "100%"}} className="heading-secondary">
                The renter must follow all safety-related activities set out in Part C of the agreement and not remove,
                deactivate or otherwise interfere with the operation of prescribed safety devices on the premises.
              </p>

              <h4 className='heading-blue text-left w-100'>
                Modifications
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The renter—</p>
              <ul className="heading-secondary">
                <li>may make some modifications without seeking the rental provider's consent. These modifications are listed
                  on the Consumer Affairs Victoria website; and
                </li>
                <li>must seek the rental provider's consent before installing any other fixtures or additions; and</li>
                <li>may apply to VCAT if they believe that the rental provider has unreasonably refused consent for a
                  modification mentioned in the Act; and
                </li>
                <li>at the end of the agreement, must restore the premises to the condition it was in before they moved in
                  (excluding fair wear and tear). This includes removing all modifications, unless the parties agree they do
                  not need to be removed.
                </li>
              </ul>
              <p style={{width: "100%"}} className="heading-secondary">
                The rental provider must not unreasonably refuse consent for certain modifications.<br/>
                A list of the modifications that the rental provider cannot unreasonably refuse consent for is available on
                the Consumer Affairs Victoria website consumer.vic.gov.au/renting.
              </p>

              <h4 className='heading-blue text-left w-100'>
                Locks
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The rental provider must ensure the premises—</p>
              <ul style={{width: "100%"}} className="heading-secondary">
                <li>has locks to secure all windows capable of having a lock; andhas deadlocks (a deadlock is a deadlatch with
                  at least one cylinder) for external doors that are able to be secured with a
                </li>
                <li>functioning deadlock; and</li>
                <li>meets the rental minimum standards for locks and window locks.</li>
              </ul>
              <p style={{width: "100%"}} className="heading-secondary">
                External doors which are not able to be secured with a functioning deadlock must at least be fitted with
                a locking device that—
              </p>
              <ul style={{width: "100%"}} className="heading-secondary">
                <li>is operated by a key from the outside; and</li>
                <li>may be unlocked from the inside with or without a key.</li>
              </ul>
              <p style={{width: "100%"}} className="heading-secondary">
                The renter must obtain consent from the rental provider to change a lock in the master key system.<br/>
                The rental provider must not unreasonably refuse consent for a renter seeking to change a lock in the master
                key system.The rental provider must not give a key to a person excluded from the premises under—
              </p>
              <ul style={{width: "100%"}} className="heading-secondary">
                <li>a family violence intervention order; or</li>
                <li>a family violence safety notice; or</li>
                <li>a recognised non-local DVO; or</li>
                <li>a personal safety intervention order.</li>
              </ul>

              <h4 className='heading-blue text-left w-100'>
                Repairs
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">
                Only a suitably qualified person may do repairs—both urgent and non-urgent.
              </p>

              <h4 className='heading-blue text-left w-100'>
                Urgent Repairs
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">Section 3(1) of the Act defines urgent repairs. Refer
                to the Consumer Affairs Victoria website for the full list of urgent repairs and for more information,
                visit consumer.vic.gov.au/urgentrepairs.<br/>
                Urgent repairs include failure or breakdown of any essential service or appliance provided for hot water,
                cooking, heating or laundering supplied by the rental provider.<br/>
                The rental provider must carry out urgent repairs after being notified. A renter may arrange for urgent repairs
                to be done if the renter has taken reasonable steps to arrange for the rental provider to immediately do the
                repairs and the rental provider has not carried out the repairs.<br/>
                If the renter has arranged for urgent repairs, the renter may be reimbursed directly by the rental provider
                for the reasonable cost of repairs up to $2500.</p>
              <p style={{width: "100%"}} className="heading-secondary">The renter may apply to VCAT for an order requiring the
                rental provider to carry out urgent repairs if—</p>
              <div style={{width: "100%"}} className="heading-secondary list alpha">
                <div className="list-item">
                  the renter cannot meet the cost of the repairs; or
                </div>
                <div className="list-item">
                  the cost of repairs is more than $2500; or
                </div>
                <div className="list-item">
                  the rental provider refuses to pay the cost of repairs if it is carried out by the renter.
                </div>
              </div>

              <h4 className='heading-blue text-left w-100'>
                Non-urgent Repairs
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The renter must notify the rental provider, in writing,
                as soon as practicable of—</p>
              <ul style={{width: "100%"}} className="heading-secondary">
                <li>damage to the premises; and</li>
                <li>a breakdown of facilities, fixtures, furniture or equipment supplied by the rental provider.</li>
              </ul>
              <p style={{width: "100%"}} className="heading-secondary">The rental provider must carry out non-urgent repairs in
                a reasonable time.<br/>
                The renter may apply to VCAT for an order requiring the rental provider to do the repairs if the rental
                provider has not carried out the repairs within 14 days of receiving notice of the need for repair.</p>

              <h4 className='heading-blue text-left w-100'>
                Assignment or Sub-letting
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The renter must not assign (transfer to another person)
                or sub-let the whole or any part of the premises without the written consent of the rental provider.
                The rental provider may give the renter notice to vacate if the renter assigns or sub- lets the premises
                without consent.<br/>
                The rental provider—</p>
              <ul style={{width: "100%"}} className="heading-secondary">
                <li>cannot unreasonably withhold consent to assign or sub-let the premises; and</li>
                <li>must not demand or receive a fee or payment for consent, other than any reasonable expenses incurred by
                  the assignment.
                </li>
              </ul>

              <h4 className='heading-blue text-left w-100'>
                Rent
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The rental provider must give the renter at least 60
                days written notice of a proposed rent increase.<br/>
                The rent cannot be increased more than once every 12 months.<br/>
                The rental provider must not increase the rent under a fixed term agreement unless the agreement provides
                for an increase by specifying the amount of increase or the method of calculating the rent increase.</p>

              <h4 className='heading-blue text-left w-100'>
                Access and Entry
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The rental provider may enter the premises—</p>
              <ul style={{width: "100%"}} className="heading-secondary">
                <li>at any time, if the renter has agreed within the last 7 days; and</li>
                <li>to do an inspection, but not more than once every 6 months; and</li>
                <li>to comply with the rental provider's duties under the Act; and</li>
                <li>to show the premises or conduct an open inspection to sell, rent or value the premises; and</li>
                <li>to take images or video for advertising a property that is for sale or rent; and</li>
                <li>if they believe the renter has failed to follow their duties under the Act; and</li>
                <li>to do a pre-termination inspection where the renter has applied to have the agreement terminated because
                  of family violence or personal violence.
                </li>
              </ul>
              <p style={{width: "100%"}} className="heading-secondary">The renter must allow entry to the premises where the
                rental provider has followed proper procedure. The renter is entitled to a set amount of compensation for each
                sales inspection.</p>

              <h4 className='heading-blue text-left w-100'>
                Pets
              </h4>
              <p style={{width: "100%"}} className="heading-secondary">The renter must seek consent from the rental provider
                before keeping a pet on the premises. The rental provider must not unreasonably refuse a request to
                keep a pet.</p>

            </div>
          ),
          footer: (
            <div className='footer__buttons-container_two'>
              <div>
                <Button
                  className='fullwidth t-uppercase app-basic-button_large'
                  shape="round"
                  type="default"
                  loading={false}
                  onClick={() => Core.toggleCallDialog()}
                >
                  Disagree
                </Button>
              </div>
              <div>
                <Button
                  className='fullwidth t-uppercase app-basic-button_large'
                  shape="round"
                  type="danger"
                  loading={false}
                  onClick={() => history.push(nextPath)}
                  // onClick={() => this.setState({isVisibleSignatureModal: true})}
                >
                  Agree
                </Button>
              </div>
            </div>
          )
        }}
      </LeaseStepsContainer>
    )
  }
}
