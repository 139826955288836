import {Lease} from "./Lease"
import {Core} from "./Core/core"
import {StaticPage} from "./StaticPages"

class Store {
    Lease: any
    Core: any
    StaticPage: any

    constructor() {
        this.Lease = new Lease()
        this.Core = new Core()
        this.StaticPage = new StaticPage()
    }

}

export default new Store()
