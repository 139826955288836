import React from "react"
import {inject} from "mobx-react"
import {Button} from 'antd'

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {Props, InjectedProps} from "./types"

import './styles.scss'

@inject('Core')
export class ConditionReportTenancyLawsStep extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    render(): React.ReactElement {
        const {history, nextPath} = this.props
        const {Core} = this.injectedProps
        return (
            <LeaseStepsContainer title={'Condition report and tenancy laws'}>
                {{
                    content: (
                        <div className="additional-terms__container">
                            <Icons.WebChecklist/>
                            <h4 className='heading-blue'>Condition Report Statement </h4>
                            <p className='heading-secondary'>A condition report relating to condition of the premises
                                must be completed and provided prior to the start of this agreement</p>
                            <h4 className='heading-blue'>Tenancy Law Statement</h4>
                            <p className='heading-secondary'>Tenancy law - The Residential Tenancy Act 2010 and The
                                Residential Tenancies Regulation 2019 apply to this agreement. Both the landlord and the
                                tenant must comply with these laws</p>
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_two'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    onClick={() => history.push(nextPath)}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
