import React from 'react'

import {MainLayoutContentContainer, MainLayoutFooter} from "../../../components"
import * as Icons from "../../../components/icons"

import {InjectedProps, Props} from "./types"

import './styles.scss'
import {Button, message} from "antd";
import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps";
import {proceedConnections} from "../../../networking/lease";
import {inject, observer} from "mobx-react";
import {getItem, removeItem} from "../../../helpers/storage";

import image from "../../../resources/images/svg/congratulation-2.svg";
import congratulationImage from "../../../resources/images/svg/congratulation.svg";

@inject('Lease')
@observer
export class CongratulationStep extends ComponentWithInjectedProps<Props, any, InjectedProps> {

    state = {
        callbackLoading: false,
        planLoading: false,
        sendingRequest: false,
        showConnections: true
    };

    toggleSendingRequest() {
        this.setState({
            sendingRequest: !this.state.sendingRequest
        })
    }

    toggleLoading(type: string, state: boolean): void {
        this.setState({
            [type === 'callback' ? 'callbackLoading' : 'planLoading']: state
        })
    }

    onClick(type: string): void {
        if (this.state.sendingRequest) return;
        this.handleRequest(type)
        this.toggleSendingRequest()
    };

    handleRequest(type: string): void {
        this.toggleLoading(type, true);
        // @ts-ignore
        const lease_id: string = getItem('current_lease_id');
        // @ts-ignore
        const code: string = getItem('link_code');
        proceedConnections(type, lease_id, code)
            .then((response) => this.onRequestSucceed(response))
            .catch((error) => this.onRequestFailed(error))
            .finally(() => {
                this.toggleLoading(type, false);
                this.toggleSendingRequest()
            })
    }

    onRequestSucceed({data: {data}}: any): void {
        data.url ? this.redirectToConnections(data.url) : this.showSuccessCallbackNotification();
        //@ts-ignore
        removeItem('token') || removeItem('link_code') || removeItem('current_lease_id')
    }

    onRequestFailed(e: any): void {
        console.error(e)
        message.error('An error occur, please try again')
    }

    redirectToConnections(url: string): void {
        message.success('You will be redirected to connections app in 3 seconds...')
        setTimeout(() => window.location.href = url, 3000)
    };

    showSuccessCallbackNotification(): void {
       this.props.history.replace(this.props.nextPath)
    }

    isShowConnection(): boolean {
        const {is_primary_tenant, has_connection} = this.injectedProps.Lease.currentLease
        return has_connection
            && is_primary_tenant
            && getItem('current_lease_id') !== null
            && getItem('link_code') !== null
    }

    render(): React.ReactElement {
        const {callbackLoading, planLoading, showConnections} = this.state

        return (
            <>
                <MainLayoutContentContainer>
                    <div className='congratulation-page__container'>
                        <h3 className='heading-red__bold-secondary' style={{marginBottom:0}}>Congratulations your lease is signed and
                            complete</h3>
                        {(!this.isShowConnection() || !showConnections) &&
                            <img style={{marginTop: "25px"}} className="image" src={congratulationImage}/>
                        }
                        <p className='heading-secondary'>
                            A PDF copy will be emailed to you shortly
                        </p>
                        {(this.isShowConnection() && showConnections) &&
                            (
                                <>
                                    <b className='heading-secondary' style={{marginTop: "5px"}}>
                                        Connect your electricity and other utilities now to ensure they’re sorted before
                                        you move in
                                    </b>
                                    <div style={{marginTop:20, marginBottom:0}}>
                                        <img className="image" src={image}/>
                                    </div>
                                    <p className='heading-secondary'>
                                        Click <b>Get Connected</b> to choose from the best plans available or <b>I Prefer a Call Back</b> and one of our connection specialists will be in touch
                                    </p>
                                </>
                        )
                        }
                    </div>
                </MainLayoutContentContainer>
                {(this.isShowConnection() && showConnections ) &&
                <MainLayoutFooter>
                    <div className='footer__buttons-container_two connection_footer'>
                      <div className="btn-wrapper">
                        <Button
                          className='fullwidth t-uppercase app-basic-button_large connected-btn'
                          shape="round"
                          type="danger"
                          loading={planLoading}
                          onClick={() => this.onClick('chose_plan')}
                        >
                            GET CONNECTED
                        </Button>
                      </div>
                        <div className="btn-wrapper">
                            <Button
                              style={{backgroundColor: "#545454", borderColor: "#545454"}}
                              className='fullwidth t-uppercase app-basic-button_large'
                              shape="round"
                              type="danger"
                              loading={callbackLoading}
                              onClick={() => this.onClick('callback')}
                            >
                                I Prefer Call back
                            </Button>
                        </div>
                        <div className="btn-wrapper">
                            <Button
                              style={{color: "#343F4D"}}
                              className='fullwidth app-basic-button_large'
                              shape="round"
                              type="link"
                              loading={callbackLoading}
                              onClick={() => this.setState({showConnections: false})}
                            >
                                No Thanks
                            </Button>
                        </div>
                    </div>
                </MainLayoutFooter>
                }
            </>
        )
    }
}
