export interface PaymentMethod {
  context: {[key: string]: string}
  created_at: string
  id: number
  is_active: boolean
  lease_id: string
  method: string
  updated_at: string
}

export const PAYMENT_METHODS_ALIAS: {[key: string]: string} = {
  account_name: 'Account name',
  account_number: 'Account number',
  bsb: 'BSB',
  reference: 'Reference',
  biller_code: 'Biller code',
  reference_code: 'Reference code',
  comment: 'Comment',
  payable_to: 'Payable to',
  details: 'Details',
  place_of_rental: "Place of rent payment"
}
