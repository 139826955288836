import React, {Component} from 'react'
import {routes} from "../../../routing/routes";
import {Route as CustomRoutes} from "../../../routing/types";
import {Redirect, Route, Switch} from "react-router";
import {getItem} from "../../../helpers/storage";
import {
  INVALID_PAGE,
  SMOKE_ALARMS_STEP,
  PROPERTY_DETAILS_LEASE_STEP,
  OCCUPANCY_DETAILS_LEASE_STEP,
  INCLUSION_DETAILS_LEASE_STEP,
  NOTES_STEP,
  GENERAL_AGREEMENT_LEASE_STEP,
  D_PART,
  E_PART,
  C_PART,
  IMPORTANT_INFORMATION_STEP,
  ADDITIONAL_TERMS_STEP,
  WATER_USAGES_STEP,
  SPECIAL_CONDITION_LEASE_STEP,
  ELECTRONIC_SERVICES,
  CONDITION_REPORT_TENANCY_LAWS
} from "../../../routing/paths";
import {inject} from "mobx-react";

import VICCPart from "../../../pages/leaseSteps/GeneralAgreementStep/VICCPart";
import VICDPart from "../../../pages/leaseSteps/GeneralAgreementStep/VICDPart";

@inject("Lease")
export class RoutesWrapper extends Component{

  VICRoutes = [
    {
      path: C_PART,
      component: VICCPart,
      nextPath: D_PART,
      isLeaseSteps: true,
      private: true
    },
    {
      path: D_PART,
      component: VICDPart,
      nextPath: SPECIAL_CONDITION_LEASE_STEP,
      isLeaseSteps: true,
      private: true
    },
  ]

  skipStep(routes: Array<CustomRoutes>, route: string){
    const skipIndex = routes.findIndex(
      v => v.path === route
    );

    if(skipIndex > -1){
      routes[skipIndex - 1].nextPath = routes[skipIndex].nextPath;
      routes.splice(skipIndex, 1);
      return routes;
    }
    return routes;
  }

  addStep(routes: Array<CustomRoutes>, newRoutes: any, after: string){
    const afterIndex = routes.findIndex(
      v => v.path === after
    );

    if(afterIndex > -1){
      routes[afterIndex].nextPath = newRoutes[0].path;
      //@ts-ignore
      routes.splice.apply(routes, [afterIndex + 1, 0].concat(newRoutes));
      return routes;
    }
    return routes;
  }

  render(){
    let customRoutes = routes;
    // @ts-ignore
    const currentLease = this.props.Lease.currentLease;

    if(currentLease.property.coordinates == null ||
      (currentLease.property.meta_data.cords.lng == 0 && currentLease.property.meta_data.cords.lat == 0)){
      customRoutes = this.skipStep(customRoutes, PROPERTY_DETAILS_LEASE_STEP);
    }

    if(currentLease.property.state == "QLD"){
      customRoutes = this.skipStep(customRoutes, SMOKE_ALARMS_STEP);
      customRoutes = this.skipStep(customRoutes, ELECTRONIC_SERVICES);
      customRoutes = this.skipStep(customRoutes, NOTES_STEP);
      customRoutes = this.skipStep(customRoutes, CONDITION_REPORT_TENANCY_LAWS);
      customRoutes = this.skipStep(customRoutes, IMPORTANT_INFORMATION_STEP);
      customRoutes = this.skipStep(customRoutes, ADDITIONAL_TERMS_STEP);
    }

    if(currentLease.property.state == "VIC"){
      customRoutes = this.skipStep(customRoutes, OCCUPANCY_DETAILS_LEASE_STEP);
      customRoutes = this.skipStep(customRoutes, INCLUSION_DETAILS_LEASE_STEP);
      customRoutes = this.skipStep(customRoutes, SMOKE_ALARMS_STEP);
      customRoutes = this.skipStep(customRoutes, WATER_USAGES_STEP);
      customRoutes = this.skipStep(customRoutes, NOTES_STEP);
      customRoutes = this.skipStep(customRoutes, IMPORTANT_INFORMATION_STEP);
      customRoutes = this.skipStep(customRoutes, GENERAL_AGREEMENT_LEASE_STEP);
      customRoutes = this.addStep(customRoutes, this.VICRoutes, ELECTRONIC_SERVICES);
    }

    return(
      <Switch>
        {customRoutes.map((route, index) => {
            return <Route key={index} exact={route.exact} path={route.path}
                          render={(props) => {
                            const token = getItem('token')
                            if (route.private && !token) {
                              return <Redirect to={{pathname: INVALID_PAGE}}/>
                            }
                            return <route.component {...props} nextPath={route.nextPath}/>
                          }}/>
          }
        )}
        <Redirect to={{pathname: INVALID_PAGE}}/>
      </Switch>
    )
  }
}
