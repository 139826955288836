import React from "react"
import {inject, observer} from "mobx-react"
import {Button, message} from "antd"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"
import {LeaseStepsContainer} from ".."
import {NoteStepContent} from "./components/NoteStepContent/NoteStepContent"
import {SignatureModal} from "../components/SignatureModal/SignatureModal"

import {Checkbox} from "../../../components"
import * as Icons from "../../../components/icons"

import {saveTenantInitial} from "../../../networking/lease"

import {State, Props, InjectedProps} from "./types"
import {SIGNATURE_STEP} from "../../../routing/paths";

@inject('Lease', 'Core')
@observer
export class NotesStep extends ComponentWithInjectedProps<Props, State, InjectedProps> {

    state = {
        isConfirm: false,
        isVisibleSignatureModal: false,
        loading: false
    }

    private changeConfirm = () => {
        this.setState({isConfirm: !this.state.isConfirm})
    }

    private saveTenantInitial = (signature: any) => {
        const {history, nextPath} = this.props
        const initial = signature.toDataURL()
        if (signature.isEmpty()) {
            return message.warning({
                content: 'Set your initial please'
            })
        }
        this.setState({loading: true}, () => {
            saveTenantInitial(initial, 'notes')
                .then(res => {
                    if(res.status === 200) {
                        const missedSignature = localStorage.getItem('signature');
                        if(missedSignature != "" && missedSignature != null){
                            localStorage.setItem('signature', "");
                            history.push(SIGNATURE_STEP);
                        } else {
                            history.push(nextPath)
                        }
                    } else {
                        this.setState({loading: false})
                        message.error('Internal error occur, please try again')
                    }
                })
                .catch(err => this.setState({loading: false}))
        })
    }

    componentDidMount(): void {
        const missedSignature = localStorage.getItem('signature');
        if(missedSignature != "" && missedSignature != null){
            message.warn('Something wrong with saving your signature on this step. Please sign again.')
        }
    }

    render(): React.ReactElement {
        const {isConfirm, isVisibleSignatureModal, loading} = this.state
        const {Core, Lease:{getCurrentUser}} = this.injectedProps
        const user = getCurrentUser()
        return (
            <LeaseStepsContainer title={'Notes'}>
                {{
                    content: (
                        <div className="additional-terms__container">
                            <Icons.WebChecklist/>
                            <h4 className='heading-blue'>
                                Definitions in this agreement's:
                            </h4>
                            <NoteStepContent/>
                            <div className='witness-step__confirmation-container w-100'>
                                <div>
                                    <Checkbox
                                        checked={isConfirm}
                                        onChange={this.changeConfirm}
                                    >
                                        <p className='small-paragraph mt-0'>
                                            I {user.first_name} {user.last_name} approve for my initials to be inserted
                                            on
                                            the bottom of each page
                                            of this agreement
                                        </p>
                                    </Checkbox>
                                </div>
                            </div>
                            <SignatureModal
                                title={'Initial'}
                                isVisible={isVisibleSignatureModal}
                                onCancel={() => this.setState({isVisibleSignatureModal: false})}
                                onSave={this.saveTenantInitial}
                                loading={loading}
                            />
                        </div>
                    ),
                    footer: (
                        <div className='footer__buttons-container_two'>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="default"
                                    loading={false}
                                    onClick={() => Core.toggleCallDialog()}
                                >
                                    Disagree
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className='fullwidth t-uppercase app-basic-button_large'
                                    shape="round"
                                    type="danger"
                                    loading={false}
                                    disabled={!isConfirm}
                                    onClick={() => this.setState({isVisibleSignatureModal: true})}
                                >
                                    Agree
                                </Button>
                            </div>
                        </div>
                    )
                }}
            </LeaseStepsContainer>
        )
    }
}
