import React from "react"

import {Props} from "./types"

import './styles.scss'

export function Checkbox(props: Props): React.ReactElement{
    const {
        checked = false,
        onChange = () => {
        },
        children
    } = props
    return (
            <label style={{display: 'flex', paddingBottom: 10, paddingTop: 10}}>
                <div className="checkbox__container">
             <span className={`checkbox ${checked ? 'checkbox-checked' : ''}`}>
                <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={checked}
                        onChange={onChange}
                />

            </span>
                </div>
                {children}
            </label>
    )
}
