export function setItem(name: string, value: string) {
    localStorage.setItem(name, value)
}

export function getItem(name: string): string | null {
    return localStorage.getItem(name)
}

export function removeItem(name: string): void {
    localStorage.removeItem(name)
}
