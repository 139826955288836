import React, {Component} from "react"
import {Tooltip, Icon, Button} from 'antd'
//@ts-ignore
import SignatureCanvas from 'react-signature-canvas'

import {BaseModal} from "../../../../components"

import {Props, State} from "./types"

import './styles.scss'

export class SignatureModal extends Component<Props, State> {

    signatureContainer = React.createRef<HTMLDivElement>()
    signatureObj: any = React.createRef()

    state = {
        containerWidth: 0,
        containerHeight: 0,
        isSizeSet: false,
        signatureUrl: ''
    }

    componentDidMount(): void {
        // if (this.signatureContainer.current) {
        //     this.setContainerSize(this.signatureContainer.current, true)
        // } else {
        //     this.forceUpdate()
        // }
        window.addEventListener('resize', this.resizeListener)
    }

    resizeListener = (): void => {
        this.setState({isSizeSet: false}, () => {
            // console.log(this.signatureContainer)
            // this.setContainerSize(this.signatureContainer.current, true)
        })
    }

    // componentDidUpdate(): void {
    //     const {isSizeSet} = this.state
    //     if (!isSizeSet) {
    //         this.setContainerSize(this.signatureContainer.current, true)
    //     }
    // }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.resizeListener)
    }

    setContainerSize = (element: HTMLDivElement | null, isSizeSet: boolean): void => {
        if (!this.state.isSizeSet) {
            this.setState({
                //@ts-ignore
                containerWidth: element ? element.clientWidth : 0,
                //@ts-ignore
                containerHeight: element ? element.clientHeight : 0,
                isSizeSet
            })
        }
    }

    undo = () => {
        if (!this.signatureObj) return;
        const data = this.signatureObj.toData();
        if (data) {
            data.pop(); // remove the last dot or line
            this.signatureObj.fromData(data);
        }
    }

    clear = () => {
        if (this.signatureObj) {
            this.signatureObj.clear()
        }

        this.setState({signatureUrl: ''})
    }

    upload = (response: any) => {
        if (response.file.status !== 'uploading') {
            this.setState({signatureUrl: response.file.response.url})
        }
    }

    render(): React.ReactElement {
        const {containerWidth, signatureUrl} = this.state
        const {isVisible, onCancel, onSave, loading, title = 'Sign'} = this.props
        return (
            <BaseModal visible={isVisible}>
                <div className='signature-container' ref={(node) => {
                    this.setContainerSize(node, true);
                }}>
                    {/*<div className='w-100 signature-title heading-secondary'>*/}
                    {/*    <span>{title}</span>*/}
                    {/*</div>*/}
                    <div className='signature__header'>

                        <div>
                            <Tooltip title="Undo">
                                <Icon
                                    onClick={() => this.undo()}
                                    component={() => <i className="la la-undo"/>}
                                />
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Clear">
                                <Icon
                                    onClick={() => this.clear()}
                                    component={() => <i
                                        className="la la-times-circle"/>}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div
                        className='signature__content'
                    >
                        {signatureUrl ?
                            <img src={signatureUrl} alt="" style={{width: containerWidth, height: 200}}/> :
                            <SignatureCanvas
                                ref={(ref: any) => this.signatureObj = ref}
                                clearOnResize={true}
                                canvasProps={{width: containerWidth, height: 200, className: 'sigCanvas'}}
                            />
                        }
                    </div>

                    <div className="signature-description">{title} above</div>
                </div>
                <div className='footer__buttons-container_two'>
                    <div>
                        <Button
                            className='fullwidth t-uppercase app-basic-button_large'
                            shape="round"
                            type="default"
                            disabled={loading}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            className='fullwidth t-uppercase app-basic-button_large'
                            shape="round"
                            type="danger"
                            loading={loading}
                            onClick={() => onSave(this.signatureObj)}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </BaseModal>
        )
    }
}
