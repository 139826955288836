import React from "react"
import {Button} from 'antd'
import {
  observer,
  inject
} from "mobx-react"

import {
  MainLayoutContentContainer,
  MainLayoutFooter
} from "../../components"
import {ComponentWithInjectedProps} from "../../services/ComponentWithInjectedProps"

import {
  Props,
  InjectedProps,
  State
} from "./types"

import {removeItem} from "../../helpers/storage"

import './styles.scss'
import logo from "../../resources/images/svg/logo-with-text.svg";
import image from "../../resources/images/svg/welcome-image.svg";

@inject('Lease', 'StaticPage')
@observer
export class StartPage extends ComponentWithInjectedProps<Props, State, InjectedProps>{

  constructor(props: Props){
    super(props)
    removeItem('phone_number')
  }

  componentDidMount(): void{
    const {StaticPage: {setPage}} = this.injectedProps
    setPage()
  }

  render(): React.ReactElement{
    const {history, nextPath} = this.props;
    const {Lease} = this.injectedProps;
    const state = Lease.lease.property.state;

    console.log(Lease);

    let logoElement = <img className="start-page__logo" src={logo} alt="Logo"/>;
    let agencyCustomization = Lease.currentLease.agency.customization;
    if(agencyCustomization){
      // @ts-ignore
      let customLogo = agencyCustomization.tenancy_logo;
      if (customLogo){
        logoElement = <img className="logo" src={customLogo.full_url} alt="Logo"/>;
      }
    }

    return (
      <>
        <MainLayoutContentContainer>
          <div className='start-page__container'>
            {logoElement}
            <h1 className="title">Welcomes you to your</h1>
            <span className="subtitle">
              Residential {state == 'VIC' ? "Rental" : "Tenancy"} Agreement
            </span>
            <img className="image" src={image}/>
          </div>
        </MainLayoutContentContainer>
        <MainLayoutFooter>
          <div className='start-page__buttons-container'>
            <Button
              className='fullwidth t-uppercase app-basic-button_large'
              shape="round"
              type="danger"
              loading={false}
              onClick={() => history.push(nextPath)}
            >
              Get Started
            </Button>
          </div>
        </MainLayoutFooter>
      </>
    )
  }
}
