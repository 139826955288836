import React from "react"

import {Props} from "./types"
import {ADDITIONAL_TERM_ALIAS} from "../../../../../types/AdditionalTerms"

export function GeneralAgreementStepContent(props:Props): React.ReactElement{
    const {Lease:{getValueFromAdditionalTerms}} = props
    return (
            <>

                <ol style={{counterReset: 'item 0'}} className="nested-list heading-secondary">
                    <h4 className='heading-blue text-left w-100'>
                        RIGHT TO OCCUPY THE PREMISES
                    </h4>
                    {/*1*/}
                    <li>
                        The landlord agrees that the tenant has the right to occupy the residential premises during the
                        tenancy. The
                        residential premises include the additional things (if any) noted under “Residential premises”.
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        COPY OF AGREEMENT
                    </h4>
                    {/*2*/}
                    <li>
                        The landlord agrees to give the tenant:
                        <ol>
                            <li>
                                a copy of this agreement before or when the tenant gives the signed copy of the
                                agreement to the landlord
                                or landlord’s agent, and
                            </li>
                            <li>
                                a copy of this agreement signed by both the landlord and the tenant as soon as is
                                reasonably practicable.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        RENT
                    </h4>
                    {/*3*/}
                    <li>
                        The tenant agrees:
                        <ol>
                            <li>
                                to pay rent on time, and
                            </li>
                            <li>
                                to reimburse the landlord for the cost of replacing rent deposit books or rent cards
                                lost by the tenant, and
                            </li>
                            <li>
                                to reimburse the landlord for the amount of any fees paid by the landlord to a bank or
                                other authorised
                                deposit-taking institution as a result of funds of the tenant not being available for
                                rent payment on the due
                                date.
                            </li>
                        </ol>
                    </li>
                    {/*4*/}
                    <li>
                        The landlord agrees:
                        <ol>
                            <li>
                                to provide the tenant with at least one means to pay rent for which the tenant does not
                                incur a cost (other
                                than bank fees or other account fees usually payable for the tenant’s transactions) and
                                that is reasonably
                                available to the tenant, and
                            </li>
                            <li>
                                not to require the tenant to pay more than 2 weeks rent in advance or to pay rent for a
                                period of the tenancy
                                before the end of the previous period for which rent has been paid, and
                            </li>
                            <li>
                                not to require the tenant to pay rent by a cheque or other negotiable instrument that is
                                post-dated, and
                            </li>
                            <li>
                                to accept payment of unpaid rent after the landlord has given a termination notice on
                                the ground of failure to
                                pay rent if the tenant has not vacated the residential premises, and
                            </li>
                            <li>
                                not to use rent paid by the tenant for the purpose of any amount payable by the tenant
                                other than rent, and
                            </li>
                            <li>
                                to give a rent receipt to the tenant if rent is paid in person (other than by cheque),
                                and
                            </li>
                            <li>
                                to make a rent receipt available for collection by the tenant or to post it to the
                                residential premises or to send
                                it by email to an email address specified in this agreement by the tenant for the
                                service of documents of that
                                kind if rent is paid by cheque, and
                            </li>
                            <li>
                                to keep a record of rent paid under this agreement and to provide a written statement
                                showing the rent
                                record for a specified period within 7 days of a request by the tenant (unless the
                                landlord has previously
                                provided a statement for the same period).
                            </li>
                        </ol>
                    </li>
                    <p className='small-paragraph'>
                        Note. The landlord and the tenant may, by agreement, change the manner in which rent is payable
                        under this
                        agreement.
                    </p>
                    <h4 className='heading-blue text-left w-100'>
                        RENT INCREASES
                    </h4>
                    {/*5*/}
                    <li>
                        The landlord and the tenant agree that the rent cannot be increased after the end of the
                        fixed term (if any) of this agreement or under this agreement if the agreement is for a fixed
                        term of 2 years or more, unless the landlord gives not less than
                        60 days written notice of the increase to the tenant. The notice must specify the increased rent
                        and the day from which it is payable.
                    </li>
                    <p className='small-paragraph'>
                        Note: Section 42 of the Residential Tenancies Act
                        2010 sets out the circumstances in which rent may be increased during
                        the fixed term of a residential tenancy agreement. An additional term for
                        this purpose may be included in the agreement.
                    </p>
                    {/*6*/}
                    <li>
                        The landlord and the tenant agree that the rent may not be increased after the end of the fixed
                        term (if any) of this agreement more than once in any 12-month period.
                    </li>
                    {/*7*/}
                    <li>
                        The landlord and the tenant agree:
                        <ol>
                            <li>
                                that the increased rent is payable from the day specified in the notice, and
                            </li>
                            <li>
                                that the landlord may cancel or reduce the rent increase by a later notice that takes
                                effect on the same day as the original notice, and
                            </li>
                            <li>
                                that increased rent under this agreement is not payable unless the rent is increased
                                in accordance with this agreement and the
                                Residential Tenancies Act 2010 or by the NSW Civil and Administrative Tribunal.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        RENT REDUCTIONS
                    </h4>
                    {/*8*/}
                    <li>
                        The landlord and the tenant agree that the rent abates if the residential premises:
                        <ol>
                            <li>
                                are destroyed, or become wholly or partly uninhabitable, otherwise than as a result of a
                                breach of this agreement, or
                            </li>
                            <li>
                                cease to be lawfully usable as a residence, or
                            </li>
                            <li>
                                are compulsorily appropriated or acquired by an authority.
                            </li>
                        </ol>
                    </li>
                    {/*9*/}
                    <li>
                        The landlord and the tenant may, at any time during this agreement, agree to reduce the rent
                        payable.
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        PAYMENT OF COUNCIL RATES, LAND TAX, WATER AND OTHER CHARGES
                    </h4>
                    {/*10*/}
                    <li>
                        The landlord agrees to pay:
                        <ol>
                            <li>
                                rates, taxes or charges payable under any Act (other than
                                charges payable by the tenant under this agreement), and
                            </li>
                            <li>
                                the installation costs and charges for initial connection to the
                                residential premises of an electricity, water, gas, bottled gas or oil
                                supply service, and
                            </li>
                            <li>
                                all charges for the supply of electricity, non-bottled gas or oil
                                to the tenant at the residential premises that are not separately metered, and
                                <p className='small-paragraph'>
                                    Note 1. Clause 10.3 does not apply to premises located in an embedded network in
                                    certain circumstances in accordance with clauses 34 and 35 of the Residential
                                    Tenancies Regulation 2019.
                                </p>
                                <p className='small-paragraph'>
                                    Note 2. Clause 10.3 does not apply to social housing tenancy agreements in certain
                                    circumstances, in accordance with clause 36 of the Residential Tenancies Regulation
                                    2019.
                                </p>
                            </li>
                            <li>
                                the costs and charges for the supply or hire of gas bottles for
                                the supply of bottled gas at the commencement of the tenancy, and
                            </li>
                            <li>
                                all charges (other than water usage charges) in connection with
                                a water supply service to separately metered residential premises,
                                and
                            </li>
                            <li>
                                all charges in connection with a water supply service to residential premises that are
                                not separately metered, and
                            </li>
                            <li>
                                all charges for the supply of sewerage services (other than for pump out septic
                                services) or the supply or use of drainage services to the residential premises, and
                            </li>
                            <li>
                                all service availability charges, however described for the supply of non-bottled gas to
                                the residential premises are separately metered but do not have any appliances, supplied
                                by the landlord, for which gas is required and the tenant does not use gas supplied to
                                the premises, and
                            </li>
                            <li>
                                the costs ahd charges for repair, maintenance or other work carried out on the
                                residential premises which is required to facilitate the propert installation or
                                replacement of an electricity meter, in working order, including an advanced meter, if
                                the meter installation is required by the retailer to replace an existing meter because
                                the meter is faulty, testing indicates the meter may become faulty or the meter has
                                reached the end of its life.
                            </li>
                        </ol>
                    </li>
                    {/*11*/}
                    <li>
                        The tenant agrees to pay:
                        <ol>
                            <li>
                                all charges for the supply of electricity or oil to the tenant at the residential
                                premises if the premises are
                                separately metered, and
                            </li>
                            <li>
                                all charges for the supply of non-bottled gas to the tenant at the residential premises
                                if the premises are
                                separately metered, unless the premises do not have any appliances supplied by the
                                landlord for which gas is
                                required and the tenant does not use gas supplied to the premises, and
                                <p className='small-paragraph'>
                                    Note. Charges for the supply of gas in certain circumstances may also be payable by
                                    a tenant under a social
                                    housing agreement in accordance with clause 36 of the Residential Tenancies
                                    Regulation 2019.
                                </p>
                            </li>
                            <li>
                                all charges for the supply of bottled gas to the tenant at the residential premises
                                except for the costs and
                                charges for the supply or hire of gas bottles at the start of the tenancy, and
                            </li>
                            <li>
                                all charges for pumping out a septic system used for the residential premises, and
                            </li>
                            <li>
                                any excess garbage charges relating to the tenant’s use of the residential premises, and
                            </li>
                            <li>
                                water usage charges, if the landlord has installed water efficiency measures referred to
                                in clause 10 of the Residential Tenancies Regulation 2019 and the residential premises:
                                <ol>
                                    <li>
                                        are separately metered, or
                                    </li>
                                    <li>
                                        are not connected to a water supply service and water is delivered
                                        by vehicle.

                                    </li>
                                </ol>
                                <p className='small-paragraph'>
                                    Note. Separately metered is defined in the Residential Tenancies Act 2010.
                                </p>
                            </li>
                        </ol>
                    </li>
                    {/*12*/}
                    <li>
                        The landlord agrees that the tenant is not required to pay water usage
                        charges unless:
                        <ol>
                            <li>
                                the landlord gives the tenant a copy of the part of the water
                                supply authority’s bill setting out the charges, or other evidence of
                                the cost of water used by the tenant, and
                            </li>
                            <li>
                                the landlord gives the tenant at least 21 days to pay the
                                charges, and
                            </li>
                            <li>
                                the landlord requests payment of the charges by the tenant
                                not later than 3 months after the issue of the bill for the charges by
                                the water supply authority, and
                            </li>
                            <li>
                                the residential premises have the following water efficiency
                                measures:
                                <ol>
                                    <li>
                                        all internal cold water taps and single mixer taps for
                                        kitchen sinks or bathroom hand basins on the premises have a
                                        maximum flow rate of 9 litres per minute,
                                    </li>
                                    <li>
                                        on and from 23 March 2025,all toilets are dual flush toilets that have a minimum
                                        3 star rating in accordance with the WELS scheme,
                                    </li>
                                    <li>
                                        all showerheads have a maximum flow rate of 9 litres
                                        per minute,
                                    </li>
                                    <li>
                                        the residential tenancy agreement and whenever any other water efficiency
                                        measures are installed, repaired or upgraded, the premises are checked and any
                                        leaking taps or toilets on the premises have been fixed.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    {/*13*/}
                    <li>
                        The landlord agrees to give the tenant the benefit of, or an amount
                        equivalent to, any rebate received by the landlord for water usage
                        charges payable or paid by the tenant.
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        POSSESSION OF THE PREMISES
                    </h4>
                    {/*14*/}
                    <li>
                        The landlord agrees:
                        <ol>
                            <li>
                                to make sure the residential premises are vacant so the tenant
                                can move in on the date agreed, and
                            </li>
                            <li>
                                to take all reasonable steps to ensure that, at the time of
                                signing this agreement, there is no legal reason why the premises
                                cannot be used as a residence for the term of this agreement.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        TENANT’S RIGHT TO QUIET ENJOYMENT
                    </h4>
                    {/*15*/}
                    <li>
                        The landlord agrees:
                        <ol>
                            <li>
                                that the tenant will have quiet enjoyment of the residential premises
                                without interruption by the landlord or any person claiming by, through
                                or under the landlord or having superior title to that of the landlord (such as a head
                                landlord), and
                            </li>
                            <li>
                                that the landlord or the landlord’s agent will not interfere with, or cause or permit
                                any interference with, the
                                reasonable peace, comfort or privacy of the tenant in using the residential premises,
                                and
                            </li>
                            <li>
                                that the landlord or the landlord’s agent will take all reasonable steps to ensure that
                                the landlord’s other
                                neighbouring tenants do not interfere with the reasonable peace, comfort or privacy of
                                the tenant in using the
                                residential premises.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        USE OF THE PREMISES BY TENANT
                    </h4>
                    {/*16*/}
                    <li>
                        The tenant agrees:
                        <ol>
                            <li>
                                not to use the residential premises, or cause or permit the
                                premises to be used, for any illegal purpose, and
                            </li>
                            <li>
                                not to cause or permit a nuisance, and
                            </li>
                            <li>
                                not to interfere, or cause or permit interference, with the
                                reasonable peace, comfort or privacy of neighbours, and
                            </li>
                            <li>
                                not to intentionally or negligently cause or permit any
                                damage to the residential premises, and
                            </li>
                            <li>
                                not to cause or permit more people to reside in the residential
                                premises than is permitted by this agreement.
                            </li>
                        </ol>
                    </li>
                    {/*17*/}
                    <li>
                        The tenant agrees:
                        <ol>
                            <li>
                                to keep the residential premises reasonably
                                clean, and
                            </li>
                            <li>
                                to notify the landlord as soon as practicable of any damage to
                                the residential premises, and
                            </li>
                            <li>
                                that the tenant is responsible to the landlord for any act or
                                omission by a person who is lawfully on the residential premises if
                                the person is only permitted on the premises with the tenant’s
                                consent and the act or omission would be in breach of this
                                agreement if done or omitted by the tenant, and
                            </li>
                            <li>
                                that it is the tenant’s responsibility to replace light globes on the residential
                                premises.
                            </li>
                        </ol>
                    </li>
                    {/*18*/}
                    <li>
                        The tenant agrees, when this agreement ends and before giving
                        vacant possession of the premises to the landlord:
                        <ol>
                            <li>
                                to remove all the tenant’s goods from the residential premises, and
                            </li>
                            <li>
                                to leave the residential premises as nearly as possible in the same
                                condition, fair wear and tear excepted, as at the commencement of the
                                tenancy, and
                            </li>
                            <li>
                                to leave the residential premises reasonably clean, having regard to
                                their condition at the commencement of the tenancy, and
                            </li>
                            <li>
                                to remove or arrange for the removal of all rubbish from the residential premises in a
                                way that is lawful and in accordance with council requirements, and
                            </li>
                            <li>
                                to make sure that all light fittings on the premises have working
                                globes, and
                            </li>
                            <li>
                                to return to the landlord all keys, and other opening devices or
                                similar devices, provided by the landlord
                            </li>
                        </ol>
                        <p className='small-paragraph'>
                            Note. Under section 54 of the Residential Tenancies Act 2010, the vicarious liability of a
                            tenant for damage to residential premises caused by another person is not imposed on a
                            tenant who is the victim of a domestic violence offence, or a co-tenant who is not a
                            relevant domestic violence offender, if the damage occurred during the commission of a
                            domestic violence offence (within the meaning of that Act).
                        </p>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        LANDLORD’S GENERAL OBLIGATIONS FOR RESIDENTIAL PREMISES
                    </h4>
                    {/*19*/}
                    <li>
                        The landlord agrees:
                        <ol>
                            <li>
                                to make sure that the residential premises are
                                reasonably clean and fit to live in, and
                                <p className='small-paragraph'>
                                    Note 1. Section 52 of the Residential Tenancies Act 2010 specifies the minimum
                                    requirements that must be met for residential premises to be fit to live in. These
                                    include that the residential premises:
                                </p>
                                <ol style={{counterReset: 'item 0'}} type='a'>
                                    <li>
                                        are structurally sound, and
                                    </li>
                                    <li>
                                        have adequate natural light or artificial lighting in each room of the premises
                                        other than a room that is intended to be used only for the purposes of storage
                                        or a garage, and
                                    </li>
                                    <li>
                                        have adequate ventilation, and
                                    </li>
                                    <li>
                                        are supplied with electricity or gas and have an adequate number of electricity
                                        outlet sockets or gas outlet sockets for the supply of lighting and heating to,
                                        and use of appliances in, the premises, and
                                    </li>
                                    <li>
                                        have adequate plumbing and drainage, and
                                    </li>
                                    <li>
                                        are connected to a water supply service or infrastructure that supplies water
                                        (including, but not limited to, a water bore or water tank) that is able to
                                        supply to the premises hot and cold water for drinking and ablution and cleaning
                                        activities, and
                                    </li>
                                    <li>
                                        contain bathroom facilities, including toilet and washing facilities, that allow
                                        privacy for the user.
                                    </li>
                                </ol>
                                <p className='small-paragraph'>
                                    Note 2. Premises are structurally sound only if the floors, ceilings, walls,
                                    supporting structures (including foundations), doors, windows, roof, stairs,
                                    balconies, balustrades and railings:
                                </p>
                                <ol type='a'>
                                    <li>
                                        are in a reasonable state of repair, and
                                    </li>
                                    <li>
                                        with respect to the floors, ceilings, walls and supporting structures—are not
                                        subject to significant dampness, and
                                    </li>
                                    <li>
                                        with respect to the roof, ceilings and windows—do not allow water penetration
                                        into the premises, and
                                    </li>
                                    <li>
                                        are not liable to collapse because they are rotted or otherwise defective.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                to make sure that all light fittings on the residential premises
                                have working light globes on the commencement of the tenancy,
                                and
                            </li>
                            <li>
                                to keep the residential premises in a reasonable state of
                                repair, considering the age of, the rent paid for and the prospective
                                life of the premises, and
                            </li>
                            <li>
                                not to interfere with the supply of gas, electricity, water,
                                telecommunications or other services to the residential premises
                                (unless the interference is necessary to avoid danger to any person
                                or enable maintenance or repairs to be carried out), and
                            </li>
                            <li>
                                not to hinder a tradesperson’s entry to the residential premises when the tradesperson
                                is carrying out maintenance or repairs necessary to avoid health or safety risks to any
                                person, or to avoid a risk that the supply of gas, electricity, water,
                                telecommunications or other services to the residential premises may ne disconnected,
                                and
                            </li>
                            <li>
                                to comply with all statutory obligations relating to the health
                                or safety of the residential premises and,
                            </li>
                            <li>
                                that a tenant who is the victim of a domestic violence offence or a co-tenant who is
                                under the same agreement as the victim of the domestic violence offence but is not a
                                relevant domestic violence offender is not responsible to the landlord for any act or
                                omission by a co-tenant that is a breach of this agreement if the act or omission
                                constitutes or resulted in damage to the premises and occurred during the commission of
                                a domestic violence offence.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        URGENT REPAIRS
                    </h4>
                    {/*20*/}
                    <li>
                        The landlord agrees to pay the tenant, within 14 days after
                        receiving written notice from the tenant, any reasonable costs (not
                        exceeding $1,000) that the tenant has incurred for making urgent repairs
                        to the residential premises (of the type set out below) so long as:
                        <ol>
                            <li>
                                the damage was not caused as a result of a breach of this
                                agreement by the tenant, and
                            </li>
                            <li>
                                the tenant gives or makes a reasonable attempt to give the
                                landlord notice of the damage, and
                            </li>
                            <li>
                                the tenant gives the landlord a reasonable opportunity to
                                make the repairs, and
                            </li>
                            <li>
                                the tenant makes a reasonable attempt to have any
                                appropriate trades person named in this agreement make the
                                repairs, and
                            </li>
                            <li>
                                the repairs are carried out, where appropriate, by licensed or
                                properly qualified persons, and
                            </li>
                            <li>
                                the tenant, as soon as possible, gives or tries to give the
                                landlord written details of the repairs, including the cost and the
                                receipts for anything the tenant pays for.
                            </li>
                            <p className='small-paragraph'>
                                Note. The type of repairs that are urgent repairs are defined in the Residential
                                Tenancies Act 2010 and are
                                defined as follows—
                            </p>
                            <ol type='a'>
                                <li>
                                    a burst water service,
                                </li>
                                <li>
                                    an appliance, fitting or fixture that uses water or is used to
                                    supply water that is broken or not functioning properly, so that a
                                    substantial amount of water is wasted,
                                </li>
                                <li>
                                    a blocked or broken lavatory system,
                                </li>
                                <li>
                                    a serious roof leak,
                                </li>
                                <li>
                                    a gas leak,
                                </li>
                                <li>
                                    a dangerous electrical fault,
                                </li>
                                <li>
                                    flooding or serious flood damage,
                                </li>
                                <li>
                                    serious storm or fire damage,
                                </li>
                                <li>
                                    a failure or breakdown of the gas, electricity or water supply to
                                    the premises,
                                </li>
                                <li>
                                    a failure or breakdown of any essential service on the
                                    residential premises for hot water, cooking, heating, cooling or
                                    laundering,
                                </li>
                                <li>
                                    any fault or damage that causes the premises to be unsafe or
                                    insecure.
                                </li>
                            </ol>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        SALE OF THE PREMISES
                    </h4>
                    {/*21*/}
                    <li>
                        The landlord agrees:
                        <ol>
                            <li>
                                to give the tenant written notice that the landlord intends to
                                sell the residential premises, at least 14 days before the premises
                                are made available for inspection by potential purchasers, and
                            </li>
                            <li>
                                to make all reasonable efforts to agree with the tenant as to
                                the days and times when the residential premises are to be
                                available for inspection by potential purchasers.
                            </li>
                        </ol>
                    </li>
                    {/*22*/}
                    <li>
                        The tenant agrees not to unreasonably refuse to agree to days and
                        times when the residential premises are to be available for inspection by
                        potential purchasers.
                    </li>
                    {/*23*/}
                    <li>
                        The landlord and tenant agree:
                        <ol>
                            <li>
                                that the tenant is not required to agree to the residential premises
                                being available for inspection more than twice in a period of a week, and
                            </li>
                            <li>
                                that, if they fail to agree, the landlord may show the residential
                                premises to potential purchasers not more than twice in any period of a
                                week and must give the tenant at least 48 hours notice each time.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        LANDLORD’S ACCESS TO THE PREMISES
                    </h4>
                    {/*24*/}
                    <li>
                        The landlord agrees that the landlord, the landlord’s agent or any
                        person authorised in writing by the landlord, during the currency of this
                        agreement, may only enter the residential premises in the following
                        circumstances:
                        <ol>
                            <li>
                                in an emergency (including entry for the purpose of carrying out
                                urgent repairs),
                            </li>
                            <li>
                                if the NSW Civil and Administrative Tribunal so orders,
                            </li>
                            <li>
                                if there is good reason for the landlord to believe the premises are
                                abandoned,
                            </li>
                            <li>
                                if there is good reason for serious concern about the health of the
                                tenant or any other person on the residential premises and a reasonable
                                attempt has been made to obtain consent to the entry,
                            </li>
                            <li>
                                to inspect the premises, if the tenant is given at least 7 days written
                                notice (no more than 4 inspections are allowed in any period of
                                12 months),
                            </li>
                            <li>
                                to carry out, or assess the need for, necessary repairs, if the tenant is
                                given at least 2 days notice each time,
                            </li>
                            <li>
                                to carry out, or assess the need for, work relating to statutory health
                                and safety obligations relating to the residential premises, if the tenant is
                                given at least 2 days notice each time,
                            </li>
                            <li>
                                to show the premises to prospective tenants on a reasonable number
                                of occasions if the tenant is given reasonable notice on each occasion
                                (this is only allowed during the last 14 days of the agreement),
                            </li>
                            <li>
                                to value the property, if the tenant is given
                                7 days notice (not more than one valuation is allowed in any period of
                                12 months),
                            </li>
                            <li>
                                to take photographs, or make visual recordings, of the inside of the premises in order
                                to advertise the premises for sale or lease, if the tenant is given reasonable notice
                                and reasonable opportunity to move any of their possessions that can reasonably be moved
                                out of the frame of the photograph or the scope of the recording (this is only allowed
                                once in a 28 day period before marketing of the premises starts for sale or lease or the
                                termination of this agreement),
                            </li>
                            <li>
                                if the tenant agrees.
                            </li>
                        </ol>
                    </li>
                    {/*25*/}
                    <li>
                        The landlord agrees that a person who enters the residential premises under clause 24.5, 24.6,
                        24.7, 24.8, 24.9 or
                        24.10 of this agreement:
                        <ol>
                            <li>
                                must not enter the premises on a Sunday or a public
                                holiday, unless the tenant agrees, and
                            </li>
                            <li>
                                may enter the premises only between the hours of 8.00
                                a.m. and 8.00 p.m., unless the tenant agrees to another time,
                                and
                            </li>
                            <li>
                                must not stay on the residential premises longer than is necessary to achieve the
                                purpose of the entry to the
                                premises, and
                            </li>
                            <li>
                                must, if practicable, notify the tenant of the proposed
                                day and time of entry.
                            </li>
                        </ol>
                    </li>
                    {/*26*/}
                    <li>
                        The landlord agrees that, except in an emergency (including to
                        carry out urgent repairs), a person other than the landlord or the
                        landlord’s agent must produce to the tenant the landlord’s or the
                        landlord’s agent’s written permission to enter the residential
                        premises.
                    </li>
                    {/*27*/}
                    <li>
                        The tenant agrees to give access to the residential premises to the
                        landlord, the landlord’s agent or any person, if they are exercising
                        a right to enter the residential premises in accordance with this
                        agreement.
                    </li>
                    {/*28*/}
                    <h4 className='heading-blue text-left w-100'>
                        PUBLISHING PHOTOGRAPHS OR VISUAL RECORDINGS
                    </h4>
                    <li>
                        The landlord agrees that the landlord or the landlord’s agent must not publish any photographs
                        taken or visual recordings made of the inside of the residential premises in which the tenant’s
                        possession are visible unless they first obtain written consent from the tenant.
                        <p className='small-paragraph'>
                            Note. See section 55A of the Residential Tenancies Act 2010 for when a photograph or visual
                            recording is published.
                        </p>
                    </li>
                    {/*29*/}
                    <li>
                        The tenant agrees not to unreasonably withhold consent.. If the tenant is in circumstances of
                        domestic violence within the meaning of section 105B of the Residential Tenancies Act 2010, it
                        is not unreasonable for the tenant to withhold consent.
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        FIXTURES, ALTERATIONS, ADDITIONS OR RENOVATIONS TO THE PREMISES
                    </h4>
                    {/*30*/}
                    <li>
                        The tenant agrees:
                        <ol>
                            <li>
                                not to install any fixture or renovate, alter or add to the
                                residential premises without the landlord’s written
                                permission, and
                            </li>
                            <li>
                                that certain kinds of fixtures or alterations, additions or renovations that are of a
                                minor nature specified by clause 22(2) of the esidential Tenancies Regulation 2019 may
                                only be carried out by a person appropriately qualified to carry out those alterations
                                unless the landlord gives consent, and
                            </li>
                            <li>
                                to pay the cost of a fixture, installed or on behalf of the tenant, or any renovation,
                                alteration or addition to the residential premises, unless the landlord otherwise
                                agrees, and
                            </li>
                            <li>
                                not to remove, without the landlord’s permission, any
                                fixture attached by the tenant that was paid for by the
                                landlord or for which the landlord gave the tenant a benefit
                                equivalent to the cost of the fixture, and
                            </li>
                            <li>
                                to notify the landlord of any damage caused by
                                removing any fixture attached by the tenant, and
                            </li>
                            <li>
                                to repair any damage caused by removing the fixture or
                                compensate the landlord for the reasonable cost of repair.
                            </li>
                        </ol>
                    </li>
                    {/*31*/}
                    <li>
                        The landlord agrees not to unreasonably withhold consent to a fixture, or to an alteration,
                        addition or renovation that is of a minor nature
                        <p className='small-paragraph'>
                            Note. The Residential Tenancies Regulation 2019 provides a list of the kinds of fixtures or
                            alterations, additions or renovations of a minor nature to which it would be unreasonable
                            for a landlord to withhold consent and which of those fixtures, or alterations, additions or
                            renovations the landlord may give consent to on the condition that the fixture or
                            alteration, addition or renovation is carried out by an appropriately qualified person.
                        </p>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        LOCKS AND SECURITY DEVICES
                    </h4>
                    {/*32*/}
                    <li>
                        The landlord agrees:
                        <ol>
                            <li>
                                to provide and maintain locks or other security devices
                                necessary to keep the residential premises reasonably secure, and
                            </li>
                            <li>
                                to give each tenant under this agreement a copy of the key or
                                opening device or
                                information to open any lock or security device for the residential
                                premises or common property to which the tenant is entitled to have
                                access, and
                            </li>
                            <li>
                                not to charge the tenant for the cost of providing the copies except
                                to recover the cost of replacement or additional copies, and
                            </li>
                            <li>
                                not to alter, remove or add any lock or other security device without
                                reasonable excuse (which includes an emergency, an order of the NSW
                                Civil and Administrative Tribunal, termination of a co-tenancy or an
                                apprehended violence order prohibiting a tenant or occupant from
                                having access) or unless the tenant agrees, and
                            </li>
                            <li>
                                to give each tenant under this agreement
                                a copy of any key or other opening device or information to open any
                                lock or security device that the landlord changes as soon as practicable
                                (and no later than 7 days) after the change.
                            </li>
                        </ol>
                    </li>
                    {/*33*/}
                    <li>
                        The tenant agrees:
                        <ol>
                            <li>
                                not to alter, remove or add any lock or other security device without reasonable excuse
                                (which includes an
                                emergency, an order of the Civil and Administrative Tribunal, termination of a
                                co-tenancy or an apprehended
                                violence order prohibiting a tenant or occupant from having access) or unless the
                                landlord agrees, and
                            </li>
                            <li>
                                to give the landlord a copy of the key or opening device or information to open any lock or security device
                                that the tenant changes within 7 days of the change.
                            </li>
                        </ol>
                    </li>
                    {/*34*/}
                    <li>
                        A copy of a changed key or other opening device need not be given to the other party if the other party agrees not
                        to be given a copy or the Civil and Administrative Tribunal authorises a copy not to be given or the other party is
                        prohibited from access to the residential premises by an apprehended violence order.
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        TRANSFER OF TENANCY OR SUB-LETTING BY TENANT
                    </h4>
                    {/*35*/}
                    <li>
                        The landlord and tenant agree that:
                        <ol>
                            <li>
                                the tenant may, with the landlord’s written permission,
                                transfer the tenant’s tenancy under this agreement or sub-let the
                                residential premises, and
                            </li>
                            <li>
                                the landlord may refuse permission (whether or not it is
                                reasonable to do so) to the transfer of the whole of the tenancy or sub-letting the
                                whole of
                                the residential premises, and
                            </li>
                            <li>
                                the landlord must not unreasonably refuse permission to a
                                transfer of part of a tenancy or a sub-letting of part of the
                                residential premises, and
                            </li>
                            <li>
                                35.4 without limiting clause 35.3, the landlord may refuse permission to a transfer of
                                part of the tenancy or to sub-letting part of
                                the residential premises if the number of occupants would be more than is permitted
                                under this agreement or any proposed tenant or sub-tenant is listed on a residential
                                tenancy database or it would result in overcrowding of the residential premises.
                                <p className='small-paragraph'>
                                    Note: Clauses 32.3 and 32.4 do not apply to social tenancy housing
                                    agreements.
                                </p>
                            </li>
                        </ol>
                    </li>
                    {/*36*/}
                    <li>
                        The landlord agrees not to charge for giving permission other than
                        for the landlord’s reasonable expenses in giving permission.
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        CHANGE IN DETAILS OF LANDLORD OR LANDLORD’S AGENT
                    </h4>
                    {/*37*/}
                    <li>
                        The landlord agrees:
                        <ol>
                            <li>
                                if the name and telephone number or contact details of the
                                landlord change, to give the tenant notice in writing of the change
                                within 14 days, and
                            </li>
                            <li>
                                if the address of the landlord changes (and the landlord does
                                not have an agent), to give the tenant notice in writing of the
                                change within 14 days, and
                            </li>
                            <li>
                                if the name, telephone number or business address of the
                                landlord’s agent changes or the landlord appoints an agent, to give
                                the tenant notice in writing of the change or the agent’s name,
                                telephone number and business address, as appropriate, within 14
                                days, and
                            </li>
                            <li>
                                if the landlord or landlord’s agent is a corporation and the
                                name or business address of the corporation changes, to give the
                                tenant notice in writing of the change within 14 days.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        COPY OF CERTAIN BY-LAWS TO BE PROVIDED
                    </h4>
                    <h4 className='heading-blue text-left w-100'>
                        [Cross out if not applicable]
                    </h4>
                    {/*38*/}
                    <li>
                        The landlord agrees to give to the tenant, before the tenant enters into this agreement, a copy
                        of the by-laws applying to the residential premises if they are premises under the Strata
                        Schemes Management Act 2015.
                    </li>
                    {/*39*/}
                    <li>
                        to give to the tenant within 7 days of entering into this agreement a copy of
                        the by-laws applying to the residential premises if they are premises under the Strata Schemes
                        Development Act 2015, the Community Land Development Act 1989 or the Community Land
                        Management Act 1989.
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        MITIGATION OF LOSS
                    </h4>
                    {/*40*/}
                    <li>
                        The rules of law relating to mitigation of loss or damage on breach
                        of a contract apply to a breach of this agreement. (For example, if the
                        tenant breaches this agreement the landlord will not be able to claim
                        damages for loss which could have been avoided by reasonable effort by
                        the landlord.)
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        RENTAL BOND
                    </h4>
                    <h4 className='heading-blue text-left w-100'>
                        [Cross out this clause if no rental bond is payable]
                    </h4>
                    {/*41*/}
                    <li>
                        The landlord agrees that, where the landlord or the landlord’s agent applies to the Rental Bond Board or the Civil
                        and Administrative Tribunal for payment of the whole or part of the rental bond to the landlord, the landlord or the
                        landlord’s agent will provide the tenant with:
                        <ol>
                            <li>
                                details of the amount claimed, and
                            </li>
                            <li>
                                copies of any quotations, accounts and receipts that are relevant to the claim, and
                            </li>
                            <li>
                                a copy of a completed condition report about the residential premises at the end of the
                                residential tenancy agreement.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        SMOKE ALARMS
                    </h4>
                    {/*42*/}
                    <li>
                        The landlord agrees to:
                        <ol>
                            <li>
                                ensure that smoke alarms are installed in accordance with the Environmental Planning and
                                Assessment Act 1979 if that Act requires them to be installed in the premises and are
                                functioning in accordance with the regulations under that Act, and
                            </li>
                            <li>
                                conduct an annual check of all smoke alarms installed on the residential premises to
                                ensure that the smoke alarms are functioning, and
                            </li>
                            <li>
                                install or replace, or engage a person to install or replace, all removable batteries in
                                all smoke alarms installed on the residential premises annually, except for smoke alarms
                                that have a removable lithium battery, and
                            </li>
                            <li>
                                install or replace, or engage a person to install or replace, a removable lithium
                                battery in a smoke alarm in the period specified by the manufacturer of the smoke alarm,
                                and
                            </li>
                            <li>
                                engage an authorised electrician to repair or replace a hardwired smoke alarm, and
                            </li>
                            <li>
                                repair or replace a smoke alarm within 2 business days of becoming aware that the smoke
                                alarm is not working unless the tenant notifies the landlord that the tenant will carry
                                out the repair to the smoke alarm and the tenant carries out the repair, and
                            </li>
                            <li>
                                reimburse the tenant for the costs of a repair or replacement of a smoke alarm in
                                accordance with clause 18 of the Residential Tenancies Regulation 2019, that the tenant
                                is allowed to carry out.
                            </li>
                            <p className='small-paragraph'>
                                Note 1. Under section 64A of the Residential Tenancies Act 2010, repairs to a smoke
                                alarm includes maintenance of a smoke alarm in working order by installing or replacing
                                a battery in the smoke alarm.
                            </p>
                            <p className='small-paragraph'>
                                Note 2. Clauses 42.2–42.7 do not apply to a landlord of premises that comprise or
                                include a lot in a strata scheme (within the meaning of the Strata Schemes Management
                                Act 2015) if the owners corporation is responsible for the repair and replacement of
                                smoke alarms in the residential premises.
                            </p>
                            <p className='small-paragraph'>
                                Note 3. A tenant who intends to carry out a repair to a smoke alarm may do so only in
                                the circumstances prescribed for a tenant in clause 15 of the Residential Tenancies
                                Regulation 2019.
                            </p>
                        </ol>
                    </li>
                    {/*43*/}
                    <li>
                        The tenant agrees:
                        <ol>
                            <li>
                                to notify the landlord if a repair or a replacement of a smoke alarm is required,
                                including replacing a battery in the smoke alarm, and
                            </li>
                            <li>
                                that the tenant may only replace a battery in a battery-operated smoke alarm, or a
                                back-up battery in a hardwired smoke alarm, if the smoke alarm has a removable battery
                                or a removable back-up battery, and
                            </li>
                            <li>
                                to give the landlord written notice, as soon as practicable if the tenant will carry out
                                and has carried out a repair or replacement, or engages a person to carry out a repair
                                or replacement, in accordance with clauses 15–17 of the Residential Tenancies Regulation
                                2019.
                            </li>
                            <p className='small-paragraph'>
                                Note. Clauses 43.2 and 43.3 do not apply to tenants under social housing tenancy agreements or tenants of premises
                                that comprise or include a lot in a strata scheme (within the meaning of the StrataSchemesManagement Act2015) if the
                                owners corporation is responsible for the repair and replacement of smoke alarms in the residential premises.
                            </p>
                        </ol>
                    </li>
                    {/*44*/}
                    <li>
                        The landlord and the tenant each agree not to remove or interfere with the operation of a smoke alarm installed on
                        the residential premises unless they have a reasonable excuse to do so.
                        <p className='small-paragraph'>
                            Note. The regulations made under the Environmental Planning and Assessment Act 1979 provide that it is an offence to
                            remove or interfere with the operation of a smoke alarm or a heat alarm in particular circumstances.
                        </p>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        SWIMMING POOLS
                    </h4>
                    <h4 className='heading-blue text-left w-100'>
                        [Cross out this clause if no rental bond is payable]
                    </h4>
                    {/*45*/}
                    <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.swimming_pool) ? 'cross-text' : ''}`}>
                        The landlord agrees to ensure that the requirements of the Swimming Pools Act 1992 have been
                        complied with in respect of the swimming pool on the residential premises.
                    </li>
                    {/*46*/}
                    <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.swimming_pool) ? 'cross-text' : ''}`}>
                        The landlord agrees to ensure that at the time that this residential tenancy agreement is
                        entered into:
                        <ol>
                            <li>
                                the swimming pool on the residential premises is registered under the Swimming Pools Act
                                1992 and has a valid certificate of compliance under that Act or a relevant occupation
                                certificate within the meaning of that Act, and
                            </li>
                            <li>
                                a copy of that valid certificate of compliance or relevant occupation certificate is
                                provided to the tenant.
                            </li>
                        </ol>
                    </li>
                    <p className='small-paragraph'>
                        Note. A swimming pool certificate of compliance is valid for 3 years from its date of
                        issue.
                    </p>
                    <h4 className='heading-blue text-left w-100'>
                        LOOSE-FILL ASBESTOS INSULATION
                    </h4>
                    {/*47*/}
                    <li>
                        The landlord agrees:
                        <ol>
                            <li>
                                if, at the time that this residential tenancy agreement is entered into, the premises
                                have been and remain listed on the LFAI Register, the tenant has been advised in writing
                                by the landlord that the premises are listed on that Register, or
                            </li>
                            <li>
                                if, during the tenancy, the premises become listed on the LFAI Register, to advise the
                                tenant in writing, within 14 days of the premises being listed on the Register, that the
                                premises are listed on the Register.
                            </li>

                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        COMBUSTIBLE CLADDING
                    </h4>
                    {/*48*/}
                    <li>
                        The landlord agrees that if, during the tenancy, the landlord becomes aware of any of the
                        following facts, the landlord will advise the tenant in writing within 14 days of becoming aware
                        of the fact:
                        <ol>
                            <li>
                                that the residential premises are part of a building in relation to which a notice of
                                intention to issue a fire safety order, or a fire safety order, has been issued
                                requiring rectification of the building regarding external combustible cladding.
                            </li>
                            <li>
                                that the residential premises are part of a building in relation to which a notice of
                                intent to issue a building product rectification order, or a building product
                                rectification order, has been issued requiring rectification of the building regarding
                                external combustible cladding.
                            </li>
                            <li>
                                that the residential premises are part of a building where a development application or
                                complying development certificate application has been lodged for rectification of the
                                building regarding external combustible cladding.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        SIGNIFICANT HEALTH OR SAFETY RISKS
                    </h4>
                    {/*49*/}
                    <li>
                        The landlord agrees that if, during the tenancy, the landlord becomes aware that the premises are subject to a
                        significant health or safety risk, the landlord will advise the tenant in writing, within 14 days of becoming aware, that
                        the premises are subject to the significant health or safety risk and the nature of the risk.
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        ELECTRONIC SERVICE OF NOTICES AND OTHER DOCUMENTS
                    </h4>
                    {/*50*/}
                    <li>
                        The landlord and tenant:
                        <ol>
                            <li>
                                to only serve any notices and any other documents, authorised or required by the Residential Tenancies Act
                                2010 or the regulations or this agreement, on the other party by email if the other party has provided express
                                consent, either as part of this agreement or otherwise, that a specified email address is to be used for the
                                purpose of serving notices and other documents, and
                            </li>
                            <li>
                                to notify the other party in writing within 7 days if the email address specified for electronic service of notices
                                and other documents changes, and
                            </li>
                            <li>
                                that they may withdraw their consent to the electronic service of notices and other documents at any time,
                                by notifying the other party in writing, and
                            </li>
                            <li>
                                if a notice is given withdrawing consent to electronic service of notices and other documents, following the
                                giving of such notice, no further notices or other documents are to be served by email.
                            </li>
                        </ol>
                    </li>
                    <h4 className='heading-blue text-left w-100'>
                        BREAK FEE FOR FIXED TERM OF NOT MORE THAN 3 YEARS
                    </h4>
                    {/*51*/}
                    <li>
                        The tenant agrees that, if the tenant ends the residential tenancy agreement before the end of the fixed term of the
                        agreement, the tenant must pay a break fee of the following amount if the fixed term is not more than 3 years:
                        <ol>
                            <li>
                                4 weeks rent if less than 25% of the fixed term has expired,
                            </li>
                            <li>
                                3 weeks rent if 25% or more but less than 50% of the fixed term has expired,
                            </li>
                            <li>
                                2 weeks rent if 50% or more but less than 75% of the fixed term has expired,
                            </li>
                            <li>
                                1 week’s rent if 75% or more of the fixed term has expired.
                            </li>
                            <p className='small-paragraph'>
                                This clause does not apply if the tenant terminates a fixed term residential tenancy agreement for a fixed term of more
                                than 3 years or if the tenant terminates a residential tenancy agreement early for a reason that is permitted under the
                                Residential Tenancies Act 2010
                            </p>
                            <p className='small-paragraph'>
                                Note. Permitted reasons for early termination include destruction of residential
                                premises, breach of the agreement by the landlord and an offer of social housing or a
                                place in an aged care facility, and being in circumstances of domestic violence. Section
                                107 of the Residential Tenancies Act 2010 regulates the rights of the landlord and
                                tenant under this clause.
                            </p>
                        </ol>
                    </li>
                    {/* 52*/}
                    <li>
                        The landlord agrees that the compensation payable by the tenant for ending the residential
                        tenancy agreement before the end of the fixed term of not more than 3 years is limited to the
                        amount specified in clause 51 and any occupation fee payable under the Residential Tenancies Act
                        2010 for goods left on the residential premises.
                        <p className='small-paragraph'>
                            Note. Section 107 of the Residential Tenancies Act 2010 also regulates the rights of landlords and tenants for a
                            residential tenancy agreement with a fixed term of more than 3 years.
                        </p>
                    </li>

                </ol>
            </>
    )
}
