import {ColumnProps} from "antd/lib/table"

import {TableColumn} from "../../../components/table/TableColumn/TableColumn";

export const emergencyColumns = function (): ColumnProps<any>[] {

    return [
        {
            title: 'Trade Type',
            dataIndex: 'type',
            key: 'type',
            render: (value: string) => TableColumn(value, 'Trade Type'),
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
            render: (value: string) => TableColumn(value, 'Company Name'),
        },
        {
            title: 'Contact Name',
            dataIndex: 'contact_name',
            key: 'contact_name',
            render: (value: string) => TableColumn(value, 'Contact Name'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (value: string) => TableColumn(value, 'Phone'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value: string) => TableColumn(value, 'Email'),
        },
    ];
}
