import React from "react"
import {inject} from "mobx-react"
import {Button} from 'antd'

import {LeaseStepsContainer} from ".."
import * as Icons from "../../../components/icons"

import {ComponentWithInjectedProps} from "../../../services/ComponentWithInjectedProps"

import {
    Props,
    InjectedProps
} from "./types"

@inject('Core', 'Lease')
export class SmokeAlarmsStep extends ComponentWithInjectedProps<Props, any, InjectedProps>{

    render(): React.ReactElement{
        const {history, nextPath} = this.props
        const {Lease: {lease: {smoke_alarm}}} = this.injectedProps
        return (
                <LeaseStepsContainer title={'Smoke Alarms'}>
                    {{
                        content: (
                                <div className="additional-terms__container">
                                    <Icons.WebChecklist/>
                                    <h4 className='heading-blue'>
                                        Indicate whether the smoke alarms installed in the residential premises are
                                        hardwired or battery operated:
                                    </h4>
                                    <p className='heading-secondary'>
                                        <span style={{textTransform:'capitalize'}}>{smoke_alarm.type} </span>smoke alarm
                                    </p>
                                    <h4 className='heading-blue'>
                                        If the smoke alarm(s) are hardwired, are the back-up batteries in the smoke alarm(s)
                                        of a kind the tenant can replace?
                                    </h4>
                                    <p className='heading-secondary'>
                                        {smoke_alarm.battery_operated ? 'YES' : 'NO'}
                                    </p>
                                    {smoke_alarm.battery_operated ?
                                            <>
                                                <h4 className='heading-blue'>
                                                    If yes, specify of type back-up battery that needs to be used if the
                                                    smoke
                                                    alarm
                                                    needs to be replaced:
                                                </h4>
                                                <p className='heading-secondary'>
                                                    {smoke_alarm.description}
                                                </p>
                                            </> :
                                            null
                                    }

                                    <h4 className='heading-blue'>
                                        If the Strata Schemes Management Act 2015 applies to the residential premises,
                                        is the owners corporation of the
                                        strata schemes responsible for the repair and replacement of smoke alarms in the
                                        residential premises?
                                    </h4>
                                    <p className='heading-secondary'>
                                        {smoke_alarm.replaceable ? 'YES' : 'NO'}
                                    </p>
                                </div>
                        ),
                        footer: (
                                <div className='footer__buttons-container_two'>
                                    <div>
                                        <Button
                                                className='fullwidth t-uppercase app-basic-button_large'
                                                shape="round"
                                                type="danger"
                                                loading={false}
                                                onClick={() => history.push(nextPath)}
                                        >
                                            Ok
                                        </Button>
                                    </div>
                                </div>
                        )
                    }}
                </LeaseStepsContainer>
        )
    }
}
