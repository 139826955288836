import React from "react"
// import {Button} from "antd"

import {formatDate} from "../../../../../helpers/date"

import {Props} from "./types"
import {
    ADDITIONAL_TERM_ALIAS,
} from "../../../../../types/AdditionalTerms"
import {toJS} from "mobx";

export function AdditionalTermsStepContent(props: Props): React.ReactElement{
    const {/*openModalForSetInitial, signed_terms,*/ additional_terms, rent_increase, state} = props;
    const rentIncreases: any = toJS(props.rent_increase);

    console.log(additional_terms);

    function getValueFromAdditionalTerms(alias: string): boolean{
        return additional_terms.reduce((prev, term) => {
            if(term.alias === alias){
                prev = term.value
            }
            return prev
        }, false as boolean)
    }

    function getAdditionalTermsPets(): Array<{age:number, type:string, breed:string, number:string}>{
        return additional_terms.reduce((prev, term) => {
            if(term.alias === ADDITIONAL_TERM_ALIAS.pets){
                if(term.meta_data){
                    //@ts-ignore
                    prev = typeof term.meta_data[0] === 'string'?
                        //@ts-ignore
                        term.meta_data[0].split(',').map(item => ({age:null, type:item, breed:null, number:null}))
                        :term.meta_data
                }
            }
            return prev
        }, [])
    }

    function getDateFromRenewalAdditionalTerm(): string{
        return additional_terms.reduce((prev, term) => {
            if(term.alias === ADDITIONAL_TERM_ALIAS.original_condition_report){
                //@ts-ignore
                prev = term.meta_data ? term.meta_data[0] : ''
            }
            return prev
        }, '')
    }

    function getRentIncreases(){
      if(rentIncreases.length > 0){
        let rentIncreasesArray: any[] = [];
        rentIncreases.map(function (item: any, index: number) {
          rentIncreasesArray.push(
            <div>
              {formatDate(item.start_on)}, rent is to be increased to ${item.rate} per {item.frequency}
            </div>
          )
        });
        return rentIncreasesArray;
      } else {
        return('____/____/____, rent is to be increased to $____ per ____.') ;
      }
    }

    if(state === "VIC"){
        return (
          <>
              <h4 className='heading-blue'>
                  Owners corporation
              </h4>
              <p className='heading-secondary'>
                  Do owners corporation rules apply to the premises?
              </p>
              <p className='heading-secondary' style={{margin: "15px 0"}}>
                  {getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.corporation_rules) ? "YES" : "NO"}
              </p>


              <h4 className='heading-blue'>
                  Condition Report
              </h4>
              <p className='heading-secondary'>
                  The renter must be given two copies of the condition report (or one emailed copy) on or before the
                  date the renter moves into the rented premises.
              </p>
              <p className={`heading-secondary ${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.condition_report) ? 'cross-text' : ''} `}>
                  The condition report has been provided
              </p>
              <p className={`heading-secondary ${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.condition_report_will_provided) ? 'cross-text' : ''} `}>
                  The condition report will be provided to the renter on or before the date the agreement starts
              </p>
          </>
        )
    } else {
        return (
          <>
              <h4 className='heading-blue'>
                  Additional terms
              </h4>
              <p className='heading-secondary'>
                  Additional terms may be included in this agreement if:
              </p>
              <ol className='heading-secondary' type="a">
                  <li>
                      both the landlord and tenant agree to the terms, and
                  </li>
                  <li>
                      they do not conflict with the Residential Tenancies Act 2010, the Residential
                      Tenancies Regulation 2010
                      or any other Act, and
                  </li>
                  <li>
                      they do not conflict with the standard terms of this
                      agreement
                  </li>
              </ol>
              <p className='heading-secondary text-center'>ANY ADDITIONAL TERMS ARE NOT REQUIRED BY LAW
                  AND ARE NEGOTIABLE
              </p>
              {/* ********************/}
              <h4 className='heading-blue text-left w-100'>
                  Additional term — pets
              </h4>
              <ol style={{counterReset: 'item 52'}}
                  className={`nested-list heading-secondary`}>

                  {/*53*/}
                  <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.pets) ? 'cross-text' : ''} `}>
                      The landlord agrees that the tenant may keep the following animal on the residential premises
                      {getAdditionalTermsPets().map((item, index, array) => {
                          return (
                            <div key={index}>
                                 <span style={{fontSize:13}}>
                                 Type: {item.type}
                                </span>
                                <span style={{fontSize:13,marginLeft:5}}>
                                   Breed: {item.breed}
                                </span>
                                <span style={{fontSize:13,marginLeft:5}}>
                                    Number: {item.number}
                                </span>
                                <span style={{fontSize:13,marginLeft:5}}>
                                    Age: {item.age}
                                </span>{index + 1 < array.length?',':null}
                            </div>
                          )
                      })}
                  </li>
                  {/*54*/}
                  <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.pets) ? 'cross-text' : ''} `}>
                      The tenant agrees
                      <ol>
                          <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.pets) ? 'cross-text' : ''} `}>
                              to supervise and keep the animal within the premises, and
                          </li>
                          <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.pets) ? 'cross-text' : ''} `}>
                              to ensure that the animal does not cause a nuisance, or breach the reasonable peace,
                              comfort or privacy of neighbours, and
                          </li>
                          <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.pets) ? 'cross-text' : ''} `}>
                              to ensure that the animal is registered and micro-chipped if required under law, and
                          </li>
                          <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.pets) ? 'cross-text' : ''} `}>
                              to comply with any council requirements.
                          </li>
                      </ol>
                  </li>
                  {/* 55*/}
                  <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.pets) ? 'cross-text' : ''} `}>
                      The tenant agrees to have the carpet professionally cleaned or to pay the cost of having the
                      carpet professionally cleaned at the end of the tenancy if cleaning is required because an
                      animal
                      has been kept on the residential premises during the tenancy.
                  </li>
              </ol>
              {/*    *****/}

              <ol
                style={{counterReset: 'item 55'}}
                className={`nested-list heading-secondary`}
              >
                  {/*56*/}
                  <li className={`${rent_increase.length == 0 ? 'cross-text' : ''}`}>
                      If the details in this clause 56 have been completed, then the parties agree to increase rent
                      during the fixed term of
                      the agreement as follows 56 .1 on {getRentIncreases()}
                  </li>
                  <p className={`${rent_increase.length == 0 ? 'small-paragraph cross-text' : 'small-paragraph'}`}>
                      Note. The rent payable under a residential tenancy agreement may be increased only if the tenant
                      is given written
                      notice by the landlord or the landlord's agent specifying the increased rent and the day from
                      which it is payable, and
                      the notice is given at least 60 days before the increased rent is payable. Notice of a rent
                      increase must be given by a
                      landlord or landlord's agent even if details of the rent increase are set out in the residential
                      tenancy agreement. The rent
                      payable under a fixed term agreement for a fixed term of less than 2 years must not be increased
                      during the fixed term
                      unless the agreement specifies the increased rent or the method of calculating the increase. The
                      rent payable under a
                      fixed term agreement for a fixed term of 2 years or more must not be increased more than once in
                      any period of 12
                      months and may be increased whether or not the agreement specifies the increased rent or the
                      method of calculating
                      the increase
                  </p>
                  <h4 className='heading-blue text-left w-100'>
                      Additional term - Previous Condition Report
                  </h4>
                  <li className={`${!getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.original_condition_report) ? 'cross-text' : ''}`}>
                      The parties agree that the condition report dated
                      {getValueFromAdditionalTerms(ADDITIONAL_TERM_ALIAS.original_condition_report) ? ' ' + formatDate(getDateFromRenewalAdditionalTerm()) + ' ' : ' _____/_____/_____ '}
                      and carried out to record the state of repair
                      and condition of the residential premises under a previous residential tenancy agreement between
                      the landlord and
                      the tenant, forms part of this agreement.
                  </li>
                  {/*<li>*/}
                  {/*    The tenant agrees to:*/}
                  {/*    <ol>*/}
                  {/*        <li>*/}
                  {/*            have the carpet professionally cleaned or to have the residential premises fumigated if*/}
                  {/*            the*/}
                  {/*            cleaning or fumigation is required because animals have been kept on the residential*/}
                  {/*            premises during the tenancy.*/}
                  {/*        </li>*/}
                  {/*        <li>*/}
                  {/*            repair any damage caused by animals kept on the residential premises; and*/}
                  {/*        </li>*/}
                  {/*        <li>*/}
                  {/*            upon request, and in the form of evidence elected, by the landlord or landlord’s agent,*/}
                  {/*            provide to the landlord or the landlord’s agent (as the case may be) evidence that the*/}
                  {/*            tenant has complied with clause 45.1 and*/}
                  {/*            45.2 of this agreement*/}
                  {/*        </li>*/}
                  {/*    </ol>*/}
                  {/*</li>*/}
                  {/*<div className='button-small-container'>*/}
                  {/*    <Button*/}
                  {/*            className='fullwidth t-uppercase app-basic-button_large'*/}
                  {/*            shape="round"*/}
                  {/*            type="danger"*/}
                  {/*            loading={false}*/}
                  {/*            onClick={() => openModalForSetInitial(ADDITIONAL_TERM_ALIAS.pets)}*/}
                  {/*    >*/}
                  {/*        {signed_terms[ADDITIONAL_TERM_ALIAS.pets] ? 'Agreed' : 'Agree'}*/}
                  {/*    </Button>*/}
                  {/*</div>*/}
              </ol>
              {/*    *****/}
              {/*<ol style={{counterReset: 'item 56'}} className="nested-list heading-secondary">*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Rent increases during the fixed term*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        If the details in this clause 46 have been completed, then the parties agree to increase rent*/}
              {/*        during*/}
              {/*        the fixed term of the agreement as follows*/}
              {/*        <ol>*/}
              {/*            <li>*/}
              {/*                On the 8th of February 2019, rent is to be increased to $2000.00 per fortnight.*/}
              {/*            </li>*/}
              {/*        </ol>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        If the details in this clause 47 have been completed, then the parties agree to increase rent*/}
              {/*        during the fixed term of the agreement using the following method: [insert method of*/}
              {/*        calculation]*/}
              {/*    </li>*/}
              {/*    <p className='small-paragraph'>*/}
              {/*        Note: The rent payable under a residential tenancy agreement may be increased only if the tenant*/}
              {/*        is*/}
              {/*        given written notice by the landlord or the landlord's agent specifying the increased rent and*/}
              {/*        the*/}
              {/*        day from which it is payable, and the notice is given at least 60 days before the increased rent*/}
              {/*        is*/}
              {/*        payable. Notice of a rent increase must be given by a landlord or landlord's agent even if*/}
              {/*        details*/}
              {/*        of the rent increase are set out in the residential tenancy agreement. The rent payable under a*/}
              {/*        fixed term agreement for a fixed term of less than 2 years must not be increased during the*/}
              {/*        fixed*/}
              {/*        term unless the agreement specifies the increased rent or the method of calculating the*/}
              {/*        increase.*/}
              {/*        The rent payable under a fixed term agreement for a fixed term of 2 years or more must not be*/}
              {/*        increased more than once in any period of 12 months and may be increased whether or not the*/}
              {/*        agreement specifies the increased rent or the method of calculating the increase.*/}
              {/*    </p>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - No set off*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        Without the written approval of the landlord, the tenant must not set off or seek to set off the*/}
              {/*        rental bond against any rent or other monies payable by the tenant to the landlord.*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Smoking*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        The tenant must not smoke or allow others to smoke in the premises.*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        If the tenant smokes or allows others to smoke outside the premises, the tenant must ensure that*/}
              {/*        all*/}
              {/*        cigarette butts are*/}
              {/*        properly disposed and not left on the ground.*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        If the tenant smokes or allows others to smoke inside the premises in breach of clause 49, upon*/}
              {/*        termination of this*/}
              {/*        agreement, the tenant will be responsible for the cost of professionally cleaning all surfaces,*/}
              {/*        floors and windows of the*/}
              {/*        premises.*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Tenancy Databases*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        The landlord may list the tenant’s personal information in a residential tenancy database if:*/}
              {/*        <ol>*/}
              {/*            <li>*/}
              {/*                the tenant was named as a tenant in this agreement that has terminated or the tenant’s*/}
              {/*                co-tenancy was terminated;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                the tenant breached this agreement;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                because of the breach, the tenant owes the landlord an amount that is more than the*/}
              {/*                rental*/}
              {/*                bond for this agreement or the Tribunal has made a termination order; and*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                the personal information identifies the nature of the breach and is accurate, complete*/}
              {/*                and*/}
              {/*                unambiguous.*/}
              {/*            </li>*/}
              {/*        </ol>*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Condition Report*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        If a condition report, signed by both the tenant and the landlord, is included with or annexed*/}
              {/*        to*/}
              {/*        this agreement, the*/}
              {/*        parties agree that:*/}
              {/*        <ol>*/}
              {/*            <li>*/}
              {/*                it forms part of this agreement; and*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                it represents a true and accurate statement of the state of repair and condition of the*/}
              {/*                residential premises as at the date of the condition report.*/}
              {/*            </li>*/}
              {/*        </ol>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        If the landlord or the landlord’s agent provides a condition*/}
              {/*        report, signed by the landlord to the tenant and the tenant does not return a copy of the*/}
              {/*        condition*/}
              {/*        report, signed by the tenant, within 7 days of receipt, then the condition report signed by the*/}
              {/*        landlord is deemed to:*/}
              {/*        <ol>*/}
              {/*            <li>*/}
              {/*                form part of this agreement; and*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                represent a true and accurate statement of the state of repair and condition of the*/}
              {/*                residential premises as at the date of the condition report.*/}
              {/*            </li>*/}
              {/*        </ol>*/}
              {/*    </li>*/}
              {/*    <div className='button-small-container'>*/}
              {/*        <Button*/}
              {/*                className='fullwidth t-uppercase app-basic-button_large'*/}
              {/*                shape="round"*/}
              {/*                type="danger"*/}
              {/*                loading={false}*/}
              {/*                onClick={() => openModalForSetInitial(ADDITIONAL_TERM_ALIAS.original_condition_report)}*/}
              {/*        >*/}
              {/*            {signed_terms[ADDITIONAL_TERM_ALIAS.original_condition_report] ? 'Agreed' : 'Agree'}*/}
              {/*        </Button>*/}
              {/*    </div>*/}
              {/*</ol>*/}
              {/*/!*    ********!/*/}
              {/*<ol style={{counterReset: 'item 65'}} className="nested-list heading-secondary">*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Previous Condition Report*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        the parties agree that the condition report dated 30th July 2016 and carried out to record the*/}
              {/*        state*/}
              {/*        of repair and condition of the residential premises under a previous residential tenancy*/}
              {/*        agreement*/}
              {/*        between the landlord and the tenant, forms part of this agreement.*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Health Issues*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        The tenant must*/}
              {/*        <ol>*/}
              {/*            <li>*/}
              {/*                routinely clean the premises to avoid any mould, mildew or damp build-up;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                ensure that exhaust fans are turned on and windows are*/}
              {/*                opened when the relevant rooms in the premises are in use, e.g. bathrooms, to minimise*/}
              {/*                condensation;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                ensure that the premises are free of any pests and vermin; and*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                promptly notify the landlord or the landlord’s agent if there are any signs of mould,*/}
              {/*                mildew, dampness, pests or vermin in the premises.*/}
              {/*            </li>*/}
              {/*        </ol>*/}
              {/*    </li>*/}
              {/*    <div className='button-small-container'>*/}
              {/*        <Button*/}
              {/*                className='fullwidth t-uppercase app-basic-button_large'*/}
              {/*                shape="round"*/}
              {/*                type="danger"*/}
              {/*                loading={false}*/}
              {/*                onClick={() => openModalForSetInitial(ADDITIONAL_TERM_ALIAS.defence_clause)}*/}
              {/*        >*/}
              {/*            {signed_terms[ADDITIONAL_TERM_ALIAS.defence_clause] ? 'Agreed' : 'Agree'}*/}
              {/*        </Button>*/}
              {/*    </div>*/}
              {/*</ol>*/}
              {/*/!*    *******!/*/}
              {/*<ol style={{counterReset: 'item 67'}} className="nested-list heading-secondary">*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Telecommunication Facilities*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        The Landlord does not warrant or make any representation that there are lines of connection to*/}
              {/*        telephone, internet and cable or analogue telephone or television services.*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Repairs*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        The tenant must ensure that all repairs which are non-urgent in nature, are carried out at the*/}
              {/*        premises on business days between 9.00am to 5.00pm.*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        If the landlord has, acting reasonably, requested the tenant to provide access to the premises*/}
              {/*        for*/}
              {/*        the purpose of repairs, the tenant is liable for any call out fees incurred by the landlord as a*/}
              {/*        result of the tenant failing to provide access to the premises for any reason at the specified*/}
              {/*        time*/}
              {/*        and date.*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Procedure on Termination*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        Upon termination of this agreement, the tenant must vacate the premises in a peaceful manner and*/}
              {/*        return all keys, security cards and other opening devices to the landlord or the landlord’s*/}
              {/*        agent.*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        If the tenant fails to comply with clause 60, the tenant must continue to pay rent to the*/}
              {/*        landlord, at the amount payable immediately prior to termination of this agreement until:*/}
              {/*        <ol>*/}
              {/*            <li>*/}
              {/*                all the keys, security cards and other opening devices are returned to the landlord or*/}
              {/*                the*/}
              {/*                landlord’s agent; or*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                the landlord or the landlord’s agent has replaced/changed the locks to the premises and*/}
              {/*                the*/}
              {/*                landlord is able to gain access to the premises.*/}
              {/*            </li>*/}
              {/*        </ol>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        The tenant is liable, and must compensate the landlord, for the costs incurred by the landlord*/}
              {/*        in*/}
              {/*        replacing changing the locks under clause 61.2.*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        The landlord may apply to the Civil and Administration Tribunal (NCAT) for an order to recover:*/}
              {/*        <ol>*/}
              {/*            <li>*/}
              {/*                the rent payable by the tenant for the period from the date of termination to the date*/}
              {/*                the*/}
              {/*                landlord gains access to the premises; and*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                the costs incurred by the landlord in replacing/changing the locks under clause 61.2.*/}
              {/*            </li>*/}
              {/*        </ol>*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Dishonoured Payments*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        If any payment by the tenant to the landlord is dishonoured upon presentation to a financial*/}
              {/*        institution, then the tenant is liable to pay a $30 dishonour fee to the landlord. The tenant*/}
              {/*        must*/}
              {/*        pay the dishonour fee within 7 days notice from the landlord notifying the tenant of the*/}
              {/*        dishonoured*/}
              {/*        payment.*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Gardens*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        The tenant is responsible for regularly maintaining the yard and gardens on the premises*/}
              {/*        (including*/}
              {/*        regular mowing,*/}
              {/*        edging, pruning and weeding) during the tenancy period. The tenant agrees to keep the yard and*/}
              {/*        gardens on the premises in good condition (having regard to the condition report) during*/}
              {/*        the tenancy period, fair wear and tear excluded.*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - care of swimming pool*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        If there is a swimming pool located on the premises, the*/}
              {/*        tenant must:*/}
              {/*        <ol>*/}
              {/*            <li>*/}
              {/*                keep the swimming pool clean and regularly sweep up any leaves or other debris which*/}
              {/*                have*/}
              {/*                fallen into the swimming pool;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                regularly clean the sides of the swimming pool to minimise build-up of slime and other*/}
              {/*                residue;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                regularly clean the pool filters and empty out the leaf baskets;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                maintain the cleanliness and clarity of the water to a standard set by the landlord*/}
              {/*                (acting*/}
              {/*                reasonably) by testing the pool water monthly and treating, at the tenant’s cost, the*/}
              {/*                pool*/}
              {/*                with the necessary chemicals, if required;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                maintain the water level above the filter inlet at all times;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                promptly notify the landlord or the landlord’s agent of any issues with the pool or pool*/}
              {/*                equipment;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                ensure that all doors and gates providing access to the swimming pool are kept securely*/}
              {/*                closed at all times whenthey are not in actual use;*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                not leave any items near the swimming pool or the safety door/gate which would allow a*/}
              {/*                child*/}
              {/*                to gain access to the swimming pool; and*/}
              {/*            </li>*/}
              {/*            <li>*/}
              {/*                take all reasonable steps to ensure no unaccompanied child can gain access to the pool*/}
              {/*                area.*/}
              {/*            </li>*/}
              {/*        </ol>*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - electronic signatures*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        Any notice given electronically under this agreement must comply with sections 8 and 9 of the*/}
              {/*        Electronic Transactions Act 2000 (NSW), as applicable.*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*        Any signature given electronically under this agreement must comply with section 9 of the*/}
              {/*        Electronic*/}
              {/*        Transactions Act 2000 (NSW),*/}
              {/*    </li>*/}
              {/*    <h4 className='heading-blue'>*/}
              {/*        Additional term - Asbestos*/}
              {/*    </h4>*/}
              {/*    <li>*/}
              {/*        The parties acknowledge that the premises may contain asbestos or asbestos containing materials*/}
              {/*        and*/}
              {/*        the tenant must promptly notify the landlord or the landlord’s agent in writing, if any surface*/}
              {/*        and/or material at the premises*/}
              {/*        suspected of containing asbestos, is disturbed or damaged in any way.*/}
              {/*    </li>*/}
              {/*</ol>*/}
          </>
        )
    }
}
