import React from "react"

export function TableColumn(text: string | React.ReactNode, label?: string, type?: 'email' | 'phone'): React.ReactNode {
    return (
        <div>
            <div className="label">{label}</div>
            <div className="value">
                {type === 'email' ?
                    <a href={`mailto: ${text}`}>{text}</a> :
                    type === 'phone' ? <a href={`tel: ${text}`}>{text}</a> :
                        text
                }

            </div>
        </div>
    )
}
